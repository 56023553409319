import React, { forwardRef, LegacyRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR/index";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import styles from "./styles.module.scss";

interface Props extends ReactDatePickerProps {}

const CustomDatePicker = ({
  locale = ptBr,
  dateFormat = "HH:mm",
  ...rest
}: Props) => {
  const CustomInput = forwardRef(
    ({ value, onClick }: any, ref: LegacyRef<HTMLInputElement> | undefined) => (
      <div className={styles.inputContainer}>
        <input onClick={onClick} ref={ref} defaultValue={value}/>
        <KeyboardArrowDownIcon
          className={styles.inputIcon}
          onClick={onClick}
        ></KeyboardArrowDownIcon>
      </div>
    )
  );

  return (
    <DatePicker
      {...rest}
      customInput={<CustomInput />}
      locale={locale}
      dateFormat={dateFormat}
    />
  );
};

export default CustomDatePicker;
