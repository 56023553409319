import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { Pagination } from "@material-ui/lab";

import { useModalContext } from "../../../../providers/modalContext";
import { EditCategory } from "../../../Modal/EditCategory";
import { usePagination } from "../../../../util/hooks/pagination";

import styles from "./styles.module.scss";

import { DeleteItemModal } from "../../../Modal/DeleteItem";
import { useModelContext } from "../../../../providers/modelContext";

export function EquipmentCategory() {
  const [categoryEdit, setCategoryEdit] = useState();
  const [categoryId, setCategoryId] = useState<number>(0);
  const [categoryName, setCategoryName] = useState<string>("");
  const { setModalEditSubCategory, setModalDelete } = useModalContext();
  const { stateCategoryList, deleteCategory } = useModelContext();

  const PER_PAGE = 1000000;
  const count = Math.ceil(stateCategoryList.length / PER_PAGE);
  const _DATA = usePagination(stateCategoryList, PER_PAGE);

  const handleChange = (e: any, p: any) => {
    _DATA.jump(p);
  };

  const handleEditSubCategory = (catEdit: any) => {
    setCategoryEdit(catEdit);
    setModalEditSubCategory(true);
  };

  const handleDeleteItem = (id: number, name: string) => {
    setCategoryId(id);
    setCategoryName(name);
    setModalDelete(true);
  };

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div className={styles.itemContainer}>
          <div className={styles.titletable}>
            <div className={styles.contentHeader}>
              <h5>Nome</h5>
            </div>
            <div />
          </div>

          {stateCategoryList &&
            _DATA.currentData().map((category: any, index: any) => {
              return (
                <div key={index}>
                  <div className={styles.mainContent}>
                    <div className={styles.contentBox}>
                      <div className={styles.contentText}>
                        <h4>{category.name}</h4>
                      </div>
                    </div>
                    <div className={styles.contentBox3}>
                      <div className={styles.contentItem}>
                        <button onClick={() => handleEditSubCategory(category)}>
                          <EditIcon />
                        </button>
                        <button
                          onClick={() =>
                            handleDeleteItem(category.id, category.name)
                          }
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={styles.dividerContainer}>
                    <div className={styles.dividerHorizontal} />
                  </div>
                </div>
              );
            })}
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: "1.25rem !important",
            ul: {
              display: "flex",
              flexDirection: "row",
            },
          }}
        >
          {/*<Pagination
              count={count}
              size="large"
              //page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
        />*/}
        </Box>
      </section>
      {categoryEdit ? <EditCategory categoryEdit={categoryEdit} /> : <div />}
      <DeleteItemModal
        id={categoryId}
        name={categoryName}
        functionDelete={() => deleteCategory(categoryId)}
      />
    </div>
  );
}
