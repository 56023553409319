export const chartColors = [
    "#3BB2D0",
    "#3887BE",
    "#8B4F80",
    "#D3C0CC",
    "#8A8ACB",
    "#FBB03B",
    "#F9886C",
    "#E55E5E",
    "#ED6498",
    "#41AFA5",
    "#C8E96D",
    "#2ABC80",
    "#B1489E",
    "#C28EED",
    "#50667F",
];
