import { useState } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useFormik } from "formik";
import * as yup from "yup";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";

import CustomButton from "../../Form/button";

import styles from "./styles.module.scss";
import CustomInput from "../../CustomInput";
import { useStateContext } from "../../../providers/stateContext";
import { useModelContext } from "../../../providers/modelContext";
import CustomButtonLoading from "../../Form/buttonLoading";


type props = {
  messageTitle: string;
  massageContent: string;
};

export function ModalModelRegistration() {
  const { modalModelRegistration, setModalModelRegistration } = useModalContext()
  const { stateBusinessTypeList, stateCategoryList, postModelContext } = useModelContext();
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar, selectConsumerUnitId } = useStateContext();
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);

  const validationSchema = yup.object({
    name: yup.string()
      .required('Campo obrigatório')
      .max(80, 'O nome deve ter no máximo 80 caracteres'),
    brand: yup.string().required('Campo obrigatório'),
    equipmentModelBusinessId: yup.number().required('Campo obrigatório'),
    equipmentModelCategoryId: yup.number().required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      brand: '',
      name: '',
      equipmentModelBusinessId: '',
      equipmentModelCategoryId: '',
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setIsLoading(true);

      const MountedModelPost = {
        name: values.name,
        brand: values.brand,
        equipmentModelCategoryId: values.equipmentModelCategoryId,
        equipmentModelBusinessId: values.equipmentModelBusinessId,
        consumerUnitId: selectConsumerUnitId
      }

      let hasPostModel = await postModelContext(MountedModelPost);

      if (hasPostModel) {
        setStatusErrorOpenSnackBar(false)
        handleOpenSnackBar()
        setIsLoading(false)
        setModalModelRegistration(false)
        formik.resetForm()
      } else {
        setStatusErrorOpenSnackBar(true)
        handleOpenSnackBar()
        setIsLoading(false)
      }
    },
  });

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };


  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidthExit] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setModalModelRegistration(false);
      setStateExitModal(false);
      formik.resetForm()
    };

    const handleCloseExit = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidthExit}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "400px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleCloseExit()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalModelRegistration}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                padding: "0.8rem 0.7rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "-20px",
                  }}
                >
                  Cadastrar Setor
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1.25rem",
                  }}
                >
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    type="number"
                    id="brand"
                    name="brand"
                    label="Metragem (m²)"
                    value={formik.values.brand}
                    onChange={formik.handleChange}
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                  />
                  <FormControl>
                    <InputLabel sx={{ top: '-7px', fontSize: '0.9rem' }} id="equipmentModelBusinessId">
                      Tipo de negócio
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="equipmentModelBusinessId"
                      id="equipmentModelBusinessId"
                      value={formik.values.equipmentModelBusinessId}
                      name="equipmentModelBusinessId"
                      label="Tipo de negócio"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.equipmentModelBusinessId &&
                        Boolean(formik.errors.equipmentModelBusinessId)
                      }
                      className={styles.fixMuiInputBaseRootEditModel}
                    >
                      {stateBusinessTypeList && stateBusinessTypeList.map((businessType) => {
                        return (
                          <MenuItem
                            key={businessType.id}
                            value={businessType.id}
                          >
                            {businessType.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <span className={styles.error}>{formik.touched.equipmentModelBusinessId && formik.errors.equipmentModelBusinessId}</span>
                  </FormControl>
                  <FormControl>
                    <InputLabel sx={{ top: '-7px', fontSize: '0.9rem' }} id="equipmentModelCategoryId">
                      Categoria de equipamento
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="equipmentModelCategoryId"
                      id="demo-simple-select"
                      value={formik.values.equipmentModelCategoryId}
                      name="equipmentModelCategoryId"
                      label="Categoria de equipamento"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.equipmentModelCategoryId &&
                        Boolean(formik.errors.equipmentModelCategoryId)
                      }
                      className={styles.fixMuiInputBaseRootEditModel}
                    >
                      {stateCategoryList.map((category) => {
                        return (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <span className={styles.error}>{formik.touched.equipmentModelCategoryId && formik.errors.equipmentModelCategoryId}</span>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    padding: "1rem 0rem",
                  }}
                ></Box>
                <Divider />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                padding: "0rem 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1rem",
                }}
              >
                <CustomButtonLoading type="submit" title="Cadastrar" className="middle" />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
      <ExitModal
        messageTitle="Deseja cancelar o cadastro?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
