import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";

import { useFormik } from "formik";
import * as Yup from "yup";

import CustomInput from "../../components/CustomInput";
import Logo from "../../assets/image/deep-ambiente-logo.svg";
import Button from "../../components/Form/button";
import styles from "./stylesbackup.module.scss";
import { useAuth } from "../../providers/authContext";
import { User } from "../../util/types";
import { useStateContext } from "../../providers/stateContext";
import { CustomSnackBar } from "../../components/Snackbar";
import InputMask from "react-input-mask";
import maskConfig from "../../util/maks";
import onlyNumber from "../../util/onlyNumber";
import CustomButtonLoading from "../../components/Form/buttonLoading";
import { formatPhoneNumber, formatDocument } from "../../util/formattingUtils";


export default function Signup() {
  const navigate = useNavigate();
  const [statePerson, setStatePerson] = useState<boolean>(true);
  const [stateExitForm, setStateExitForm] = useState(false);
  const {
    setIsCNPJ,
    isCNPJ,
    signUpUser,
    generateConfirmEmail,
    statusResponse,
  } = useAuth();
  const { setIsLoading, setStatusErrorOpenSnackBar, setOpenSnackBar } =
    useStateContext();

  const schema = Yup.object({
    FullName: Yup.string().required("esqueceu o seu Nome"),
    document: Yup.string().required(
      `esqueceu o seu ${isCNPJ ? "CNPJ " : "CPF"}`
    ),
    email: Yup.string().email().required("esqueceu o seu e-mail"),
    phone: Yup.string().required("esqueceu o seu telefone"),
    userName: Yup.string().required("esqueceu o seu Nome de Usuário"),
    password: Yup.string()
      .min(6, "no mínimo 6 caracteres")
      .required("esqueceu a sua senha!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "senhas não são iguais!")
      .required("esqueceu a confirmação de senha!"),
  });

  const formik = useFormik({
    initialValues: {
      FullName: "",
      document: "",
      email: "",
      phone: "",
      userName: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: schema,

    onSubmit: async (values) => {
      setIsLoading(true);

      const MountedUserPost: User = {
        FullName: values.FullName,
        userName: values.userName,
        password: values.password,
        email: values.email,
        phone: onlyNumber(values.phone),
        document: onlyNumber(values.document),
        isCNPJ: isCNPJ,
      };

      const hasPostUser = await signUpUser(MountedUserPost);

      if (hasPostUser) {
        const hasConfirmEmail = await generateConfirmEmail(values.email);

        if (hasConfirmEmail) {
          setOpenSnackBar(true);
          setStatusErrorOpenSnackBar(false);
          setIsLoading(false);
          navigate('/')
        } else {
          setOpenSnackBar(true);
          setIsLoading(false);
          setStatusErrorOpenSnackBar(true);
        }
      } else {
        setOpenSnackBar(true);
        setIsLoading(false);
        setStatusErrorOpenSnackBar(true);
      }
      setIsLoading(false);
    },
  });

  const handleClose = () => {
    setStateExitForm(true);
  };

  const toggleTab = () => {
    setStatePerson(statePerson ? false : true);
  };

  type props = {
    messageTitle: string;
    massageContent: string;
  };

  function ExitForm({ messageTitle, massageContent }: props) {
    const [maxWidthExit] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setStateExitForm(false);
      navigate("/");
    };

    const handleCloseExit = () => {
      setStateExitForm(false);
    };

    return (
      <Dialog
        maxWidth={maxWidthExit}
        open={stateExitForm}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "350px",
            textAlign: "center",
            gap: "1rem",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleCloseExit()}
            />
            <Button
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.ContentBox}>
        <div className={styles.containerHeader}>
          <div className={styles.containerImage}>
            <img src={Logo} alt="Logo Deep Ambiente" width={140} height={90} />
          </div>
          <div className={styles.containerTitle}>
            <h3 className={styles.title}>Cadastro</h3>
          </div>
          <div />
        </div>
        <div className={styles.PersonButton}>
          <button
            className={
              statePerson ? styles.ActiveTabButton : styles.disabledTabButton
            }
            onClick={() => {
              toggleTab();
              setIsCNPJ(false);
            }}
          >
            Pessoa Física
          </button>
          <button
            className={
              statePerson ? styles.disabledTabButton : styles.ActiveTabButton
            }
            onClick={() => {
              toggleTab();
              setIsCNPJ(true);
            }}
          >
            Pessoa Jurídica
          </button>
        </div>
        <div className={styles.formTitleDP}>
          <h3>Dados Pessoais</h3>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className={styles.containerPersonInput}>
            <CustomInput
              size="small"
              variant="outlined"
              id="FullName"
              name="FullName"
              label="Nome Completo"
              value={formik.values.FullName}
              onChange={formik.handleChange}
              error={formik.touched.FullName && Boolean(formik.errors.FullName)}
              helperText={formik.touched.FullName && formik.errors.FullName}
            />
            {/* <InputMask
              mask={statePerson ? maskConfig.cpf : maskConfig.cnpj}
              value={formik.values.document}
              onChange={formik.handleChange}
            >
              {(inputProps: any) => (
                <CustomInput
                  {...inputProps}
                  value={formik.values.document}
                  onChange={formik.handleChange}
                  size="small"
                  variant="outlined"
                  id="document"
                  name="document"
                  label={statePerson ? "CPF" : "CNPJ"}
                  error={
                    formik.touched.document && Boolean(formik.errors.document)
                  }
                  helperText={formik.touched.document && formik.errors.document}
                />
              )}
            </InputMask> */}
            <InputMask
              mask={statePerson ? "999.999.999-99" : "99.999.999/9999-99"}
              value={formatDocument(formik.values.document)}
              onChange={formik.handleChange}
            >
              {(inputProps: any) => (
                <CustomInput
                  {...inputProps}
                  value={formik.values.document}
                  onChange={formik.handleChange}
                  size="small"
                  variant="outlined"
                  id="document"
                  name="document"
                  label={statePerson ? "CPF" : "CNPJ"}
                  error={formik.touched.document && Boolean(formik.errors.document)}
                  helperText={formik.touched.document && formik.errors.document}
                />
              )}
            </InputMask>
            <CustomInput
              size="small"
              variant="outlined"
              id="email"
              name="email"
              label="E-mail"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            {/* <InputMask
              mask={maskConfig.phone}
              value={formik.values.phone}
              onChange={formik.handleChange}
            >
              {(inputProps: any) => (
                <CustomInput
                  {...inputProps}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  size="small"
                  variant="outlined"
                  id="phone"
                  name="phone"
                  label="Telefone"
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              )}
            </InputMask> */}
            <InputMask
              mask="(99) 99999-9999"  // A máscara permanece a mesma
              value={formatPhoneNumber(formik.values.phone)}  // Aplica a formatação ao telefone
              onChange={formik.handleChange}  // Atualiza o valor no formik
            >
              {(inputProps: any) => (
                <CustomInput
                  {...inputProps}
                  value={formik.values.phone}  // Valor do telefone no formik
                  onChange={formik.handleChange}  // Atualiza o valor do telefone no formik
                  size="small"
                  variant="outlined"
                  id="phone"
                  name="phone"
                  label="Telefone"
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              )}
            </InputMask>

            <CustomInput
              size="small"
              variant="outlined"
              id="userName"
              name="userName"
              label="Nome de Usuário"
              value={formik.values.userName}
              onChange={formik.handleChange}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
            />
            <CustomInput
              size="small"
              variant="outlined"
              id="password"
              name="password"
              label="Senha"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <CustomInput
              size="small"
              variant="outlined"
              id="confirmPassword"
              name="confirmPassword"
              label="Confirmar Senha"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </div>

          <div className={styles.dividerHorizontal} />

          <div className={styles.containerButtons}>
            <div onClick={handleClose}>
              <span className={styles.buttonText}>Já tenho conta</span>
            </div>
            <CustomButtonLoading
              type="submit"
              title="Cadastrar"
              className="middle"
            />
          </div>
        </form>
      </div>

      <CustomSnackBar
        action={
          statusResponse === 200 ? <span className="btnSnackBar"></span> : null
        }
      />

      <ExitForm
        messageTitle="Deseja voltar para tela de login?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </div>
  );
}
