import { useEffect, useState } from "react";
import { ReactComponent as NewCalendarIcon } from "../../../../../util/SVGs/calendar.svg";

import Button from '@mui/material/Button';
import { DateTime } from 'luxon';
import AreaChart from "../../../../../components/Charts/AreaChart";
import { DatePickerCalendarIcon } from "../../../../../components/Form/DatePicker";
import ChartFullScreen from "../../../../../components/Modal/ChartFullScreen";

import { useStateContext } from "../../../../../providers/stateContext";
import { useReportContext } from "../../../../../providers/reportContext";
import { useModalContext } from "../../../../../providers/modalContext";

import styles from "./styles.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ComparisonChartType } from "../../../../Comparison";

export default function AreaChartCard({ dataChart, powerDistributor }) {
  const [typeChartModel, setTypeChartModel] = useState("");
  const navigate = useNavigate();

  const {
    setModalChartFullscreenArea,
  } = useModalContext();

  const {
    dateToCallApiForChartArea,
  } = useStateContext();
  const {
  } = useReportContext();

  const [sliderValues, setSliderValues] = useState([0, 24]);
  const now = DateTime.now();
  const initialStartTime = now.startOf('day').toJSDate();
  const initialEndTime = now.endOf('day').toJSDate();
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  let [searchParams, setSearchParams] = useSearchParams();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 375);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 375);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDateChange = (date) => {
    const originalDate = DateTime.fromJSDate(date).startOf('day');
    const newStartHour = sliderValues[0];
    const newEndHour = sliderValues[1] >= 24 ? 23 : sliderValues[1];
    const newStartTime = originalDate.set({
      hour: newStartHour,
      minute: 0,
      second: 0
    }).toJSDate();

    const newEndTime = originalDate.set({
      hour: newEndHour,
      minute: newEndHour === 23 ? 59 : 0,
      second: newEndHour === 23 ? 59 : 0
    }).toJSDate();

    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  function isWeekend(date) {
    const day = DateTime.fromJSDate(date).weekday;
    const isWeekendDay = day === 6 || day === 7;
    return isWeekendDay;
  }

  const isWeekendForChart = isWeekend(startTime);

  useEffect(() => {
    const isWeekendForChart = isWeekend(startTime);
  }, [startTime]);

  function DateForChart() {
    let dateAreaStar = new Date(dateToCallApiForChartArea.start);
    let dateAreaEnd = new Date(dateToCallApiForChartArea.end);
    let dtAreaStart = DateTime.fromMillis(dateAreaStar.getTime());
    let dtAreaEnd = DateTime.fromMillis(dateAreaEnd.getTime());

    let formatAreaDate = {
      end: dtAreaEnd.toUTC().toLocaleString({ month: "long", day: "numeric" }),
      start: dtAreaStart.toUTC().toLocaleString({ month: "long", day: "numeric", year: "numeric" })
    };
    return <p className={styles.date}>{`${formatAreaDate.start}`}</p>;
  }

  function openModalChartFullscreen() {
    searchParams.set('comparison', 'true');
    searchParams.set('chartType', ComparisonChartType.AREA);
    setSearchParams(searchParams);
  }

  return (
    <div className={styles.middleCard}>
      <div className={styles.CardHeader}>
        <div className={styles.containerTitle}>
          <h3>{"Consumo em tempo real"}</h3>
          <h5>{DateForChart()}</h5>
        </div>
        <div className={styles.containerIcon}>
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: isMobile ? 'center' : 'flex-end',
              alignItems: 'end',
              width: '100%',
              gap: '10px',


            }}
          >
            <Button
              onClick={() => openModalChartFullscreen()}
              className={styles.customButton}
            >
              Comparar períodos
            </Button>

            <DatePickerCalendarIcon
              typeChart={"area"}
              className={`${styles.datePicker} ${styles.customDatePicker}`}
              handleDateChange={handleDateChange}
            />
          </div>
        </div>
      </div>

      <div className={styles.AreaChartArea}>
        <AreaChart
          data={dataChart}
          chartSizeType={'normal'}
          powerDistributor={powerDistributor}
          isWeekend={isWeekendForChart}
        />
      </div>
      <ChartFullScreen dataChart={dataChart} typeChart={typeChartModel} />
    </div>
  );
}
