import api from './api';

export function getMeterAllAPI (){
  return api.get(`/api/Mac`);
}

export function getMetersByConsumerUnitAPI (consumerUnitId: number | string) {
  return api.get(`/api/Mac/by-consumer-unit/${consumerUnitId}`);
}

export function getMeterByIdAPI (macId: number | string){
  return api.get(`/api/Mac/${macId}`);
}

export function postMeterAPI (data: any){
  return api.post('/api/Mac', data);
}

export function putMeterAPI (data: any, macId: number | string){
  return api.put(`/api/Mac/${macId}`, data);
}

export function deleteMeterAPI (macId: number | string){
  return api.delete(`/api/Mac/${macId}`);
}

export function associatedSmartMeterAPI (id:number | string ,macId: number | string){
  return api.put(`/api/ConsumerUnit/${id}/associate-mac/${macId}`);
}

export function associatedIntrusiveMeterAPI (id:number | string, body: any){
  return api.put(`/api/Equipment/${id}/associate-mac`, body);
}

export function disassociateSmartMeterAPI (id:number | string ){
  return api.put(`/api/ConsumerUnit/${id}/disassociate-mac`);
}

export function disassociateIntrusiveMeterAPI (id: number | string){
  return api.put(`/api/Equipment/${id}/disassociate-mac`);
}