import React, { useEffect, useState } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import useFormatDate from '../../../util/hooks/useFormartDate';
import styles from './styles.module.scss';
import { useReportContext } from '../../../providers/reportContext';
import { CircularProgress } from '@mui/material';

import useChartConfig from "./ChartConfig";
import { useMeterContext } from '../../../providers/meterContext';

export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const filteredPayload = payload.map((equipment, index) => {
      const formattedValue = equipment.value.toFixed(4).replace('.', ',');
      return (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <p>{formattedValue}kW <span> {equipment.payload.timestamp}</span></p>
        </div>
      );
    });

    return (
      <div style={{
        backgroundColor: '#E0F7FA',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '0.8125rem',
        color: '#808080',
        marginBottom: "40px"
      }}>
        {filteredPayload}
      </div>
    );
  }
  return null;
};


export default function CustomBarChart({ data, chartSizeType, typeFormatDate, selectedEquipment }) {
  const { tickFontSize, containerWidth, containerHeight, margins, tspanFontSize, xOffset, dy, x1, strokeWidth, y2, chartHeight, chartWidth } = useChartConfig();
  const [error, setError] = useState(false);
  const formatDate = useFormatDate();
  const { loadingChartBar } = useReportContext();
  const [formattedData, setFormattedData] = useState([]);

  const { meterList } = useMeterContext();
  const smartMeter = meterList?.smart?.length > 0 ? meterList.smart[0] : null;

  const generate15MinuteData = (data) => {
    const isSmartMeter = smartMeter?.id === selectedEquipment;
    let allValues;

    if (isSmartMeter) {
      allValues = data.smartMeterConsumption.values.map(value => ({
        timestamp: formatDate.formatUTC24(value.timestamp),
        id: selectedEquipment,
        consumption: value.consumption || 0
      }));
      debugger;
    } else {
      allValues = data.equipmentConsumptions.flatMap(equip =>
        equip.values.map(value => ({
          timestamp: formatDate.formatUTC24(value.timestamp),
          id: equip.id,
          consumption: value.consumption || 0
        }))
      );
    }

    const structuredData = allValues.reduce((acc, curr) => {
      const existingTimestamp = acc.find((item) => item.timestamp === curr.timestamp);

      if (existingTimestamp) {
        existingTimestamp[curr.id] = curr.consumption;
      } else {
        acc.push({
          timestamp: curr.timestamp,
          [curr.id]: curr.consumption
        });
      }

      return acc;
    }, []);

    setFormattedData(structuredData);
  };

  const CustomTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value === 0 ? (
          <>
            <line x1={x1} x2={30} y1={0} y2={0} stroke="#BDBDBD" strokeWidth={strokeWidth} />
            <text
              x={xOffset}
              y={5}
              dy={0}
              textAnchor="end"
              fill="#BDBDBD"
              fontSize={tickFontSize}
            >
              {payload.value}
              <tspan fontSize={tspanFontSize}>kW</tspan>
            </text>
          </>
        ) : (
          <text
            x={xOffset}
            y={0}
            dy={6}
            textAnchor="end"
            fill="#BDBDBD"
            fontSize={tickFontSize}
            strokeWidth={1.5}
          >
            {payload.value}
            <tspan fontSize={tspanFontSize}>kW</tspan>
          </text>
        )}
      </g>
    );
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    const valueStr = String(payload.value);
    const isFullHour = valueStr.endsWith(":00");

    return (
      <g transform={`translate(${x},${y})`}>
        {isFullHour && (
          <>
            <line x1={0} x2={0} y1={-2} y2={y2} stroke="#BDBDBD" strokeWidth={strokeWidth} />
            <text
              x={0}
              y={10}
              dy={dy}
              textAnchor="middle"
              fill="#BDBDBD"
              fontSize={tickFontSize}
            >
              {`${valueStr.split(':')[0]}h`}
            </text>
          </>
        )}
      </g>
    );
  };

  useEffect(() => {
    if (data && data.equipmentConsumptions) {
      generate15MinuteData(data);

      const equipmentConsumption = data.equipmentConsumptions.find(
        (equip) => equip.id === selectedEquipment
      );

      if (equipmentConsumption && equipmentConsumption.values.every(val => val.consumption === 0)) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [data, selectedEquipment]);

  if (loadingChartBar) {
    return (
      <div className={styles.blurLoading}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h2>Buscando Informações</h2>
          <CircularProgress className={styles.circle} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>

      </div>
    );
  }

  if (data?.totalConsumption === 0) {
    return (
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>

      </div>
    );
  }

  return (
    <div className={styles.boxChart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={formattedData}
          layout="horizontal"
          margin={margins}
        >
          <CartesianGrid
            horizontal={true}
            vertical={false}
            strokeDasharray="0"
            stroke="#EFEFEF"
          />
          <XAxis
            dataKey="timestamp"
            tickFormatter={(timestamp) => {
              const timestampStr = String(timestamp);
              const hour = timestampStr.split(":")[0];
              return `${hour}h`;
            }}
            interval={3}
            tickSize={0}
            tick={CustomXAxisTick}
            stroke="#BDBDBD"
            strokeWidth={strokeWidth}
          />
          <YAxis
            tickFormatter={(tick) => `${tick} kW`}
            tick={CustomTick}
            tickSize={0}
            stroke="#BDBDBD"
            strokeWidth={strokeWidth}
            axisLine={false}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey={selectedEquipment}
            fill="#676ACA"
            radius={[10, 10, 0, 0]}
            barSize={40} />
        </ComposedChart>
      </ResponsiveContainer>

      {/* {selectedEquipment && (
        <div className={styles.legend}>
          <p><strong>Equipamento:</strong> {selectedEquipment}</p>
        </div>
      )} */}
    </div>
  );
}