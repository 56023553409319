import DatePicker from "react-datepicker";
import React, { useState, useMemo } from "react";
import styles from "./styles.module.scss";
import { DateType } from "../../../../../../util/enums/dateType";
import ptBR from "date-fns/locale/pt-BR";
import CustomDatePicker from "./components/CustomDatePicker";
import CustomAlert from "./customAlert";

interface Props {
  type?: string;
  startDate?: Date;
  endDate?: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  onChange: (args: any) => void;
  minDate?: Date;
}

const DatePickerByType = ({
  type,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  onChange,
}: Props) => {
  const minDatePeriod = new Date();
  minDatePeriod.setDate(minDatePeriod.getDate() - 60);

  const datePickerOptionsByType = useMemo(() => {
    switch (type) {
      case DateType.PERIOD:
        return {
          onChange: onChange,
          startDate: startDate,
          endDate: endDate,
          selectsRange: true,
          selectsStart: true,
          selectsEnd: true,
          startDatePlaceholder: "Data de início",
          endDatePlaceholder: "Data de término",
          calendarStartDay: 0,
          minDate: minDatePeriod,
          maxDate: new Date(),
        };
      case DateType.DATE:
      default:
        return {
          onChange: onChange,
          value: startDate?.toDateString(),
        };
    }
  }, [type, startDate, endDate, onChange]);

  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const closeAlert = () => setAlertMessage(null);

  const handleStartDateChange = (date: Date) => {
    if (!endDate || date <= endDate) {
      setStartDate(date);
    } else {
      setAlertMessage(
        "A data/hora inicial não pode ser maior que a data/hora final. Por favor, selecione um horário válido."
      );
    }
  };

  const handleEndDateChange = (date: Date) => {
    if (!startDate || date >= startDate) {
      setEndDate(date);
    } else {
      setAlertMessage(
        "A data/hora final não pode ser menor que a data/hora inicial. Por favor, selecione um horário válido."
      );
    }
  };

  return (
    <div className={styles.dataPickerByTypeContent}>
      {alertMessage && (
        <CustomAlert message={alertMessage} onClose={closeAlert} />
      )}
      <DatePicker
        selected={startDate}
        inline
        locale={ptBR}
        className={styles.customDatepicker}
        calendarClassName={styles.reactDatePickerHeader}
        maxDate={new Date()}
        fixedHeight
        {...datePickerOptionsByType}
      />
      {type === DateType.DATE && (
        <div className={styles.dataPickerHours}>
          <CustomDatePicker
            selected={startDate}
            wrapperClassName={styles.reactDatepickerWrapper}
            calendarClassName={styles.timeCustomDatepicker}
            onChange={handleStartDateChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
          />

          <CustomDatePicker
            wrapperClassName={styles.reactDatepickerWrapper}
            calendarClassName={styles.timeCustomDatepicker}
            selected={endDate}
            onChange={handleEndDateChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
          />
        </div>
      )}
    </div>
  );
};

export default DatePickerByType;
