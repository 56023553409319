import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useModalContext } from '../../../providers/modalContext';
import Logo from '../../../assets/image/deep-ambiente-logo.svg'

import CustomButton from '../../Form/button';
import CustomInput from '../../CustomInput';
import { useStateContext } from '../../../providers/stateContext';
import { useModelContext } from '../../../providers/modelContext';
import CustomButtonLoading from '../../Form/buttonLoading';

type propsModel = {
  categoryEdit: any
}

type props = {
  messageTitle: string,
  massageContent: string,
}

export function EditCategory(categoryEdit: propsModel) {
  const { modalEditSubCategory, setModalEditSubCategory } = useModalContext()
  const { putCategoryContext } = useModelContext()
  const [maxWidth] = useState<DialogProps['maxWidth']>('xl');
  const [stateExitModal, setStateExitModal] = useState(false)
  const { setIsLoading, setStatusErrorOpenSnackBar, setOpenSnackBar } = useStateContext()
  const { name, id } = categoryEdit.categoryEdit

  useEffect(() => {
    formik.setFieldValue('name', name, false)
  }, [categoryEdit]) // eslint-disable-line react-hooks/exhaustive-deps

  const validationSchema = yup.object({
    name: yup.string().required('O nome é obrigatório!'),
  })

  const formik = useFormik({
    initialValues: {
      name: name,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)

      const hasPutCategory = await putCategoryContext(values, id)
      if (hasPutCategory) {
        setStatusErrorOpenSnackBar(false)
        handleOpenSnackBar()
        setIsLoading(false)
        setModalEditSubCategory(false)
        formik.resetForm()
      } else {
        setIsLoading(false)
        setStatusErrorOpenSnackBar(true)
        handleOpenSnackBar()
      }
    },
  });

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleClose = () => {
    if (
      formik.values.name !== name
    ) {
      setStateExitModal(true);
    } else {
      setModalEditSubCategory(false);
    }
  };


  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps['maxWidth']>('sm');

    const CloseAllModal = () => {
      setModalEditSubCategory(false)
      setStateExitModal(false)
      formik.resetForm()
    }

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"

      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "400px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">{messageTitle}</Typography>
          <Typography component={'span'} variant={'body2'}>{massageContent}</Typography>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '8rem 8rem',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}>
            <CustomButton type="button" title="Não" className="smallOutline" onClick={() => handleClose()} />
            <CustomButton type="button" title="Sim" className="small" onClick={() => CloseAllModal()} />
          </div>
        </Box>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalEditSubCategory}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                padding: "0.8rem 0.7rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center'
                }}
              >
                <DialogTitle sx={{ textAlign: 'center', textTransform: 'uppercase', fontSize: '1rem', marginRight: '-20px' }}>Editar <br /> categoria</DialogTitle>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-end',

                }}
              >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleClose()} />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '0px !important'
              }}
            >
              <CustomInput
                size="small"
                variant="outlined"
                id="name"
                name="name"
                label="Nome"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{
                  width: '400px'
                }}
              />
            </DialogContent>
            <Box
              sx={{
                padding: '20px 24px',
              }}
            >
              <Divider />
            </Box>
            <DialogActions
              sx={{
                padding: '0rem 1.5rem !important'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mb: '1rem'
                }}
              >
                <CustomButtonLoading type="submit" title="Editar" className="middle" />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <ExitModal messageTitle="Deseja cancelar a edição?" massageContent="Os dados preenchidos serão perdidos!" />
    </>
  );
}
