// ChartConfig.js
import { useState, useEffect } from "react";

export default function useChartConfig() {
  const [config, setConfig] = useState({
    tickFontSize: 14,
    tspanFontSize: 10,
    x1: -10,
    xOffset: -40,
    strokeWidth: 1.5,
    y2: 8,
    dy: 15,  // Adicione o valor padrão para dy
    containerWidth: "100%",
    containerHeight: "100%",
    margins: { top: 50, right: 260, left: 45, bottom: -10 },
  });

  useEffect(() => {
    const updateConfig = () => {
      const width = window.innerWidth;
      const height = window.innerHeight; // Definindo 'height' corretamente

      if (width <= 320 && height <= 658) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 90, right: 20, left: -20, bottom: 15 },
        });
      }
      else if (width === 360 && height === 640) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 130, right: 20, left: -20, bottom: 15 },
        });
      }
      else if (width === 375 && height === 667) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 110, right: 20, left: -20, bottom: 32 },
        });
      }
      else if (width <= 500 && height <= 300) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 2,
          x1: -3,
          y2: 3,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 20, right: 100, left: -20, bottom: -10 },
        });
      }
      else if (width <= 800 && height <= 400) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 2,
          x1: -3,
          y2: 3,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 20, right: 170, left: -20, bottom: 17 },
        });
      }
      else if (width <= 900 && height <= 700) {
        setConfig({
          tickFontSize: 4,
          tspanFontSize: 3,
          dy: 0,
          x1: -3,
          y2: 3,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 0, right: 110, left: -20, bottom: -11 },
        });
      }
      else if (width <= 1024 && height <= 450) {
        setConfig({
          tickFontSize: 6,
          tspanFontSize: 6,
          dy: 2,
          x1: -5,
          y2: 4,
          strokeWidth: 1.2,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 10, right: 140, left: -20, bottom: 15 },
        });
        // Outras configurações baseadas em largura e altura
      } else if (width <= 1024 && height <= 700) {
        setConfig({
          tickFontSize: 6,
          tspanFontSize: 6,
          dy: 2,
          x1: -5,
          y2: 4,
          strokeWidth: 1.2,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 0, right: 140, left: -20, bottom: 0 },
        });

      } else if (width <= 1280 && height <= 600) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 6,
          x1: -5,
          y2: 4,
          strokeWidth: 1.2,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 0, right: 140, left: -20, bottom: 10 },
        });

      } else if (width >= 1500 && height >= 2000) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 6,
          x1: -5,
          y2: 4,
          strokeWidth: 1.2,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 80, right: 190, left: -20, bottom: 10 },
        });


      } else if (width <= 320) {
        // Configuração para largura <= 360
        setConfig({
          tickFontSize: 6,
          tspanFontSize: 4,
          dy: 2,
          x1: -3,
          y2: 3,
          strokeWidth: 0.5,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 30, right: 0, left: -20, bottom: 0 },
        });

      } else if (width <= 360) {
        // Configuração para largura <= 360
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 4,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 40, right: 20, left: -20, bottom: 5 },
        });


      } else if (width <= 375) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 5,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 50, right: 20, left: -20, bottom: 12 },
        });
      } else if (width <= 390) {
        setConfig({
          tickFontSize: 8,
          tspanFontSize: 6,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 100, right: 15, left: -15, bottom: 0 },
        });
      } else if (width <= 412) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 7,
          dy: 5,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 100, right: 30, left: -15, bottom: 10 },
        });
      } else if (width <= 414) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 7,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 90, right: 30, left: -15, bottom: 15 },
        });
      } else if (width <= 480) {
        setConfig({
          tickFontSize: 7,
          tspanFontSize: 7,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.8,
          xOffset: -12,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 90, right: 30, left: -20, bottom: 10 },
        });
      } else if (width <= 600) {
        setConfig({
          tickFontSize: 8,
          tspanFontSize: 6,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 0.8,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 30, right: 150, left: 5, bottom: 20 },
        });

      } else if (width <= 768) {
        setConfig({
          tickFontSize: 11,
          tspanFontSize: 7,
          dy: 8,
          x1: -5,
          y2: 5,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 40, right: 180, left: 15, bottom: 5 },
        });
      } else if (width <= 800) {
        setConfig({
          tickFontSize: 13,
          tspanFontSize: 9,
          dy: 8,
          x1: -5,
          y2: 5,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 80, right: 190, left: 25, bottom: -10 },
        });

      } else if (width <= 1024) {
        setConfig({
          tickFontSize: 8,
          tspanFontSize: 6,
          dy: 8,
          x1: -5,
          y2: 5,
          strokeWidth: 1,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 30, right: 140, left: -5, bottom: 10 },
        });

      } else if (width <= 1280) {
        setConfig({
          tickFontSize: 8,
          tspanFontSize: 6,
          dy: 6,
          x1: -5,
          y2: 5,
          strokeWidth: 1.5,
          xOffset: -15,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 10, right: 160, left: 5, bottom: 10 },
        });
      } else if (width <= 1366) {
        setConfig({
          tickFontSize: 10,
          tspanFontSize: 8,
          dy: 12,
          x1: -5,
          y2: 5,
          strokeWidth: 1.5,
          xOffset: -20,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 20, right: 180, left: 5, bottom: 25 },
        });
      } else if (width <= 1440) {
        setConfig({
          tickFontSize: 10,
          tspanFontSize: 8,
          xOffset: -20,
          dy: 12,
          x1: -5,
          y2: 5,
          strokeWidth: 1.5,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 30, right: 190, left: 5, bottom: 35 },
        });
      } else if (width <= 1680) {
        setConfig({
          tickFontSize: 10,
          tspanFontSize: 8,
          dy: 5,
          x1: -5,
          y2: 5,
          strokeWidth: 1.5,
          xOffset: -10,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 30, right: 230, left: 15, bottom: 25 },
        });

      } else if (width <= 1905) {
        setConfig({
          tickFontSize: 12,
          tspanFontSize: 10,
          dy: 9,
          x1: -5,
          y2: 5,
          strokeWidth: 1.5,
          xOffset: -20,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 20, right: 260, left: 35, bottom: 25 },
        });




      } else {
        setConfig({
          tickFontSize: 14,
          tspanFontSize: 12,
          xOffset: -27,
          x1: -10,
          dy: 15,
          strokeWidth: 1.5,
          y2: 8,
          containerWidth: "100%",
          containerHeight: "100%",
          margins: { top: 55, right: 270, left: 49, bottom: 50 },
        });
      }
    };

    updateConfig();
    window.addEventListener("resize", updateConfig);

    return () => window.removeEventListener("resize", updateConfig);
  }, []);





  return config;
}
