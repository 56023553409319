import React, {
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";

import { DateType } from "../../../../util/enums/dateType";
import { Modal, withStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import TypeButtons from "./components/TypeButtons";
import DatePickerByType from "./components/DatePickerByType";
import { startOfDay, endOfDay, subWeeks } from "date-fns";

export interface ModalRef {
  openModal: (startDate?: Date, endDate?: Date) => void;
  closeModal: () => void;
}

interface Props {
  onChangeDate: (startDate?: Date, endDate?: Date, type?: string) => void;
}

const CustomDatePickerModal: ForwardRefRenderFunction<ModalRef, Props> = (
  { onChangeDate }: Props,
  ref
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateType, setDateType] = useState<string>(DateType.DATE); // "DATE" por padrão
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  useImperativeHandle(ref, () => ({
    openModal: (newStartDate?: Date, newEndDate?: Date) => {
      if (newStartDate && newEndDate) {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      }
      setIsOpen(true);
    },
    closeModal: () => setIsOpen(false),
    dateType,
  }));

  // Função para fechar o modal
  const onCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Função para enviar as datas selecionadas
  const onSubmit = () => {
    onChangeDate(startDate, endDate, dateType);
    onCloseModal();
  };

  // Função para atualizar as datas
  const updateDate = (initialDate: Date, finishDate: Date) => {
    setStartDate(initialDate);
    setEndDate(finishDate);
  };

  // Função para atualizar intervalo de datas
  const updateDateByRange = useCallback((dates: Date[]) => {
    const [start, end] = dates;
    updateDate(start, end);
  }, []);

  // Função para atualizar uma única data
  const updateOneDate = useCallback((date: Date) => {
    const start = startOfDay(date);
    const end = endOfDay(date);
    updateDate(start, end);
  }, []);

  // Função que ajusta o comportamento de acordo com o tipo de data selecionado
  const updateDatesByType = useCallback(
    (args: Date | Date[]) => {
      const handleDateUpdate = (date: Date) => {
        const start = startOfDay(date);
        const end = endOfDay(date);
        updateDate(start, end);
      };

      if (dateType === DateType.PERIOD) {
        // Para o tipo PERÍODO, ajusta o intervalo (uma semana atrás até hoje)
        const [start, end] = args as Date[];
        updateDate(start, end);
      } else {
        // Para o tipo DATA, apenas uma data única
        handleDateUpdate(args as Date);
      }
    },
    [dateType, updateDate]
  );

  // Define o valor inicial das datas com base no tipo de data
  useEffect(() => {
    const today = new Date();
    if (dateType === DateType.DATE) {
      setStartDate(startOfDay(today));
      setEndDate(endOfDay(today));
    } else if (dateType === DateType.PERIOD) {
      const lastWeek = subWeeks(today, 1);
      setStartDate(startOfDay(lastWeek));
      setEndDate(endOfDay(today));
    }
  }, [dateType]);

  // Estilos personalizados para o botão
  const ColorButton = withStyles(() => ({
    root: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#14394C",
      padding: "3px !important",
      backgroundColor: "#BDDB6C",
      borderRadius: "16px",
      "&:hover": {
        backgroundColor: "#BDDB6CBF",
      },
    },
  }))(Button);

  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <div className={styles.customModalContainer}>
        <div className={styles.customModalHeader}>
          <CloseIcon onClick={onCloseModal}></CloseIcon>
        </div>
        <div className={styles.customModalBody}>
          <TypeButtons value={dateType} setValue={setDateType} />
          <DatePickerByType
            type={dateType}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
            onChange={updateDatesByType}
          ></DatePickerByType>
        </div>
        <div className={styles.customModalFooter}>
          <ColorButton variant="contained" color="primary" onClick={onSubmit}>
            Ok
          </ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(CustomDatePickerModal);
