import { Grid } from "@material-ui/core";
import styles from "./styles.module.scss";
import { DateType } from "../../../../../../util/enums/dateType";
import { ToggleButtonGroup } from "@mui/material";
import { CustomToggleButton } from "./styles";

interface Props {
  value: string;
  setValue: (value: string) => void;
}
const TypeButtons = ({ value, setValue }: Props) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <ToggleButtonGroup
          className={styles.toggleGroup}
          color="primary"
          value={value}
          exclusive
          onChange={(_, newValue) => {setValue(newValue ?? value)}}
          aria-label="Platform"
        >
          <CustomToggleButton value={DateType.DATE}>
            {DateType.DATE}
          </CustomToggleButton>
          <CustomToggleButton value={DateType.PERIOD}>
            {DateType.PERIOD}
          </CustomToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default TypeButtons;
