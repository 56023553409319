import { ROLE } from "./enums/Role";

export function OrdinationIsAdmin(item) {
  item.sort(function (a, b) {
    if (a.userRole?.name === ROLE.ADMIN) {
      return -1;
    } else {
      return true;
    }
  })
}
