import { useEffect, useState, useRef, useLayoutEffect } from "react";
import styles from "./styles.module.scss";
import "./styles.module.scss";
import { ReactComponent as EquipamentIconOutline } from "../../../assets/equipamentIconOutline.svg";
import { useReportContext } from "../../../providers/reportContext";
import { CircularProgress } from "@mui/material";
import { PieChart, Pie, Legend, Cell, Tooltip } from 'recharts';
import { chartColors } from "../../../util/chartsColors";
import useDimensions from "../../../util/hooks/useDimensions";
import { useZoom } from "../../../util/hooks/useZoom";
import { useStateContext } from "../../../providers/stateContext";


export default function PieChartRecharts({ pieSvg, data, positionLegend, handleSliderChange, startTime, endTime, activeLegends: externalActiveLegends,
  onLegendChange, }) {
  const { equipmentConsumptions } = data;
  const { screenWidth, screenHeight, isTablet, isMidDesktop, isMobile } = useDimensions();
  const { loadingChartPie } = useReportContext();
  const [graphicData, setGraphicData] = useState([]);
  const { sectorId } = useStateContext();

  const [internalActiveLegends, setInternalActiveLegends] = useState({});
  const activeLegends = externalActiveLegends || internalActiveLegends;

  const { zoomLevel, zoomClass } = useZoom();

  const containerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  useLayoutEffect(() => {
    const updateChartDimensions = () => {
      if (containerRef.current) {
        setChartWidth(containerRef.current.offsetWidth);
        setChartHeight(containerRef.current.offsetHeight);
      }
    };

    updateChartDimensions();

    window.addEventListener('resize', updateChartDimensions);
    return () => window.removeEventListener('resize', updateChartDimensions);
  }, [containerRef.current, zoomLevel]);

  useLayoutEffect(() => {
    if (isTablet || isMobile) {

      const updateChartWidth = () => {
        if (containerRef.current) {
          setChartWidth(containerRef.current.offsetWidth);
          setChartHeight(containerRef.current.offsetHeight);
        }
      };

      updateChartWidth();

      window.addEventListener('resize', updateChartWidth);

      return () => window.removeEventListener('resize', updateChartWidth);
    }

  }, [containerRef.current]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      // Extrai os dados do payload
      const { name, value, payload: dataPayload } = payload[0];
      // const timestamp = dataPayload?.timestamp || "Hora não disponível";

      return (
        <div
          style={{
            backgroundColor: '#E0F7FA',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
            padding: '10px',
            borderRadius: '5px',
            fontSize: '0.8125rem',
            color: '#808080',
            marginBottom: "10px",
            display: 'flex',
            flexDirection: 'column', // Alinhamento vertical
            gap: '5px',
          }}
        >
          {/* Nome do item */}
          <span style={{ fontWeight: 'bold', color: '#2566FF' }}>{name || "Equipamento"}</span>

          {/* Valor formatado */}
          <span>
            {value !== undefined
              ? `${value.toFixed(2).replace('.', ',')} kW`
              : "Dados indisponíveis"}
          </span>

          {/* Timestamp, se disponível */}
          {/* <span style={{ fontSize: '0.75rem', color: '#666666' }}>{timestamp}</span> */}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    const initialLegendsState = {};

    graphicData.forEach((item) => {
      // Verifique se 'item.data' está definido antes de chamar 'slice'
      if (item.data) {
        // Filtra os dados com base no intervalo de tempo
        const filteredData = item.data.filter((dataItem) => {
          // Verifica se o timestamp está dentro do intervalo
          const timestamp = dataItem.timestamp;
          return timestamp >= startTime && timestamp <= endTime;
        });

        // Verifica se há consumo maior que zero dentro do intervalo
        const hasConsumption = filteredData.some((dataItem) => dataItem.consumption > 0);

        // Sempre ativa as legendas, independentemente do consumo
        initialLegendsState[item.name] = true;
      } else {
        // Caso 'item.data' seja indefinido, considere a legenda como ativa
        initialLegendsState[item.name] = true;
      }
    });



    setInternalActiveLegends(initialLegendsState);
  }, [graphicData, startTime, endTime]);  // Dependências: graphicData, startTime, endTime

  const handleLegendClick = (entry, payload) => {
    console.log('Legend clicked:', entry.payload.name); // Debug

    const updatedLegends = { ...activeLegends };
    const activeCount = Object.values(activeLegends).filter(Boolean).length;

    if (activeCount === 1 && activeLegends[entry.payload.name]) {
      payload.forEach((item) => {
        updatedLegends[item.payload.name] = true;
      });
    } else if (Object.values(activeLegends).every((isActive) => isActive)) {
      payload.forEach((item) => {
        updatedLegends[item.payload.name] = item.payload.name === entry.payload.name;
      });
    } else {
      updatedLegends[entry.payload.name] = !activeLegends[entry.payload.name];
    }

    if (onLegendChange) {
      onLegendChange(updatedLegends);
    } else {
      setInternalActiveLegends(updatedLegends);
    }
  };



  const CustomLegend = ({ payload }) => {
    const sortedPayload = [...payload].sort((a, b) =>
      a.payload.name.localeCompare(b.payload.name)
    );

    return (
      <ul className={isTablet ? styles.customLegendList__tablet : `${styles.customLegendList} ${styles[zoomClass]}`}>
        {sortedPayload.map((entry, index) => {
          const isActive = activeLegends[entry.payload.name] !== false; // Verifica se a legenda está ativa
          const legendColor = isActive ? entry.color : '#999999';  // Se ativa, usa a cor original, se inativa, usa cinza
          const color = isActive ? "#2566ff" : "#999999"; // Cor azul para ativo, cinza para inativo
          const opacity = isActive ? "0.7" : "0.5"


          return (
            <li
              key={`item-${index}`}
              style={{ listStyleType: 'none' }}
              onClick={() => handleLegendClick(entry, payload)} // Alterna o estado ao clicar
            >
              <div className={styles.legend__item}>
                {/* Dot com a cor da legenda */}
                <span
                  style={{ backgroundColor: legendColor, opacity: opacity }}
                  className={`${styles.customDot} ${styles[zoomClass]}`}
                />
                {/* Texto da legenda com cor e opacidade definidas dinamicamente */}
                <span
                  style={{ color: color, opacity: opacity }} // Define a cor dinamicamente
                  className={`${isTablet ? styles.legendColorBox__tablet : `${styles.legendColorBox} ${styles[zoomClass]}`} ${isActive ? styles.active : styles.inactive
                    }`}
                >
                  {entry.payload.name.length > 16 ? `${String(entry.payload.name).slice(0, 16)}...` : entry.payload.name}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  useEffect(() => {
    if (startTime != null && endTime != null) {
      setGraphicData(mountedArrayForPieChart());
    }
  }, [data, startTime, endTime]);

  function mountedArrayForPieChart() {
    let pieData = [];
    const shouldShowOthers = sectorId === -1;

    if (data && data.equipmentConsumptions) {
      const filteredSmartMeterValues = data?.smartMeterConsumption?.values?.filter(
        (value) =>
          new Date(value.timestamp + 3 * 60 * 60 * 1000).getTime() >= new Date(startTime).getTime() &&
          new Date(value.timestamp + 3 * 60 * 60 * 1000).getTime() <= new Date(endTime).getTime()
      ) ?? [];

      const totalSmartMeterConsumption = filteredSmartMeterValues?.reduce(
        (sum, value) => sum + (value.consumption || 0),
        0
      ) ?? 0;

      const totalSmartMeterPrice = filteredSmartMeterValues?.reduce(
        (sum, value) => sum + (value.price || 0),
        0
      ) ?? 0;

      let totalEquipmentConsumption = 0;
      let totalEquipmentPrice = 0;

      for (const [index, { name, values }] of data.equipmentConsumptions.entries()) {
        const filteredValues = values.filter(
          (value) =>
            new Date(value.timestamp + 3 * 60 * 60 * 1000).getTime() >= new Date(startTime).getTime() &&
            new Date(value.timestamp + 3 * 60 * 60 * 1000).getTime() <= new Date(endTime).getTime()
        );

        let equipmentConsumption = 0;
        let equipmentPrice = 0;

        for (const { consumption, price } of filteredValues) {
          equipmentConsumption += consumption;
          equipmentPrice += price;
        }

        if (equipmentConsumption !== 0) {
          const colorIndex = index % chartColors.length;

          // Adicionando o payloadName para cada entrada
          const payloadName = name;
          pieData = pieData.concat({
            x: equipmentPrice,
            y: equipmentConsumption,
            name: payloadName,
            color: chartColors[colorIndex],
          });


        }

        totalEquipmentConsumption += equipmentConsumption;
        totalEquipmentPrice += equipmentPrice;
      }

      if (shouldShowOthers) {
        const otherConsumption = totalSmartMeterConsumption - totalEquipmentConsumption;
        const otherPrice = totalSmartMeterPrice - totalEquipmentPrice;

        if (otherConsumption > 0) {
          pieData = pieData.concat({
            x: otherPrice,
            y: otherConsumption,
            name: "Outros",
            color: "#4d908e",
          });

        }
      }
    }

    return pieData;
  }

  if (loadingChartPie) {
    return (
      <div className={styles.blurLoading}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h2>Buscando Informações</h2>
          <CircularProgress className={styles.circle} />
        </div>
      </div>
    );
  };

  if (data?.totalConsumption === 0) {
    return (
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>
      </div>
    );
  };

  if (Object.keys(data).length > 0 && equipmentConsumptions.length > 0) {
    const COLORS = ['#0025c9', '#00ab1a', '#FFBB28', '#FF8042'];
    const handleLegendClick = (entry) => console.log(entry);

    const isCardLayoutInColumns = screenWidth > 800;
    const isZoomLevel500 = zoomLevel === 500;
    const isZoomLevel400 = zoomLevel === 400;
    const isZoomLevel300 = zoomLevel === 300;
    const isZoomLevel250 = zoomLevel === 250;
    const isZoomLevel200 = zoomLevel === 200;
    const isZoomLevel175 = zoomLevel === 175;

    const is400or500 = isZoomLevel400 || isZoomLevel500;

    const dimensions = {
      mobile: {
        // when using zoom 400, the project is interpreted as a mobile layout
        width: is400or500 ? .5 * screenWidth : chartWidth,
        height: chartHeight,
        innerRadius: isZoomLevel400 ? 22 : isZoomLevel500 ? 20 : 25,
        outerRadius: isZoomLevel400 ? 40 : isZoomLevel500 ? 30 : 50,
        cx: "50%",
        cy: isZoomLevel500 ? "44%" : "40%",
      },
      tablet: {
        width: chartWidth,
        height: Math.round(0.2832 * screenHeight),
        innerRadius: Math.round(0.06875 * screenWidth),
        outerRadius: Math.round(0.11875 * screenWidth),
        cx: "50%",
        cy: isZoomLevel200 ? "40%" : "45%",
      },
      midDesktop: {
        width: Math.round((isZoomLevel175 || isZoomLevel200 ? 0.22 : isZoomLevel250 || isZoomLevel300 ? 0.40 : 0.25) * screenWidth),
        height: Math.round((isZoomLevel200 ? 0.26 : isZoomLevel250 || isZoomLevel300 ? 0.37 : 0.29) * screenHeight),
        innerRadius: Math.round((isZoomLevel200 ? 0.030 : isZoomLevel250 || isZoomLevel300 ? 0.045 : 0.037) * screenWidth),
        outerRadius: Math.round((isZoomLevel200 ? 0.055 : isZoomLevel250 || isZoomLevel300 ? 0.090 : 0.062) * screenWidth),
        cx: "45%",
        cy: "50%",
      },
      desktop: {
        width: Math.round(0.25 * screenWidth),
        height: Math.round(0.29 * screenHeight),
        innerRadius: Math.round(0.034 * screenWidth),
        outerRadius: Math.round(0.065 * screenWidth),
        cx: "45%",
        cy: "48%",
      },
    };

    const activeLayout = isMobile
      ? dimensions.mobile
      : isTablet
        ? dimensions.tablet
        : isMidDesktop
          ? dimensions.midDesktop
          : dimensions.desktop;

    const activeData = graphicData
      .map((entry) => ({
        ...entry,
        y: activeLegends[entry.name] === false ? 0 : entry.y,
        opacity: activeLegends[entry.name] === false ? 0.3 : 1,
      }))
      .sort((a, b) => b.name.localeCompare(a.name));

    return (

      <div className={styles.flexContainer}>
        <div
          ref={containerRef}
          className={isTablet ? styles.chartContainer__tablet : styles.chartContainer}>
          <PieChart width={activeLayout.width} height={activeLayout.height}>
            <Pie
              data={activeData}
              dataKey="y"
              nameKey="name"
              cx={activeLayout.cx}
              cy={activeLayout.cy}
              labelLine={false}
              innerRadius={activeLayout.innerRadius}
              outerRadius={activeLayout.outerRadius}
              fill="#8884d8"
              stroke="none"
              isAnimationActive={true}
              animationDuration={1100}
            >             {activeData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} opacity={entry.opacity} />
            ))}
            </Pie>
            <Tooltip
              content={<CustomTooltip />}
              formatter={(value, name, props) => {
                return [
                  `${value?.toFixed(2)} kWh - R$ ${(props.payload?.payload?.x || 0).toFixed(2)}`,
                  props.payload?.name || name
                ];
              }}
            />
            <Legend
              content={<CustomLegend />}
              onClick={(e) => handleLegendClick(e)}
              layout={(isMobile && !is400or500) || isTablet ? "horizontal" : "vertical"}
              align={(isMobile && !is400or500) || isTablet ? "center" : "right"}
              verticalAlign={isMobile || isTablet ? "bottom" : "middle"}
            />
          </PieChart>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.NotConsumer}>
      <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>
      <EquipamentIconOutline color="#0f85ca" />
    </div>
  );
}