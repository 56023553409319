export class StringUtils {
  /**
   * Formata um número inteiro no formato de dinheiro brasileiro (R$)
   * @param value O valor inteiro a ser formatado
   * @returns O valor formatado como string no formato de R$
   */
  static formatToBRL(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  /**
   * Retorna o valor formatado no padrão BRL com "R$", parte inteira e centavos separados.
   * @param value O valor a ser analisado
   * @returns Um objeto com o valor completo formatado e as partes separadas para uso individual
   */
  static getBRLParts(value?: number): { integer: string; decimal: string } {
    // Garante que `value` não seja `undefined` ou `null`, usando 0 como valor padrão
    const safeValue = value ?? 0;

    // Formata o valor como moeda brasileira
    const formattedValue = safeValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    // Remove o prefixo `R$` e espaços para evitar problemas
    const sanitizedValue = formattedValue.replace(/[^\d,]/g, '');

    // Extrai a parte inteira e decimal com base na vírgula
    const [integer, decimal] = sanitizedValue.split(',');

    return {
      integer: integer || '0',
      decimal: decimal || '00',
    };
  }
}  