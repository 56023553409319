import styled from "@emotion/styled";
import { ToggleButton } from "@mui/material";

export const CustomToggleButton = styled(ToggleButton)(() => {
  return {
    fontFamily:"Inter",
    width: "50%",
    padding: "4px",
    fontSize: "14px",
    fontWeight: 600,
    backgroundColor: "#E9EAE6",
    color: "#18445B",
    "&:hover":{
        color: "#18445BBF",
        backgroundColor: "#E9EAE6BF",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "#14394C",
      backgroundColor: "#BDDB6C",
    },
  };
});
