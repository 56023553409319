import { useState, useEffect } from 'react';

interface Dimensions {
  screenWidth: number;
  screenHeight: number;
  isMobile: boolean;
  isTablet: boolean;
  isMidDesktop: boolean;
  isDesktop: boolean;
}

function useDimensions(): Dimensions {
  const [dimensions, setDimensions] = useState<Dimensions>({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    isMobile: window.innerWidth <= 600,
    isTablet: window.innerWidth >= 600 && window.innerHeight > window.innerWidth * 1.1,
    isMidDesktop: window.innerWidth > 480 && window.innerWidth <= 1800,
    isDesktop: window.innerWidth > 1800,
  });

  useEffect(() => {
    function handleResize() {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      setDimensions({
        screenWidth: newWidth,
        screenHeight: newHeight,
        isMobile: newWidth <= 600,
        isTablet: newWidth >= 600 && newWidth <= 1024 && newHeight > newWidth * 1.1,
        isMidDesktop: newWidth > 480 && newWidth <= 1800,
        isDesktop: newWidth > 1800,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return dimensions;
}

export default useDimensions;
