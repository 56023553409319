import { useNavigate, Link, useSearchParams } from "react-router-dom";

import { ReactComponent as Home } from "../../util/SVGs/home.svg"; // Importa seu ícone SVG
import { ReactComponent as Chart } from "../../util/SVGs/chart.svg";
import { ReactComponent as Electricity } from "../../util/SVGs/electricity.svg";
import { ReactComponent as Setting } from "../../util/SVGs/setting.svg";
import { ReactComponent as Help } from "../../util/SVGs/help.svg";
import { ReactComponent as HomeBlue } from "../../util/SVGs/home-Blue.svg";
import { ReactComponent as ChartBlue } from "../../util/SVGs/chart-Blue.svg";
import { ReactComponent as ElectricityBlue } from "../../util/SVGs/electricity-Blue.svg";
import { ReactComponent as SettingBlue } from "../../util/SVGs/setting-Blue.svg";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import PowerIcon from "@mui/icons-material/Power";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Logo from "../../assets/image/deep-ambiente-logo.svg";
import TinyLogo from '../../assets/image/tiny-white-logo.svg'
import HelpIcon from '../../assets/image/help-icon.svg'
import { useStateContext } from "../../providers/stateContext";
import { useAuth } from "../../providers/authContext";
import BarChartIcon from '@mui/icons-material/BarChart';
import { useEffect, useState } from "react";
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { colors } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { ROLE } from "../../util/enums/Role";
import styles from "./styles.module.scss";

let drawerWidth = "20vw !important";
window.innerWidth < 1550 ? drawerWidth = "20vw !important" : drawerWidth = "20vw !important"
window.innerWidth < 990 ? drawerWidth = "25rem !important" : drawerWidth = "20vw !important"

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: window.innerWidth > 900 ? "5vw !important" : "12vw !important",
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex !important",
  alignItems: "center !important",
  justifyContent: 'flex-end !important',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface Props {
  windows?: () => Window;
  searchConsumerUnit?: boolean;
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function SideMenu(props: Props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { setShowSideMenu } = useStateContext();
  const [role, setRole] = useState("");
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isResponsive, setIsResponsive] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const height = window.innerHeight;
      setIsResponsive(height < 700); // Define como responsivo se a altura for menor que 700px
    };

    handleResize(); // Verifica o tamanho inicial da janela
    window.addEventListener("resize", handleResize); // Escuta alterações no tamanho da janela
    return () => window.removeEventListener("resize", handleResize); // Remove o listener ao desmontar
  }, []);




  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getRoleLocalStorage = async () => {
      const getRole = await localStorage.getItem("role");
      if (getRole) setRole(getRole);
    };

    getRoleLocalStorage();
  }, []);

  const handleDrawerOpen = () => {
    if (screenWidth < 1024) {
      setBurgerMenuOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (screenWidth < 1024) {
      setBurgerMenuOpen(false);
    } else {
      setOpen(false);
    }
  };

  const handleExit = async () => {
    setShowSideMenu(false);
    logout();
    navigate("/");
  };

  interface IListItemCustom {
    label: string;
    icon: any;
    hoverIcon: any;
    link: string;
    onClick: () => void;
    open: boolean;
  }

  const ListItemCustom = ({ label, icon: Icon, hoverIcon: HoverIcon, link, onClick, open }: IListItemCustom) => {
    const [isHovered, setIsHovered] = useState(false);
    const isIconSameAsHoverIcon = Icon === HoverIcon;
    const currentPath = window.location.pathname;
    const isActive = currentPath === link;

    return (
      <Link to={link} onClick={onClick}>
        <ListItem
          button
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            borderRadius: '0.5rem',
            marginLeft: open ? "5%" : "20%",
            width: open ? "90%" : "60%",
            display: "flex",
            alignItems: "center",
            justifyContent: open ? 'initial' : 'center',
            color: "#FFFFFF",
            height: 'auto',
            marginTop: '0rem',
            backgroundColor: isActive || isHovered ? '#bddb6c' : 'transparent',
            marginBottom: '0',
            '@media (min-width: 1024px) and (max-width: 1440px)': {
              marginLeft: open ? "5%" : "10%",
              width: open ? "90%" : "80%",
            },
            '@media (min-width: 360px) and (max-width: 1023px)': {
              marginLeft: open ? "5%" : "5%",
              width: open ? "90%" : "90%",
            },

            '&:hover': {
              backgroundColor: '#bddb6c',
              borderRadius: '8px',
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 'auto' : 'auto',
              justifyContent: 'center',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              width: open ? 'auto' : '7vw',
            }}
          >
            <Avatar sx={{
              color: "#BDDB6C",
              background: "none",
              width: 40,
            }}>
              {isHovered || isActive
                ? (isIconSameAsHoverIcon
                  ? <Icon className={styles.sideIcon} style={{ fill: "#14394C", opacity: open ? 1 : 0.6 }} />
                  : <HoverIcon className={styles.sideIcon} style={{ opacity: open ? 1 : 0.6 }} />)
                : <Icon className={styles.sideIcon} style={{ opacity: open ? 1 : 0.6 }} />
              }
            </Avatar>
          </ListItemIcon>
          <ListItemText
            sx={{
              span: {
                opacity: open || isBurgerMenuOpen ? 1 : 0,
                fontWeight: "800",
                fontSize: "1rem",
                color: isActive || isHovered ? '#14394C' : '#FFFFFF',
                '@media (min-width:1025px) and (max-width:1440px)': {
                  fontSize: '0.8rem'
                },
                '@media (min-width:901px) and (max-width:1024px)': {
                  fontSize: '0.7rem',

                },
                '&:hover': {
                  color: '#14394C',
                },
              },
            }
            }
            primary={label}
          />
        </ListItem>
      </Link>
    );
  };

  return (
    <div
      className={styles.mainDiv}
      onMouseEnter={() => handleDrawerOpen()}
      onMouseLeave={() => handleDrawerClose()}
      style={{ position: 'relative' }}
    >
      <Box sx={{ display: "flex", maxWidth: "20vw", padding: '1.5rem', position: 'relative' }}>
        <CssBaseline />
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: isBurgerMenuOpen || open ? "100vw" : "20vw",
              backgroundColor: '#14394C',
              borderRadius: "1rem",
              marginTop: "1.5vh",
              marginLeft: "1.5vw",
              height: "97vh",




            },
          }}
          className={styles.drawerContainer}
          variant="permanent"
          open={isBurgerMenuOpen || open}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerHeader
            style={{
              display: "flex",
              padding: "0rem",
              flexDirection: "column",
              justifyContent: "center",

            }}
          >
            <IconButton
              sx={{
                display: screenWidth < 1024 ? "block" : "none",
                color: "#BDDB6C",
                marginLeft: "0rem",
                marginTop: "2rem",
              }}
              onClick={() => setBurgerMenuOpen(!isBurgerMenuOpen)}
              aria-label="toggle burger menu"
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              sx={{
                color: "#BDDB6C",
                marginTop: "2rem",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              aria-label="toggle drawer"
              edge="start"
            >

            </IconButton>
            {open ? (
              <div className={styles.imgContainer}>
                <img className={styles.imgG} src={Logo} alt="Logo Deep Ambiente" />
              </div>
            ) : (
              <img className={styles.imgPq} src={TinyLogo} alt="Logo Deep Ambiente" />
            )}
          </DrawerHeader>
          <Box sx={{
            height: "50%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}>

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                position: 'relative'


              }}
            >

              <List sx={{ marginTop: '2rem' }}>
                <ListItemCustom label="Gráficos" icon={BarChartIcon} hoverIcon={BarChartIcon} link="/dashboard" onClick={handleDrawerClose} open={open} />
                <ListItemCustom label="Meu Perfil" icon={PersonIcon} hoverIcon={PersonIcon} link="/dashboard/myProfile" onClick={handleDrawerClose} open={open} />
                <ListItemCustom label="Unidades Consumidoras" icon={PowerIcon} hoverIcon={PowerIcon} link="/dashboard/consumerUnit" onClick={handleDrawerClose} open={open} />
                {role === ROLE.ADMIN && (
                  <ListItemCustom label="Gerenciar Usuários" icon={ManageAccountsIcon} hoverIcon={ManageAccountsIcon} link="/dashboard/userManagement" onClick={handleDrawerClose} open={open} />
                )}
                <ListItemCustom label="Gerenciar Equipamentos" icon={SettingsSuggestIcon} hoverIcon={SettingsSuggestIcon} link="/dashboard/equipmentManagement" onClick={handleDrawerClose} open={open} />
                <ListItemCustom label="Gerenciar Medidores" icon={MiscellaneousServicesIcon} hoverIcon={MiscellaneousServicesIcon} link="/dashboard/smartMeterManagement" onClick={handleDrawerClose} open={open} />
                <ListItemCustom label="Gerenciar Setores" icon={SettingsIcon} hoverIcon={SettingsIcon} link="/dashboard/modelManagement" onClick={handleDrawerClose} open={open} />
              </List>
              < Divider />
              <ListItemCustom
                label="Sair"
                icon={ExitToAppIcon}
                hoverIcon={ExitToAppIcon}
                link="#"
                onClick={handleExit}
                open={open}
              />


            </Box>





          </Box >

          <IconButton
            onClick={() => {
              window.open("http://www.deepambiente.com.br/contact/", "_blank", "noreferrer");
            }}
            sx={{
              color: "#F5F8EC",
              position: "absolute",
              bottom: "2rem",
              left: "50%",
              transform: "translateX(-50%)", // Centraliza horizontalmente
              gap: "0.4rem",




            }}
          >
            <img className={styles.help__icon} src={HelpIcon} alt="Logo Deep Ambiente" />
            {open && <span className={styles.help__word}>Ajuda</span>}
          </IconButton>



        </Drawer >
      </Box >
    </div >
  );
}