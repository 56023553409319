import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { DateTime } from 'luxon';
import BarChart from "../../../../../components/Charts/BarChart";
import { DatePickerCalendarIcon } from "../../../../../components/Form/DatePicker";
import ChartFullScreen from "../../../../../components/Modal/ChartFullScreen";
import { useStateContext } from "../../../../../providers/stateContext";
import styles from "./styles.module.scss";
import Select from 'react-select';
import { ComparisonChartType } from "../../../../Comparison";
import { useSearchParams } from "react-router-dom";
import { useModelContext } from "../../../../../providers/modelContext";
import { useConsumerUnitContext } from "../../../../../providers/consumerUnitContext";
import { useMeterContext } from "../../../../../providers/meterContext";

export default function BarChartCard({ dataChart }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [typeChartModel, setTypeChartModel] = useState("");
  const [selectedDate, setSelectedDate] = useState(DateTime.now());
  const [selectedEquipment, setSelectedEquipment] = useState(null);

  const { dateToCallApiForChartBar, selectConsumerUnitId, sectorId } = useStateContext();
  const { stateModelList } = useModelContext();
  const { consumerUnitList, consumerUnitData } = useConsumerUnitContext();

  const { meterList } = useMeterContext();
  const smartMeter = meterList?.smart?.length > 0 ? meterList.smart[0] : null;

  const consumerUnitSelected = consumerUnitList?.find((unit) => unit.id === selectConsumerUnitId) ?? consumerUnitData;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const DateForChart = () => {
    let dateBarStart = new Date(dateToCallApiForChartBar.start);
    let dateBarEnd = new Date(dateToCallApiForChartBar.end);
    let dtBarStart = DateTime.fromMillis(dateBarStart.getTime());
    let dtBarEnd = DateTime.fromMillis(dateBarEnd.getTime());

    let formatBarDate = {
      start: dtBarStart.toUTC().toLocaleString({ month: "long", day: "numeric", year: "numeric" }),
      end: dtBarEnd.toUTC().toLocaleString({ month: "long", day: "numeric" })
    };
    return <p className={styles.date}>{`${formatBarDate.start}`}</p>;
  };

  function openComparisonScreen() {
    searchParams.set('comparison', 'true');
    searchParams.set('chartType', ComparisonChartType.BAR);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (equipmentsThatBelongToSector?.length) {
      const firstEquipment = equipmentsThatBelongToSector[0];
      setSelectedEquipment({
        value: firstEquipment.id,
        label: firstEquipment.name
      });
    } else {
      setSelectedEquipment(null);
    }
  }, [consumerUnitSelected, sectorId]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const truncated = text.slice(0, maxLength).trim();
    return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
  };

  const equipmentsThatBelongToSector = sectorId === -1 ? consumerUnitSelected?.equipments : consumerUnitSelected?.equipments?.filter((equip) => equip.equipmentModelId === sectorId);



  const equipmentsThatBelongToSectorPlusSmartMeter = smartMeter && equipmentsThatBelongToSector
    ? [smartMeter, ...equipmentsThatBelongToSector]
    : equipmentsThatBelongToSector;

  // Ordena os equipamentos por nome em ordem alfabética
  const sortedEquipments = equipmentsThatBelongToSectorPlusSmartMeter
    ? equipmentsThatBelongToSectorPlusSmartMeter.sort((a, b) => {
      if (a.id === smartMeter?.id) return -1; // Smart meter primeiro
      if (b.id === smartMeter?.id) return 1;
      return a.name.localeCompare(b.name); // Ordem alfabética para os demais
    })
    : [];

  const getSectorSelectPlaceholderText = () => {
    const zeroEquipmentsFoundInSector = equipmentsThatBelongToSector?.length === 0;

    if (stateModelList?.length === 0) return "Crie um setor";
    return zeroEquipmentsFoundInSector ? "Setor vazio" : "Carregando...";
  };




  return (
    <div className={styles.largeCard}>
      <div className={styles.CardHeader}>
        <div className={styles.containerTitle}>
          <h3>{"Demanda de consumo"}</h3>
          <h5>{DateForChart()}</h5>
        </div>
        <div className={styles.containerIcon}>
          <Select
            placeholder={getSectorSelectPlaceholderText()}
            value={selectedEquipment}
            onChange={(e) => setSelectedEquipment(e)}
            options={
              sortedEquipments.length > 0
                ? sortedEquipments.map((equip) => ({
                  value: equip.id,
                  label: (
                    <span style={{ fontWeight: equip.id === smartMeter?.id ? "bold" : "inherit" }}>
                      {truncateText(equip.name, 20)}
                      {equip.id === smartMeter?.id && <span style={{ fontWeight: "bold" }}> (QGBT)</span>}
                    </span>
                  ),
                }))
                : [{ value: "", label: "Aguardando dados..." }]
            }

            styles={customStyles}
          />
          <div className={styles.buttonCalendarContainer}>
            <Button
              onClick={() => openComparisonScreen()}
              style={{
                backgroundColor: "#FFFFFF",
                border: "2px solid #EAF2F6",
                color: "#14394C",
                borderRadius: "25px",
                padding: "5px 10px",
                fontSize: window.innerWidth <= 480 ? '10px' : '12px',
                cursor: "pointer",
                marginRight: "20px",
                width: "95px",
                height: window.innerWidth <= 600 ? '35px' : '45px',
                marginTop: window.innerWidth <= 600 ? '-4px' : '0px',
              }}

            >
              Ver mais
            </Button>

            <DatePickerCalendarIcon
              typeChart={"bar"}
              className={styles.datePicker}
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              style={{ width: "90px", height: "50px" }}
            />
          </div>
        </div>
      </div>

      <div className={styles.BarChartArea}>
        <BarChart
          width={1500}
          height={350}
          data={dataChart}
          typeFormatDate="hours"
          chartSizeType={"normal"}
          selectedEquipment={selectedEquipment ? selectedEquipment.value : ""} // Passa apenas o nome do equipamento
        />
      </div>
      <ChartFullScreen dataChart={dataChart} typeChart={typeChartModel} />
    </div>
  );
}

// customStyles para o Select
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#44521B' : '#44521B',
    padding: '0.3125rem 0.625rem',
    borderRadius: '0.5rem',
    margin: '5px 0',
    fontWeight: '300',
    border: '0.5px solid #C9DA9A',
    backgroundColor: state.isSelected ? '#EAF2F6' : 'transparent',
    '&:hover': {
      backgroundColor: '#97B73C',
      borderRadius: '8px',
    },
  }),
  control: (provided) => ({
    ...provided,
    display: 'inline-flex',
    padding: '0rem 0rem 0rem 1rem',
    marginRight: window.innerWidth <= 600 ? '0px' : '20px',

    height: window.innerWidth <= 480 ? '30px' : '44px',
    width: window.innerWidth <= 360 ? '145px' : window.innerWidth <= 414 ? '160px' : window.innerWidth <= 600 ? '187px' : '199px',
    borderRadius: '73px', // Radius for rounded corners
    backgroundColor: '#FFF',
    zIndex: 10,
    border: 'none',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Leve sombra ao redor do controle
    fontFamily: 'Inter, sans-serif',
    fontWeight: '300',
    fontSize: window.innerWidth <= 414 ? '14px' : window.innerWidth <= 600 ? '16px' : '20px',
    color: '#14394C',
    transition: 'all 0.3s',

    paddingRight: '7px', // Padding Right

    paddingLeft: '10px', // Padding Left

  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 20,
    border: '0.5px solid black',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '25px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Inter, sans-serif',
    color: '#14394C',
    fontWeight: 600,
    fontSize: window.innerWidth <= 414 ? '14px' : window.innerWidth <= 600 ? '16px' : '20px',
    whiteSpace: 'nowrap',   // Impede quebra de linha
    overflow: 'hidden',     // Esconde o texto excedente
    textOverflow: 'ellipsis', // Adiciona reticências ao final do texto
    maxWidth: '150px',      // Define a largura máxima para truncamento (ajuste conforme necessário)
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: '1.5rem',
    backgroundColor: '#EAF2F6',
    // padding: '0.75rem',
    cursor: 'pointer',
    gap: '0.225rem',
    transition: 'background-color 0.3s',
    color: '#14394C',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
};