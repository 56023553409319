import React from "react";
import { DatePickerCalendarIcon1, DatePickerCalendarIcon2 } from "../../../../components/Form/DatePicker";
import AreaChartComp from "../../../../components/Charts/AreaChartComp";
import { useStateContext } from "../../../../providers/stateContext";
import { DateTime } from "luxon";
import { DateUtils } from "../../../../util/dateUtils";
import { getReportConsumerUnitForIntervalAPI } from "../../../../services/restApiReport";

import styles from "./styles.module.scss";

interface AreaComparisonProps { }

export default function AreaComparison({ }: AreaComparisonProps) {
  const { dateToCallApiForChartArea1, dateToCallApiForChartArea2, selectConsumerUnitId, sectorId } = useStateContext();

  const [areaData1, setAreaData1] = React.useState([] as any);
  const [areaData2, setAreaData2] = React.useState([] as any);

  const [loadingChart1, setLoadingChart1] = React.useState(false);
  const [loadingChart2, setLoadingChart2] = React.useState(false);

  let dateAreaStart1 = new Date(dateToCallApiForChartArea1.start);
  let dateAreaEnd1 = new Date(dateToCallApiForChartArea1.end);
  let dtAreaStart = DateTime.fromMillis(dateAreaStart1.getTime());
  let dtAreaEnd = DateTime.fromMillis(dateAreaEnd1.getTime());

  let formatAreaDate = {
    end: dtAreaEnd.plus({ days: 1 }).toJSDate().toLocaleDateString("pt-BR", { day: "numeric", month: "long", year: "numeric" }),
    start: dtAreaStart.plus({ days: 1 }).toJSDate().toLocaleDateString("pt-BR", { day: "numeric", month: "long", year: "numeric" })
  };

  let dateAreaStart2 = new Date(dateToCallApiForChartArea2.start);
  let dateAreaEnd2 = new Date(dateToCallApiForChartArea2.end);
  let dtAreaStart2 = DateTime.fromMillis(dateAreaStart2.getTime());
  let dtAreaEnd2 = DateTime.fromMillis(dateAreaEnd2.getTime());

  let formatAreaDate2 = {
    end: dtAreaEnd2.plus({ days: 1 }).toJSDate().toLocaleDateString("pt-BR", { day: "numeric", month: "long", year: "numeric" }),
    start: dtAreaStart2.plus({ days: 1 }).toJSDate().toLocaleDateString("pt-BR", { day: "numeric", month: "long", year: "numeric" })
  };

  React.useEffect(() => {
    let fStart1 = DateTime.fromJSDate(
      new Date(dateAreaStart1.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");
    let fEnd1 = DateTime.fromJSDate(
      new Date(dateAreaEnd1.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");
    getDataforAreaChart1(fStart1, fEnd1);
  }, [dateToCallApiForChartArea1, sectorId]);

  React.useEffect(() => {
    let fStart = DateTime.fromJSDate(
      new Date(dateAreaStart2.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");
    let fEnd = DateTime.fromJSDate(
      new Date(dateAreaEnd2.getTime() + (24 * 60 * 60 * 1000))
    )
      .setLocale("GTM")
      .toFormat("yyyy-MM-dd");
    getDataforAreaChart2(fStart, fEnd);
  }, [dateToCallApiForChartArea2, sectorId]);

  const getDataforAreaChart1 = async (startDate: string, endDate: string) => {
    if (!DateUtils.isValidDate(startDate) && !DateUtils.isValidDate(endDate)) {
      return;
    };

    setLoadingChart1(true);

    const { data, status } = await getReportConsumerUnitForIntervalAPI(selectConsumerUnitId, "1800", startDate, endDate, sectorId).then();
    if (status === 200) {
      setAreaData1(data);
      setLoadingChart1(false);
    } else {
      setAreaData1(null);
      setLoadingChart1(false);
    }
  };

  const getDataforAreaChart2 = async (startDate: string, endDate: string) => {
    if (!DateUtils.isValidDate(startDate) && !DateUtils.isValidDate(endDate)) {
      return;
    };

    setLoadingChart2(true);

    const { data, status } = await getReportConsumerUnitForIntervalAPI(selectConsumerUnitId, "1800", startDate, endDate, sectorId).then();

    if (status === 200) {
      setAreaData2(data);
      setLoadingChart2(false);
    } else {
      setAreaData2(null);
      setLoadingChart2(false);
    }
  };

  return (
    <>

      <div className={styles.chartsContainer}>

        <div className={styles.chartContainer}>

          <div className={styles.dateFormattedContainer}>

            <div className={styles.dateAndConsumptionContainer}>
              <span className={styles.areaDate}>{formatAreaDate.start}</span>
              <span className={styles.totalConsumption}>{"Consumo Total: " + (Number(areaData1.totalConsumption ?? 0)).toFixed(2) + "kWh"}</span>
            </div>

            <DatePickerCalendarIcon1 />
          </div>

          {/* TODO: think how to make this mesures reponsives */}
          <AreaChartComp width={1400} height={350} data={areaData1} chartSizeType={'fullscreen'} loadingChart1={loadingChart1} />

        </div>

        <div className={styles.chartContainer}>

          <div className={styles.dateFormattedContainer}>

            <div className={styles.dateAndConsumptionContainer}>
              <span className={styles.areaDate}>{formatAreaDate2.start}</span>
              <span className={styles.totalConsumption}>{"Consumo Total: " + (Number(areaData2?.totalConsumption ?? 0)).toFixed(2) + "kWh"}</span>
            </div>

            <DatePickerCalendarIcon2 />
          </div>

          {/* TODO: think how to make this mesures reponsives */}
          <AreaChartComp width={1400} height={350} data={areaData2} chartSizeType={'fullscreen'} loadingChart2={loadingChart2} />

        </div>

      </div>
    </>
  )
}