import React, { createContext, ReactNode, useContext, useState } from "react"
import { delBusinessType, delCategory, delModel, getAllBusinessType, getAllCategory, getAllModel, getAllModelsByConsumerUnit, postBusinessType, postCategory, postModel, putBusinessType, putCategory, putModel } from "../services/restApiEquipmentModels"
import { ModelCategory, ModelBusinessType, EquipmentModel, ModelCategoryResponse, ModelBusinessTypeResponse } from "../util/types"
import { useStateContext } from "./stateContext"

type PropsModelContext = {
  stateModelList: Array<EquipmentModel>,
  setStateModelList: React.Dispatch<React.SetStateAction<Array<EquipmentModel>>>,
  sectorListPickerData: Array<any>,
  stateCategoryList: Array<ModelCategoryResponse>,
  setStateCategoryList: React.Dispatch<React.SetStateAction<Array<ModelCategoryResponse>>>,
  stateBusinessTypeList: Array<ModelBusinessTypeResponse>,
  setStateBusinessTypeList: React.Dispatch<React.SetStateAction<Array<ModelBusinessTypeResponse>>>,
  getModelList: (consumerUnitId: number) => void,
  getCategoryList: () => void,
  getBusinessTypeList: () => void,
  postModelContext: (model: any) => Promise<boolean>,
  postCategoryContext: (category: ModelCategory) => Promise<boolean>,
  postBusinessTypeContext: (businessType: ModelBusinessType) => Promise<boolean>,
  putModelContext: (model: any, id: string) => Promise<boolean>,
  putCategoryContext: (category: ModelCategory, id: string) => Promise<boolean>,
  putBusinessTypeContext: (businessType: ModelBusinessType, id: string) => Promise<boolean>,
  deleteModel: (id: number | string) => Promise<boolean>,
  deleteBusinessType: (id: number | string) => Promise<boolean>,
  deleteCategory: (id: number | string) => Promise<boolean>,
}

type EquipmentContextTypes = {
  children: ReactNode
}

export const ModelContext = createContext({} as PropsModelContext)

function ModelContextProvider(props: EquipmentContextTypes) {
  const [stateModelList, setStateModelList] = useState<Array<EquipmentModel>>([{}] as Array<EquipmentModel>);
  const [stateCategoryList, setStateCategoryList] = useState<Array<ModelCategoryResponse>>([{}] as Array<ModelCategoryResponse>);
  const [stateBusinessTypeList, setStateBusinessTypeList] = useState<Array<ModelBusinessTypeResponse>>([{}] as Array<ModelBusinessTypeResponse>);
  const [sectorListPickerData, setSectorListPickerData] = useState<Array<any>>([]);

  const { setSnackBarTextResponse, selectConsumerUnitId } = useStateContext();

  const getModelList = async (consumerUnitId: number) => {
    setStateModelList([]);

    const hasConsumerUnitId = consumerUnitId != undefined && consumerUnitId != 0 && consumerUnitId != null;

    if (hasConsumerUnitId) {
      const { data, status } = await getAllModelsByConsumerUnit(consumerUnitId!)

      if (status === 200) {
        setStateModelList(data)
        setSectorListPickerData(mountSectorPickerData(data))
      }

      if (status === 204) {
        setStateModelList([])
        setSectorListPickerData([])
      }
    }
  };

  const getCategoryList = async () => {
    setStateCategoryList([])
    const { data, status } = await getAllCategory()
    if (status === 200) {
      setStateCategoryList(data)
    }
  };

  const getBusinessTypeList = async () => {
    setStateBusinessTypeList([])
    const { data, status } = await getAllBusinessType()
    if (status === 200) {
      setStateBusinessTypeList(data)
    }
  };

  const postModelContext = async (model: any) => {
    let hasFetch = false
    try {
      const { status } = await postModel(model)
      hasFetch = status === 200

      if (hasFetch) {
        getModelList(selectConsumerUnitId);
        setSnackBarTextResponse("Setor cadastrado com sucesso!")
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetch
  };

  const postCategoryContext = async (category: ModelCategory) => {
    let hasFetch = false

    try {
      const { status } = await postCategory(category)
      hasFetch = status === 200

      if (hasFetch) {
        getCategoryList()
        setSnackBarTextResponse("Categoria cadastrada com sucesso!")
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasFetch
  };

  const postBusinessTypeContext = async (businessType: ModelBusinessType) => {
    let hasFetch = false

    try {
      const { status } = await postBusinessType(businessType)
      hasFetch = status === 200

      if (hasFetch) {
        getBusinessTypeList()
        setSnackBarTextResponse("Categoria cadastrada com sucesso!")
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasFetch

  };

  const putModelContext = async (model: EquipmentModel, id: string) => {
    let hasFetch = false
    try {
      const { status } = await putModel(model, id)
      hasFetch = status === 200

      if (hasFetch) {
        getModelList(selectConsumerUnitId);
        setSnackBarTextResponse("Setor editado com sucesso!")
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetch
  };

  const putCategoryContext = async (category: ModelCategory, id: string) => {
    let hasFetch = false
    try {
      const { status } = await putCategory(category, id)
      hasFetch = status === 200

      if (hasFetch) {
        getCategoryList()
        setSnackBarTextResponse("Categoria editada com sucesso!")
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetch
  };

  const putBusinessTypeContext = async (businessType: ModelBusinessType, id: string) => {
    let hasFetch = false
    try {
      const { status } = await putBusinessType(businessType, id)
      hasFetch = status === 200

      if (hasFetch) {
        getBusinessTypeList()
        setSnackBarTextResponse("Tipo de negócio editada com sucesso!")
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetch
  };

  const deleteModel = async (id: number | string) => {
    let hasFetch = false
    try {
      const { status } = await delModel(id)
      hasFetch = status === 200
      if (hasFetch) {
        getModelList(selectConsumerUnitId);
        setSnackBarTextResponse('Setor deletado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetch
  };

  const deleteBusinessType = async (id: number | string) => {
    let hasFetch = false
    try {
      const { status } = await delBusinessType(id)
      hasFetch = status === 200
      if (hasFetch) {
        getBusinessTypeList()
        setSnackBarTextResponse('Tipo de negócio deletado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetch
  };

  const deleteCategory = async (id: number | string) => {
    let hasFetch = false
    try {
      const { status } = await delCategory(id)
      hasFetch = status === 200
      if (hasFetch) {
        getCategoryList()
        setSnackBarTextResponse('Categoria deletada com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetch
  };

  const mountSectorPickerData = (sectorList: Array<any>) => {
    const dataWithAllOption = [...sectorList, { id: -1, name: "Todos" }]

    return dataWithAllOption.map((sector) => ({
      value: sector.id,
      label: sector.name
    }));
  };

  const objProvider = {
    stateModelList,
    sectorListPickerData,
    setStateModelList,
    stateCategoryList,
    setStateCategoryList,
    stateBusinessTypeList,
    setStateBusinessTypeList,
    getModelList,
    getCategoryList,
    getBusinessTypeList,
    postModelContext,
    postCategoryContext,
    postBusinessTypeContext,
    putModelContext,
    putCategoryContext,
    putBusinessTypeContext,
    deleteModel,
    deleteBusinessType,
    deleteCategory
  }

  return (
    <ModelContext.Provider
      value={objProvider}
    >
      {props.children}
    </ModelContext.Provider>
  )
}

function useModelContext() {
  const context = useContext(ModelContext)
  return context
}

export {
  ModelContextProvider,
  useModelContext
}
