import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";

import { ModalEditModel } from "../../../Modal/EditModel";
import { useModalContext } from "../../../../providers/modalContext";

import styles from "./styles.module.scss";
import { usePagination } from "../../../../util/hooks/pagination";
import { useStateContext } from "../../../../providers/stateContext";
import { DeleteItemModal } from "../../../Modal/DeleteItem";
import { useModelContext } from "../../../../providers/modelContext";
import { EquipmentModel } from "../../../../util/types";

export function Model() {
  const [modelId, setModelId] = useState<number>(0);
  const [modelName, setModelName] = useState<string>("");
  const [modelEdit, setModelEdit] = useState();
  const { setModalEditModel, setModalDelete } = useModalContext();
  const { stateModelList, setStateModelList, deleteModel, getModelList } =
    useModelContext();
  const { searchItem, selectConsumerUnitId } = useStateContext();

  const PER_PAGE = 1000000;
  const count = Math.ceil(stateModelList.length / PER_PAGE);

  const _DATA = usePagination(stateModelList, PER_PAGE);

  useEffect(() => {
    if (searchItem !== "") {
      if (stateModelList?.length > 0) {
        const filter = stateModelList?.filter(
          (item: EquipmentModel) =>
            item.brand
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase()) ||
            item.name
              .toLocaleLowerCase()
              .includes(searchItem.toLocaleLowerCase())
          // item.equipmentModelBusiness.name
          //   .toLocaleLowerCase()
          //   .includes(searchItem.toLocaleLowerCase()) ||
          // item.equipmentModelCategory.name
          //   .toLocaleLowerCase()
          //   .includes(searchItem.toLocaleLowerCase())
        );
        setStateModelList(filter);
      }
    } else {
      getModelList(selectConsumerUnitId);
    }
  }, [searchItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: any, p: any) => {
    _DATA.jump(p);
  };

  const handleDeleteItem = (id: number, name: string) => {
    setModelId(id);
    setModelName(name);
    setModalDelete(true);
  };

  const handleEditEquipment = (modEdit: any) => {
    setModelEdit(modEdit);
    setModalEditModel(true);
  };

  return (
    <main className={styles.mainContainer}>
      <section className={styles.section}>
        <div className={styles.itemContainer}>
          <div className={styles.titletable}>
            <div className={styles.contentHeader}>
              <h5>Nome</h5>
            </div>
            <div className={styles.contentHeader}>
              <h5 className={styles.marca}>Metragem (m²)</h5>
            </div>
            <div className={styles.contentHeader}>
              <h5 className={styles.tipoEquip}>Categoria do Setor</h5>
            </div>
            <div className={styles.contentHeader}>
              <h5 className={styles.tipoNeg}>Tipo de Negócio</h5>
            </div>
            <div />
          </div>
          {stateModelList &&
            _DATA.currentData().map((state: any, index: any) => {
              return (
                <>
                  <div key={index} className={styles.mainContent}>
                    <div className={styles.contentBox}>
                      <div className={styles.contentText}>
                        <h4>{state.name}</h4>
                      </div>
                    </div>
                    <div className={styles.contentBox2}>
                      <div className={styles.contentText}>
                        <h4 className={styles.marca} >{state.brand}</h4>
                      </div>
                    </div>
                    <div className={styles.contentBox2}>
                      <div className={styles.contentText}>
                        <h4 className={styles.tipoEquip}>{state.equipmentModelCategory?.name}</h4>
                      </div>
                    </div>
                    <div className={styles.contentBox2}>
                      <div className={styles.contentText}>
                        <h4 className={styles.tipoNeg}>{state.equipmentModelBusiness?.name}</h4>
                      </div>
                    </div>
                    <div className={styles.contentBox3}>
                      <div className={styles.contentItem}>
                        <button onClick={() => handleEditEquipment(state)}>
                          <EditIcon />
                        </button>
                        <button
                          onClick={() => handleDeleteItem(state.id, state.name)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={styles.dividerContainer}>
                    <div className={styles.dividerHorizontal} />
                  </div>
                </>
              );
            })}
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: "1.25rem !important",
            ul: {
              display: "flex",
              flexDirection: "row",
            },
          }}
        >
          {/*<Pagination
              count={count}
              size="large"
              //page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
        />*/}
        </Box>
      </section>
      {modelEdit ? <ModalEditModel modelEdit={modelEdit} /> : <div />}
      <DeleteItemModal
        id={modelId}
        name={modelName}
        functionDelete={() => deleteModel(modelId)}
      />
    </main>
  );
}
