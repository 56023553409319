import { useEffect, useState } from 'react';

const zoomClasses: { [key: number]: string } = {
  110: 'zoom-110',
  125: 'zoom-125',
  150: 'zoom-150',
  175: 'zoom-175',
  200: 'zoom-200',
  250: 'zoom-250',
  300: 'zoom-300',
  400: 'zoom-400',
  500: 'zoom-500',
};

export function useZoom() {
  const [zoomClass, setZoomClass] = useState('');
  const [zoomLevel, setZoomLevel] = useState(100);
  
  useEffect(() => {
    const handleResize = () => {
      const zoomLevel = Math.round((window.devicePixelRatio || 1) * 100);
      setZoomClass(zoomClasses[zoomLevel] || "");
      setZoomLevel(zoomLevel);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { zoomLevel, zoomClass };
}