// Função para remover caracteres não numéricos
export const removeNonNumeric = (value: string): string => {
  return value.replace(/\D/g, ""); // Remove todos os caracteres que não são dígitos
};

// Função de formatação de número de telefone
export const formatPhoneNumber = (phone: string): string => {
  if (!phone) return phone;
  const formattedPhone = removeNonNumeric(phone); // Remover caracteres não numéricos

  // Validar tamanho entre 10 e 20 caracteres
  if (formattedPhone.length < 10 || formattedPhone.length > 20) {
    return phone; // Retorna o original se o tamanho for inválido
  }

  // Formatar números com 10 ou 11 caracteres (exemplo padrão brasileiro)
  if (formattedPhone.length === 10) {
    return `(${formattedPhone.slice(0, 2)}) ${formattedPhone.slice(2, 6)}-${formattedPhone.slice(6)}`;
  } else if (formattedPhone.length === 11) {
    return `(${formattedPhone.slice(0, 2)}) ${formattedPhone.slice(2, 7)}-${formattedPhone.slice(7)}`;
  }

  return phone; // Para outros casos, retorna o original
};

// Função de formatação para CPF ou CNPJ
export const formatDocument = (document: string): string => {
  if (!document) return document;
  const cleanDoc = removeNonNumeric(document); // Remover caracteres não numéricos
  if (cleanDoc.length === 11) {
    // Formatar como CPF: xxx.xxx.xxx-xx
    return `${cleanDoc.slice(0, 3)}.${cleanDoc.slice(3, 6)}.${cleanDoc.slice(6, 9)}-${cleanDoc.slice(9, 11)}`;
  } else if (cleanDoc.length === 14) {
    // Formatar como CNPJ: xx.xxx.xxx/xxxx-xx
    return `${cleanDoc.slice(0, 2)}.${cleanDoc.slice(2, 5)}.${cleanDoc.slice(5, 8)}/${cleanDoc.slice(8, 12)}-${cleanDoc.slice(12, 14)}`;
  }
  return document; // Retorna o original se não for um CPF ou CNPJ válido
};
