import React, { useEffect, useState } from "react";
import ChartLegend from "./ChartLegend";

import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import useChartConfig from "./ChartConfig";
import useFormatDate from "../../../util/hooks/useFormartDate";
import styles from "./styles.module.scss";
import { CircularProgress } from "@mui/material";
import { useReportContext } from "../../../providers/reportContext";
import { chartColors } from "../../../util/chartsColors";

export default function AreaChartRecharts({ data, powerDistributor, isWeekend }) {
  const { tickFontSize, containerWidth, containerHeight, margins, tspanFontSize, xOffset, dy, x1, strokeWidth, y2 } = useChartConfig();
  const [error, setError] = useState(false);
  const formatDate = useFormatDate();
  const { loadingChart } = useReportContext();
  const [areaChartData, setAreaChartData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [hiddenLegends, setHiddenLegends] = useState([]);

  const handleLegendClick = (clickedLegend) => {


    setHiddenLegends((prev) => {

      if (prev.length === 0) {
        const updatedLegends = filteredDataKeys.filter((key) => key !== clickedLegend);

        return updatedLegends;
      }

      if (prev.includes(clickedLegend)) {
        const updatedLegends = prev.filter((legend) => legend !== clickedLegend);
        return updatedLegends;
      }

      if (prev.length === filteredDataKeys.length - 1) {

        return [];
      }
      const updatedLegends = [...prev, clickedLegend];
      return updatedLegends;
    });
  };

  const filteredDataKeys = Object.keys(areaChartData[0] || {}).filter(
    (key) => key !== "timestamp"
  );

  const sortedKeys = [...filteredDataKeys].sort((a, b) => a.localeCompare(b));

  const [hoveredKey, setHoveredKey] = useState(null);

  const handleMouseOver = (key) => {
    setHoveredKey(key);
  };

  const handleMouseOut = () => {
    setHoveredKey(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      const visiblePayload = payload.filter(
        (equipment) => equipment.name === hoveredKey
      );

      if (visiblePayload.length > 0) {
        const { value, payload: dataPayload } = visiblePayload[0];
        const timestamp = dataPayload ? dataPayload.timestamp : "Hora não disponível";

        return (
          <div
            style={{
              backgroundColor: '#E0F7FA',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
              padding: '10px',
              borderRadius: '5px',
              fontSize: '0.8125rem',
              color: '#808080',
              marginBottom: "40px",
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <span>
              {value !== undefined
                ? `${value.toFixed(2).replace('.', ',')}kW`
                : "Dados indisponíveis"}
            </span>
            <span>{timestamp}</span>
          </div>
        );
      }
    }

    return null;
  };

  const generate30MinuteTimeSeries = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      const hour = String(i).padStart(2, '0');
      times.push(`${hour}:00`);
      times.push(`${hour}:30`);
    }
    return times;
  };

  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  const getIntervalIndices = (startTime, endTime, structuredData) => {
    if (!structuredData || structuredData.length === 0) return { x1: 0, x2: 0 };

    const x1Index = structuredData.findIndex(
      (d) => formatTime(d.timestamp) === formatTime(startTime)
    );
    const x2Index = structuredData.findIndex(
      (d) => formatTime(d.timestamp) === formatTime(endTime)
    );

    const isValidRange = x1Index !== -1 && x2Index !== -1;
    return {
      x1: isValidRange ? x1Index : 0,
      x2: isValidRange ? x2Index : structuredData.length - 1,
    };
  };

  const { x1: x1Intermediario1, x2: x2Intermediario2 } = getIntervalIndices(
    powerDistributor?.inicioIntermediario1,
    powerDistributor?.fimIntermediario2,
    areaChartData
  );

  const { x1: x1Ponta, x2: x2Ponta2 } = getIntervalIndices(
    powerDistributor?.inicioPonta,
    powerDistributor?.fimPonta,
    areaChartData
  );

  const maxConsumption = Math.max(
    ...areaChartData.map((point) => {
      const filteredPoint = Object.entries(point).filter(
        ([key]) => key !== "timestamp" && !hiddenLegends.includes(key)
      );

      return filteredPoint.reduce((sum, [, value]) => {
        return sum + (typeof value === 'number' && value > 0 ? value : 0);
      }, 0);
    })
  ) * 1.3;

  const dataWithFilteredAreaIntermediario = areaChartData.map((point, index) => {
    if (index >= x1Intermediario1 && index <= x2Intermediario2) {
      return { ...point, Intermediário: maxConsumption };
    } else {
      return { ...point, Intermediário: null };
    }
  });

  const dataWithFilteredAreaPonta = areaChartData.map((point, index) => {
    if (index >= x1Ponta && index <= x2Ponta2) {
      return { ...point, Ponta: maxConsumption };
    } else {
      return { ...point, Ponta: null };
    }
  });

  const CustomTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value === 0 ? (
          <>
            <line x1={x1} x2={30} y1={0} y2={0} stroke="#BDBDBD" strokeWidth={strokeWidth} />
            <text
              x={xOffset}
              y={5}
              dy={0}
              textAnchor="end"
              fill="#BDBDBD"
              fontSize={tickFontSize}
            >
              {payload.value}
              <tspan fontSize={tspanFontSize}>kW</tspan>
            </text>
          </>
        ) : (
          <text
            x={xOffset}
            y={0}
            dy={6}
            textAnchor="end"
            fill="#BDBDBD"
            fontSize={tickFontSize}
            strokeWidth={1.5}
          >
            {Math.round(payload.value)}
            <tspan fontSize={tspanFontSize}>kW</tspan>
          </text>
        )}
      </g>
    );
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    const isFullHour = payload.value.endsWith(":00");
    const isHalfHour = payload.value.endsWith(":30");

    return (
      <g transform={`translate(${x},${y})`}>
        {isFullHour && (
          <>
            <line x1={0} x2={0} y1={-2} y2={y2} stroke="#BDBDBD" strokeWidth={strokeWidth} />
            <text
              x={0}
              y={10}
              dy={dy}
              textAnchor="middle"
              fill="#BDBDBD"
              fontSize={tickFontSize}
            >
              {`${payload.value.split(':')[0]}h`}
            </text>
          </>
        )}


        {isHalfHour && (
          <line x1={0} x2={0} y1={-2} y2={y2 / 2} stroke="#BDBDBD" strokeWidth={strokeWidth / 2} />
        )}
      </g>
    );
  };

  useEffect(() => {
    if (data && data.equipmentConsumptions && Array.isArray(data.equipmentConsumptions)) {
      const allValues = [];
      const timestampsSet = new Set();

      data.equipmentConsumptions.forEach((item) => {
        item.values.forEach((v) => {
          if (v.consumption > 0) {
            const formattedTimestamp = formatDate.formatUTC24(v.timestamp);
            timestampsSet.add(formattedTimestamp);
            allValues.push({
              timestamp: formattedTimestamp,
              name: item.name,
              consumption: v.consumption,
            });
          }
        });
      });

      const timeSeries = generate30MinuteTimeSeries();
      const equipmentNames = [...new Set(allValues.map((v) => v.name))];

      const structuredData = timeSeries.map((timestamp) => {
        const consumptionData = allValues.filter((v) => v.timestamp === timestamp);
        const defaultValues = equipmentNames.reduce((acc, name) => {
          acc[name] = 0;
          return acc;
        }, {});

        const sortedConsumptionData = consumptionData.sort((a, b) => a.name.localeCompare(b.name));

        return {
          timestamp,
          ...defaultValues,
          ...sortedConsumptionData.reduce((acc, curr) => {
            acc[curr.name] = curr.consumption;
            return acc;
          }, {}),
        };
      });

      if (structuredData.length === 0) {
        setError(true);
      } else {
        setError(false);
        setAreaChartData(structuredData);
      }
    } else {
      setError(true);
    }
  }, [data]);

  if (loadingChart) {
    return (
      <div className={styles.blurLoading}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h2>Buscando Informações</h2>
          <CircularProgress className={styles.circle} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>

      </div>
    );
  }

  if (data?.totalConsumption === 0) {
    return (
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>

      </div>
    );
  }



  return (
    <div className={styles.boxChart}>
      <ResponsiveContainer width={containerWidth} height={containerHeight}>
        <ComposedChart data={dataWithFilteredAreaIntermediario} margin={margins} onMouseMove={(state) => setHoveredIndex(state.activeTooltipIndex)} >
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis
            dataKey="timestamp"
            tickFormatter={(tick) => {
              if (typeof tick === 'string' && tick.endsWith(":00")) {
                const [hour] = tick.split(':');
                return `${hour}h`;
              }
              return "";
            }}
            interval={2}
            tick={CustomXAxisTick}
            tickSize={0}
            stroke="#BDBDBD"
            strokeWidth={strokeWidth}
          />
          <YAxis
            domain={[0, maxConsumption]}
            tickFormatter={(tick) => `${Math.floor(tick)}kW`}
            tick={CustomTick}
            tickSize={0}
            stroke="#BDBDBD"
            strokeWidth={strokeWidth}
          />



          {sortedKeys.map((key, index) => (
            <Area
              key={index}
              type="monotone"
              dataKey={key}
              name={key}
              fillOpacity={1}
              stroke="white"
              fill={chartColors[index % chartColors.length]}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              stackId="stacked"
              activeDot={{ r: 0 }}
              hide={hiddenLegends.includes(key)}
              onMouseOver={() => handleMouseOver(key)}
              onMouseOut={handleMouseOut}
              animationDuration={300}
            />
          ))}


          <Legend
            content={
              <ChartLegend
                payload={filteredDataKeys.map((key, index) => ({
                  value: key,
                  color: chartColors[index % chartColors.length],
                }))}
                chartColors={chartColors}
                intermediarioColor="#F68D2B99"
                pontaColor="#E23D2899"
                onClick={handleLegendClick}
                hiddenLegends={hiddenLegends}

              />
            }
          />

          <Tooltip
            content={<CustomTooltip />}

            onMouseLeave={() => console.log('Mouse saiu da área')}
            onMouseMove={(e) => console.log('Mouse sobre', e)}
            cursor={{
              stroke: '#14394C',
              strokeWidth: 1,
              strokeDasharray: '3 3',
              opacity: '50%',
              z: 1000
            }}
          />



          {!isWeekend && (
            <>
              <Area
                type="monotone"
                dataKey="Intermediário"
                name="Horários de Ponta"
                fill="#F68D2B99"
                stroke="none"
                isAnimationActive={false}
                style={{ pointerEvents: 'none' }}
                activeDot={{ r: 0 }}
              />
              <Area
                type="monotone"
                dataKey="Ponta"
                name="Horário Intermediário"
                data={dataWithFilteredAreaPonta}
                fill="#E23D2899"
                stroke="none"
                isAnimationActive={false}
                style={{ pointerEvents: 'none' }}
                activeDot={{ r: 0 }}
              />
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div >
  );
}
