import React, { useEffect, useState } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import useFormatDate from '../../../../../util/hooks/useFormartDate';
import styles from './styles.module.scss';
import { useReportContext } from '../../../../../providers/reportContext';
import { CircularProgress } from '@mui/material';
import useChartConfig from "./ChartConfigComp";

type Value = {
  timestamp: string;
  consumption: number;
  price: number;
};

type EquipmentDaysReportData = {
  consumption: number;
  values: Value[];
};

interface CustomBarChartProps {
  data: EquipmentDaysReportData;
  typeFormatDate: string;
  chartSizeType: string;
}

export default function BarChartForDays({ data, typeFormatDate }: CustomBarChartProps) {
  const { tickFontSize, containerWidth, containerHeight, margins, tspanFontSize, xOffset, dy, x1, strokeWidth, y2 } = useChartConfig();
  const formartDate = useFormatDate();
  const { loadingChartBar } = useReportContext();

  // Estado para verificar se a tela é menor que 480px
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);


  const CustomTooltip = ({ active, payload }: { active?: boolean; payload?: any[] }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#E0F7FA',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
            padding: '10px',
            borderRadius: '5px',
            fontSize: '0.8125rem',
            color: '#808080',
            marginBottom: '40px',
          }}
        >
          {payload.map((equipment, index) => {
            const date = new Date(equipment.payload.timestamp);
            const formattedDate = date.toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: 'short',
            });
            const formattedValue = equipment.value.toFixed(4).replace('.', ',');

            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <p>
                  {formattedValue}kW <span>{formattedDate}</span>
                </p>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formattedData = data?.values?.length
    ? data.values.map((d: any) => ({
      timestamp:
        typeFormatDate === 'hours'
          ? formartDate.formatUTC24(d.timestamp)
          : formartDate.formatUTCLestWeek(d.timestamp),
      consumption: d.consumption || 0,
    }))
    : [];

  const maxConsumption = Math.max(...formattedData.map((d) => d.consumption), 0);
  const formattedMaxConsumption = maxConsumption.toFixed(2);

  const CustomTick = ({ x, y, payload }: { x: number; y: number; payload: { value: string } }) => {
    const value = Number(payload.value);
    const formattedValue = value.toFixed(2);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={xOffset}
          y={0}
          dy={6}
          textAnchor="end"
          fill="#BDBDBD"
          fontSize={tickFontSize}
          strokeWidth={strokeWidth}
        >
          {formattedValue}
          <tspan fontSize={tspanFontSize}>kW</tspan>
        </text>
      </g>
    );
  };

  const CustomXAxisTick = ({
    x,
    y,
    payload,
    isSmallScreen,
  }: {
    x: number;
    y: number;
    payload: { value: string };
    isSmallScreen: boolean;
  }) => {
    const valueStr = String(payload.value);

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: isSmallScreen ? '2-digit' : 'short',
      });
    };

    const formattedDate = formatDate(valueStr);

    return (
      <g transform={`translate(${x},${y})`}>
        <line x1={0} x2={0} y1={-2} y2={y2} stroke="#BDBDBD" strokeWidth={strokeWidth} />
        <text
          x={0}
          y={10}
          dy={dy}
          textAnchor="middle"
          fill="#BDBDBD"
          fontSize={tickFontSize}
        >
          {formattedDate}
        </text>
      </g>
    );
  };

  if (loadingChartBar) {
    return (
      <div className={styles.blurLoading}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h2>Buscando informações</h2>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (!data?.consumption) {
    return (
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>
      </div>
    );
  }

  if (data?.consumption === 0) {
    return (
      <div className={styles.NotConsumer}>
        <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>
      </div>
    );
  }

  return (
    <div className={styles.boxChart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={formattedData} layout="horizontal" margin={margins}>
          <CartesianGrid horizontal={true} vertical={false} strokeDasharray="0" stroke="#EFEFEF" />
          <XAxis
            interval={3}
            dataKey="timestamp"
            tick={(props) => <CustomXAxisTick {...props} isSmallScreen={isSmallScreen} />}
            angle={45}
            textAnchor="end"
            tickSize={0}
            stroke="#BDBDBD"
            strokeWidth={strokeWidth}
          />
          <YAxis
            tickFormatter={(tick) => `${tick} kW`}
            tick={CustomTick}
            tickSize={0}
            axisLine={false}
            domain={[0, Number(formattedMaxConsumption) * 1.1]}
          />
          <Tooltip content={<CustomTooltip payload={formattedData} active={true} />} />
          <Bar dataKey="consumption" fill="#676ACA" radius={[30, 30, 0, 0]} barSize={40} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
