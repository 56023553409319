import { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from "react-router-dom";
import Select from 'react-select';
import CustomSelect from './../CustomSelect';

import { Box, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { styled } from "@mui/material/styles";

import { useAuth } from '../../providers/authContext';
import { useStateContext } from '../../providers/stateContext';
import { useConsumerUnitContext } from '../../providers/consumerUnitContext';
import { useModelContext } from '../../providers/modelContext';
import { useMeterContext } from '../../providers/meterContext';

import styles from './styles.module.scss';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type selectType = {
  value: string | number;
  label: string;
}

const drawerWidth = 250;

export default function Header() {
  const { dataUser } = useAuth()
  const {
    setSelectConsumerUnitId,
    setShowSelectAppBar,
    setSectorId,
    selectConsumerUnitId
  } = useStateContext();
  const { pickerData, consumerUnitList } = useConsumerUnitContext();
  const { sectorListPickerData, getModelList } = useModelContext();
  let [searchParams, setSearchParams] = useSearchParams();
  const paramsConsumerUnitId = searchParams.get('consumerUnitId')
  const [defaultValueSelect, setDefaultValueSelect] = useState<selectType>()
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [selectedSector, setSelectedSector] = useState(null);
  const isInDashScreen = location.pathname === '/dashboard';
  const { getMeterListByConsumerUnit } = useMeterContext();

  const consumerUnitSelected = consumerUnitList?.find((consumerUnit) => consumerUnit?.id === selectConsumerUnitId);

  useEffect(() => {
    switch (location.pathname) {
      case '/dashboard':
        setShowSelectAppBar(true)
        break;
      case '/dashboard/equipmentManagement':
        setShowSelectAppBar(true)
        break;
      case '/dashboard/modelManagement':
        setShowSelectAppBar(true)
        break;
      default:
        setShowSelectAppBar(false)
    }
  }, [location]);

  useEffect(() => {
    setInitialSelectValue()
    if (pickerData.length > 0) {
      pickerData.forEach((item: any) => {
        if (paramsConsumerUnitId !== null) {
          let parsedParamsConsumerUnitId = parseInt(paramsConsumerUnitId)
          if (item.value === parsedParamsConsumerUnitId) {
            setDefaultValueSelect({
              value: item.value,
              label: item.label,
            })
          }
        }
      })

      if (defaultValueSelect === undefined) {
        if (paramsConsumerUnitId !== null) {
          pickerData.forEach((item: any) => {
            let parsedParamsConsumerUnitId = parseInt(paramsConsumerUnitId)
            if (item.value === parsedParamsConsumerUnitId) {
              setDefaultValueSelect({
                value: item.value,
                label: item.label,
              })
              setSelectConsumerUnitId(item.value)
            }
          })
        } else {
          setDefaultValueSelect({
            value: pickerData[0].value,
            label: pickerData[0].label,
          })
          setSelectConsumerUnitId(pickerData[0].value)
        }
      }
    }
  }, [pickerData, paramsConsumerUnitId]);

  useEffect(() => {
    if (paramsConsumerUnitId || selectConsumerUnitId) {
      getModelList(parseInt(paramsConsumerUnitId ?? String(selectConsumerUnitId)))
      getMeterListByConsumerUnit()
    }
  }, [paramsConsumerUnitId, selectConsumerUnitId]);

  useEffect(() => {
    if (pickerData.length > 0 && Object.keys(dataUser).length !== 0) {
      setLoading(false)
    }
  }, [pickerData, dataUser]);

  useEffect(() => {
    if (sectorListPickerData.length > 0) {
      setSelectedSector(sectorListPickerData[0])
      setSectorId(sectorListPickerData[0]?.value)
    } else {
      setSelectedSector(null)
      setSectorId(0)
    }
  }, [sectorListPickerData]);

  const setInitialSelectValue = () => {
    if (paramsConsumerUnitId) {
      setSelectConsumerUnitId(parseInt(paramsConsumerUnitId))
    }
  };

  const handleChangeSelect = (event: any) => {
    searchParams.set('consumerUnitId', event.value);
    setSearchParams(searchParams);

    setSectorId(0)
    setSelectConsumerUnitId(event.value)
  };

  const handleChangeSectorSelect = (event: any) => {
    setSelectedSector(event);
    setSectorId(event.value)
  };

  if (loading) {
    return null;
  }

  return (
    <AppBar
      sx={{
        width: '93.5vw',
        transition: 'width 0.3s ease, margin-left 0.3s ease',
        backgroundColor: '#F5F6F8',
        boxShadow: 'none',

        '@media (max-width: 900px)': {
          // height: '10%',
          width: '86.5vw',
        },
      }}
    >
      <Toolbar
        sx={{
          display: "flex-start",
          alignItems: "center",
          justifyContent: "space-between",
          width: '100%',
          backgroundColor: '#F5F6F8',
          marginTop: '2vh',

          '@media (max-width: 1024px)': {
            marginTop: '1vh',

          },
          '@media (max-width: 1680px)': {
            marginTop: '1vh',
          },
        }}
      >
        <Box className={styles.select}>
          <div className={styles.selectMargin}>
            <Select
              placeholder={"Unidade consumidora"}
              value={defaultValueSelect}
              onChange={handleChangeSelect}
              options={pickerData}
              styles={customStyles}
            />
          </div>
          {isInDashScreen && (
            <div>
              <Select
                value={selectedSector}
                onChange={handleChangeSectorSelect}
                options={sectorListPickerData}
                placeholder="Setor"
                styles={customStyles}
              />
            </div>
          )}
        </Box>

        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: "#FFF",
            borderRadius: '4.5625rem',
            padding: '0.625rem',
            width: { xs: '10rem', md: '17.625rem' },
            gap: '0.625rem',
            flexShrink: 0,
            height: '4rem',
            overflow: 'hidden',

            '@media (max-width: 900px)': {
              width: '14rem',
              height: '2.5rem',
            },
            '@media (max-width: 1680px)': {
              marginTop: '0.5rem',
              marginLeft: '2rem'
            },
            '@media (max-width: 1440px)': {
              width: '16rem',
              height: '3.5rem',
            },
            '@media (max-width: 1280px)': {
              width: '16rem',
              height: '3rem',
            },
          }}
        >
          <div className={styles.user}>
            <div className={styles.userInfo}>
              <h3>{dataUser.fullName}</h3>
              <h5>{dataUser.email}</h5>
            </div>
            <div className={styles.circleBlue} style={{ marginLeft: '10px' }}>
              <PersonSharpIcon className={styles.iconColorWhite} />
            </div>
          </div>
        </Box>

      </Toolbar>
    </AppBar >
  )
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }: any) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  borderBottom: 'none',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#44521B;' : '#44521B;',
    padding: "0.3125rem 0.625rem;",
    borderRadius: '0.5rem',
    margin: '5px 0',
    fontWeight: '300',
    border: '0.5px solid #C9DA9A',
    backgroundColor: state.isSelected
      ? '#EAF2F6'
      : 'transparent',
    '&:hover': {
      backgroundColor: '#97B73C',
      borderRadius: '8px',
    },
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'inline-flex',
    padding: '0rem 0rem 0rem 1rem',
    marginLeft: '1rem',
    height: '4rem',
    borderRadius: '4.5625rem',
    backgroundColor: '#FFF',
    zIndex: 10,
    border: 'none',
    boxShadow: 'none',




    '@media (max-width: 1440px)': {
      height: '3.5rem',
    },
    '@media (max-width: 1280px)': {
      height: '3rem',
    },

    '@media (max-width: 900px)': {
      height: '4rem',
      marginLeft: '0rem',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 20,
    border: '0.5px solid black',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: 25,
  }),
  singleValue: (provided: any, state: any) => {
    const color = '#14394C';
    const fontFamily = 'Inter, sans-serif';

    const fontStyle = 'normal';
    const fontWeight = 700;
    const lineHeight = 'normal';
    const alignSelf = 'stretch';

    // Defina o tamanho da fonte com base na largura da tela
    const fontSize = window.innerWidth <= 1280 ? '1.4rem' : '1.5rem';
    return { ...provided, fontFamily, color, fontSize, fontWeight, lineHeight, fontStyle, alignSelf };
  },

  placeholder: (provided: any, state: any) => {
    const fontFamily = 'Inter, sans-serif';
    const fontSize = window.innerWidth <= 1280 ? '1.2rem' : '1.4rem';
    const fontWeight = 300; // Pode ser ajustado conforme necessário


    return {
      ...provided,
      fontFamily,
      fontSize,
      fontWeight,

    };
  },
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3rem',
    height: '3rem',
    borderRadius: '1.5rem',
    backgroundColor: '#EAF2F6',
    padding: '0.75rem',
    cursor: 'pointer',
    gap: '0.625rem',
    transition: 'background-color 0.3s',
    marginRight: '10px',
    color: '#14394C',

    '@media (max-width: 1680px)': {
      width: '3rem',
      height: '3rem',
    },
    '@media (max-width: 1440px)': {
      width: '2.5rem',
      height: '2.5rem',
    },
    '@media (max-width: 1280px)': {
      width: '2rem',
      height: '2rem',
    },
    '@media (max-width: 900px)': {
      width: '2.5rem',
      height: '2.5rem',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
