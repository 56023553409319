import { ComposedChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, defs, LinearGradient, Stop, Legend } from 'recharts';
import Color from 'color';
import useFormatDate from "../../../util/hooks/useFormartDate";
import { ReactComponent as EquipamentIconOutline } from "../../../assets/equipamentIconOutline.svg";
import { useReportContext } from "../../../providers/reportContext";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import ChartLegend from "./ChartLegendComp";
import { chartColors } from "../../../util/chartsColors";
import styles from './styles.module.scss';
import useChartConfig from "../AreaChartComp/ChartConfigComp";


export default function AreaChartComp({ width, height, data, chartSizeType, loadingChart1 = false, loadingChart2 = false }) {
  const { tickFontSize, containerWidth, containerHeight, margins, tspanFontSize, xOffset, dy, x1, strokeWidth, y2 } = useChartConfig();
  const [error, setError] = useState(false);
  const formatDate = useFormatDate();
  const { loadingChart } = useReportContext();
  const [areaChartData, setAreaChartData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [isMobile, setIsMobile] = useState(false);

  const [hiddenLegends, setHiddenLegends] = useState([]);

  const handleLegendClick = (clickedLegend) => {
    setHiddenLegends((prev) => {
      if (prev.length === 0) {
        const updatedLegends = filteredDataKeys.filter((key) => key !== clickedLegend);

        return updatedLegends;
      }

      if (prev.includes(clickedLegend)) {
        const updatedLegends = prev.filter((legend) => legend !== clickedLegend);
        return updatedLegends;
      }

      if (prev.length === filteredDataKeys.length - 1) {
        return [];
      }

      const updatedLegends = [...prev, clickedLegend];
      return updatedLegends;
    });
  };

  const filteredDataKeys = Object.keys(areaChartData[0] || {}).filter(
    (key) => key !== "timestamp"
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const darkenColor = (color, amount = 0.05) => {
    return Color(color).darken(amount).hex();
  };

  const [hoveredKey, setHoveredKey] = useState(null);

  const handleMouseOver = (key) => {
    setHoveredKey(key);
  };

  const handleMouseOut = () => {
    setHoveredKey(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      const visiblePayload = payload.filter(
        (equipment) => equipment.name === hoveredKey
      );

      if (visiblePayload.length > 0) {
        const { value, payload: dataPayload } = visiblePayload[0];
        const timestamp = dataPayload ? dataPayload.timestamp : "Hora não disponível";

        return (
          <div
            style={{
              backgroundColor: '#E0F7FA',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
              padding: '10px',
              borderRadius: '5px',
              fontSize: '0.8125rem',
              color: '#808080',
              marginBottom: "40px",
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <span>
              {value !== undefined
                ? `${value.toFixed(2).replace('.', ',')}kW`
                : "Dados indisponíveis"}
            </span>
            <span>{timestamp}</span>
          </div>
        );
      }
    }

    return null;
  };

  const generate30MinuteTimeSeries = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      const hour = String(i).padStart(2, '0');
      times.push(`${hour}:00`);
      times.push(`${hour}:30`);
    }
    return times;
  };

  const CustomTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value === 0 ? (
          <>
            <line x1={x1} x2={30} y1={0} y2={0} stroke="#BDBDBD" strokeWidth={strokeWidth} />
            <text
              x={xOffset}
              y={5}
              dy={0}
              textAnchor="end"
              fill="#BDBDBD"
              fontSize={tickFontSize}
            >
              {payload.value}
              <tspan fontSize={tspanFontSize}>kW</tspan>
            </text>
          </>
        ) : (
          <text
            x={xOffset}
            y={0}
            dy={6}
            textAnchor="end"
            fill="#BDBDBD"
            fontSize={tickFontSize}
            strokeWidth={1.5}
          >
            {payload.value}
            <tspan fontSize={tspanFontSize}>kW</tspan>
          </text>
        )}
      </g>
    );
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    const isFullHour = payload.value.endsWith(":00");
    const isHalfHour = payload.value.endsWith(":30");

    return (
      <g transform={`translate(${x},${y})`}>
        {isFullHour && (
          <>
            <line x1={0} x2={0} y1={-2} y2={y2} stroke="#BDBDBD" strokeWidth={strokeWidth} />
            <text
              x={0}
              y={10}
              dy={dy}
              textAnchor="middle"
              fill="#BDBDBD"
              fontSize={tickFontSize}
            >
              {`${payload.value.split(':')[0]}h`}
            </text>
          </>
        )}

        {isHalfHour && (
          <line x1={0} x2={0} y1={-2} y2={y2 / 2} stroke="#BDBDBD" strokeWidth={strokeWidth / 2} />
        )}
      </g>
    );
  };

  useEffect(() => {
    if (data && data.equipmentConsumptions && Array.isArray(data.equipmentConsumptions)) {
      const allValues = [];
      const timestampsSet = new Set();

      data.equipmentConsumptions.forEach((item) => {
        item.values.forEach((v) => {
          if (v.consumption > 0) {
            const formattedTimestamp = formatDate.formatUTC24(v.timestamp);
            timestampsSet.add(formattedTimestamp);
            allValues.push({
              timestamp: formattedTimestamp,
              name: item.name,
              consumption: v.consumption,
            });
          }
        });
      });

      const timeSeries = generate30MinuteTimeSeries();
      const equipmentNames = [...new Set(allValues.map((v) => v.name))];

      const structuredData = timeSeries.map((timestamp) => {
        const consumptionData = allValues.filter((v) => v.timestamp === timestamp);
        const defaultValues = equipmentNames.reduce((acc, name) => {
          acc[name] = 0;
          return acc;
        }, {});

        return {
          timestamp,
          ...defaultValues,
          ...consumptionData.reduce((acc, curr) => {
            acc[curr.name] = curr.consumption;
            return acc;
          }, {}),
        };
      });

      if (structuredData.length === 0) {
        setError(true);
      } else {
        setError(false);
        setAreaChartData(structuredData);
      }
    } else {
      setError(true);
    }
  }, [data]);

  if (loadingChart || loadingChart1 || loadingChart2) {
    return (
      <div className={styles.blurLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Buscando informações</h2>
          <CircularProgress />
        </div>
      </div>
    );
  } else {
    if (Object.keys(data).length > 0 && data?.totalConsumption === 0) {
      return (
        <div className={styles.NotConsumer}>
          <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>
        </div>
      );
    }

    if (areaChartData && areaChartData.length > 0) {
      return (
        <div className={styles.boxChart}>
          <ResponsiveContainer width={containerWidth} height={containerHeight}>
            <ComposedChart data={areaChartData} margin={margins} onMouseMove={(state) => setHoveredIndex(state.activeTooltipIndex)} >
              <defs>
                {Object.keys(areaChartData[0] || {})
                  .filter((key) => key !== "timestamp")
                  .map((equipmentName, index) => (
                    <>
                      {/* Gradiente de preenchimento para a área */}
                      <linearGradient id={`colorGradient-${index}`} key={`fill-${index}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor={chartColors[index % chartColors.length]} stopOpacity={0.1} />
                        <stop offset="100%" stopColor={chartColors[index % chartColors.length]} stopOpacity={0} />
                      </linearGradient>

                      {/* Gradiente para o stroke da borda escurecido */}
                      <linearGradient id={`strokeGradient-${index}`} key={`stroke-${index}`} x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stopColor={darkenColor(chartColors[index % chartColors.length], 0.05)} />
                        <stop offset="100%" stopColor={darkenColor(chartColors[index % chartColors.length], 0)} />
                      </linearGradient>
                    </>
                  ))}
              </defs>

              <CartesianGrid horizontal={false} vertical={false} />
              <XAxis
                dataKey="timestamp"
                tickFormatter={(tick) => {
                  if (typeof tick === 'string' && tick.endsWith(":00")) {
                    const [hour] = tick.split(':');
                    return `${hour}h`;
                  }
                  return "";
                }}
                interval={7}
                tick={CustomXAxisTick}
                tickSize={0}
                stroke="#BDBDBD"
                strokeWidth={strokeWidth}
              />
              <YAxis
                tickFormatter={(tick) => `${Math.floor(tick)}kW`}
                tick={CustomTick}
                tickSize={0}
                stroke="#BDBDBD"
                strokeWidth={strokeWidth}
              />

              {filteredDataKeys.map((key, index) => (
                <Area
                  key={index}
                  type="linear"
                  dataKey={key}
                  name={key}
                  fillOpacity={1}
                  stroke={`url(#strokeGradient-${index})`}
                  strokeWidth={2}
                  fill={`url(#colorGradient-${index})`}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  opacity={1}
                  activeDot={{ r: 0 }}
                  hide={hiddenLegends.includes(key)}
                  onMouseOver={() => handleMouseOver(key)}
                  onMouseOut={handleMouseOut}
                  zIndex={10}


                />
              ))}
              <Tooltip
                content={<CustomTooltip />}
                cursor={{
                  stroke: '#14394C',
                  strokeWidth: 1,
                  strokeDasharray: '3 3',
                  opacity: '50%',
                  z: 1000
                }}
              />

              <Legend layout="vertical" align={isMobile ? 'center' : 'right'}
                content={
                  <ChartLegend
                    payload={filteredDataKeys.map((key, index) => ({
                      value: key,
                      color: chartColors[index % chartColors.length],
                    }))}
                    onClick={handleLegendClick}
                    hiddenLegends={hiddenLegends}
                    chartColors={chartColors}
                    intermediarioColor="#F68D2B99"
                    pontaColor="#E23D2899"


                  />
                }
              />

            </ComposedChart>
          </ResponsiveContainer>

        </div >

      );
    }
  }
  return (
    <div className={styles.NotConsumer}>
      <h2>Não encontramos dados de consumo para esse equipamento nessa data!</h2>
      <EquipamentIconOutline color="#0f85ca" />
    </div>
  );
}
