import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useConsumerUnitContext } from "../../providers/consumerUnitContext";
import { useReportContext } from "../../providers/reportContext";
import { useStateContext } from "../../providers/stateContext";
import { getLast24Hours } from "../../util/getLast24hours";
import styles from "./styles.module.scss";
import { CircularProgress } from "@mui/material";
import { ROLE } from "../../util/enums/Role";
import { PowerDistributors } from "../../util/types"; // Importa o tipo PowerDistributors
import PieChartCard from "./components/Charts/PieChartCard/PieChartCard";
import AreaChartCard from "./components/Charts/AreaChartCard/AreaChartCard";
import BarChartCard from "./components/Charts/BarChartCard/BarChartCard";
import { useSearchParams } from "react-router-dom";
import Comparison, { ComparisonChartType } from "../Comparison";
import { DateType } from "../../util/enums/dateType";

export default function Breakdown() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role") || "Unknown"; // Obtém o papel do usuário
  const {
    selectConsumerUnitId,
    isGetFetching,
    dateToCallApiForChartPie,
    dateToCallApiForChartArea,
    dateToCallApiForChartBar,
    sectorId,
    dateToCallApiForChartPieRange,
  } = useStateContext();
  const {
    consumerUnitList,
    getPowerDistributorsList,
    getPowerDistributorByConsumerUnit,
    powerDistributorsList,
  } = useConsumerUnitContext();
  const [selectedDistributor, setSelectedDistributor] =
    useState<PowerDistributors | null>(null); // Define o distribuidor selecionado

  const {
    getReportConsumerUnitForHours,
    reportConsumerUnitForHoursList,
    reportConsumerUnitForHoursPie,
    getReportConsumerUnitForInterval,
    reportConsumerUnitForIntervalBar,
    reportConsumerUnitForIntervalArea, // Dados do gráfico de área (API retorna em intervalos de 15 minutos)
    getReportConsumerUnitHoursBySectorForPizza,
    getReportConsumerUnitDaysBySectorForPizza,
  } = useReportContext();

  // Busca os distribuidores de energia quando o componente monta
  useEffect(() => {
    const fetchDistributors = async () => {
      await getPowerDistributorsList(); // Obtém a lista de distribuidores de energia
    };
    fetchDistributors();
  }, []);

  const [selectedConsumerUnit, setSelectedConsumerUnit] = useState({} as any);
  const [areaChartData, setAreaChartData] = useState([] as any); // Dados do gráfico de área
  const [pieChartData, setPieChartData] = useState([] as any); // Dados do gráfico de pizza
  const [barChartData, setBarChartData] = useState([] as any); // Dados do gráfico de barras

  const [searchParams, setSearchParams] = useSearchParams();

  const isComparisonEnabled = searchParams.get("comparison") === "true";
  const comparisonChartType: string | null = searchParams.get("chartType");
  const comparisonTitle = comparisonChartType === ComparisonChartType.AREA ? "Comparação entre períodos" : "Demanda consumida no período";

  const pieChartCardRef = useRef<{ dateType: string }>();

  const handleConsumerUnit = () => {
    navigate("/dashboard/consumerUnit");
  }; // Navega para a tela de unidades consumidoras
  const handleEquipmentRegister = () => {
    navigate("/dashboard/equipmentManagement");
  }; // Navega para a tela de gerenciamento de equipamentos

  // Obtém o distribuidor de energia relacionado à unidade consumidora selecionada
  useEffect(() => {
    const fetchDistributor = async () => {
      if (selectConsumerUnitId) {
        const distributor = await getPowerDistributorByConsumerUnit(
          selectConsumerUnitId
        );
        setSelectedDistributor(distributor); // Define o distribuidor selecionado no estado
      }
    };
    fetchDistributor();
  }, [selectConsumerUnitId]);

  // Busca os relatórios das últimas 24 horas para a unidade consumidora selecionada
  useEffect(() => {
    let hours24 = getLast24Hours();
    if (selectConsumerUnitId) {
      getReportConsumerUnitForHours(
        selectConsumerUnitId,
        hours24.start,
        hours24.end,
        "all"
      );
    }
  }, [selectConsumerUnitId]);

  const dateType = pieChartCardRef?.current?.dateType;
  useEffect(() => {
    if (
      dateType === DateType.DATE &&
      sectorId && sectorId != 0 &&
      selectConsumerUnitId &&
      dateToCallApiForChartPie.start &&
      dateToCallApiForChartPie.end
    ) {
      getReportConsumerUnitHoursBySectorForPizza(
        selectConsumerUnitId,
        dateToCallApiForChartPie.start,
        dateToCallApiForChartPie.end,
        sectorId
      );
    }
  }, [sectorId, dateToCallApiForChartPie]);

  useEffect(() => {
    if (
      dateType === DateType.PERIOD &&
      sectorId &&
      selectConsumerUnitId &&
      dateToCallApiForChartPieRange.start &&
      dateToCallApiForChartPieRange.end
    ) {
      getReportConsumerUnitDaysBySectorForPizza(
        selectConsumerUnitId,
        dateToCallApiForChartPieRange.start,
        dateToCallApiForChartPieRange.end,
        sectorId
      );
    }
  }, [dateToCallApiForChartPieRange, sectorId]);

  // Chama a API para o gráfico de área (intervalos de 30 minutos)
  useEffect(() => {
    if (Object.keys(dateToCallApiForChartArea).length > 0) {
      getReportConsumerUnitForInterval(
        selectConsumerUnitId,
        "1800",
        dateToCallApiForChartArea.start,
        dateToCallApiForChartArea.end,
        "area",
        sectorId
      );
    }
  }, [dateToCallApiForChartArea, selectConsumerUnitId, sectorId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Atualiza os dados do gráfico de área quando a resposta da API é recebida
  useEffect(() => {
    if (reportConsumerUnitForIntervalArea) {
      setAreaChartData(reportConsumerUnitForIntervalArea);
    }
  }, [reportConsumerUnitForIntervalArea]);

  // Chama a API para o gráfico de barra (intervalos de 15 minutos)
  useEffect(() => {
    if (Object.keys(dateToCallApiForChartBar).length > 0 && sectorId && sectorId != 0
    ) {
      getReportConsumerUnitForInterval(
        selectConsumerUnitId,
        "900",
        dateToCallApiForChartBar.start,
        dateToCallApiForChartBar.end,
        "bar",
        sectorId
      );
    }
  }, [dateToCallApiForChartBar, selectConsumerUnitId, sectorId]);

  // Atualiza os dados do gráfico de barra quando a resposta da API é recebida
  useEffect(() => {
    if (reportConsumerUnitForIntervalBar) {
      setBarChartData(reportConsumerUnitForIntervalBar);
    }
  }, [reportConsumerUnitForIntervalBar]);

  // Atualiza os dados do gráfico de pizza quando a resposta da API é recebida
  useEffect(() => {
    setPieChartData(reportConsumerUnitForHoursPie);
  }, [reportConsumerUnitForHoursPie]);

  // Atualiza os dados do distribuidor quando a unidade consumidora ou a lista de distribuidores muda
  useEffect(() => {
    if (selectConsumerUnitId) {
      const distributor =
        getPowerDistributorByConsumerUnit(selectConsumerUnitId);
      setSelectedDistributor(distributor);
    }
  }, [selectConsumerUnitId, consumerUnitList, powerDistributorsList]);

  // Atualiza a unidade consumidora selecionada quando a lista muda
  useEffect(() => {
    handleConsumerUnitChange();
  }, [consumerUnitList, selectConsumerUnitId]);

  // Função para atualizar a unidade consumidora selecionada
  const handleConsumerUnitChange = () => {
    consumerUnitList.forEach((consumerUnit) => {
      if (consumerUnit.id === selectConsumerUnitId) {
        setSelectedConsumerUnit(consumerUnit);
        getPowerDistributorByConsumerUnit(consumerUnit.id); // Obtém o distribuidor para a unidade consumidora
      }
    });
  };

  if (isComparisonEnabled && comparisonChartType) {
    return <Comparison
      title={comparisonTitle}
      comparisonChartType={comparisonChartType as ComparisonChartType}
    />;
  }
  // Renderiza a tela de carregamento ou os gráficos baseados nas permissões do usuário e no status de carregamento
  if (isGetFetching) {
    return (
      <div className={styles.loadingScreen}>
        <CircularProgress />
      </div>
    );
  } else if (role === ROLE.CLIENT || role === ROLE.ADMIN) {
    if (
      selectedConsumerUnit.equipments &&
      selectedConsumerUnit.equipments.length > 0 &&
      Object.keys(reportConsumerUnitForHoursList).length > 0
    ) {
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <main className={styles.box}>
              <div className={styles.firstContainerChart}>
                <PieChartCard dataChart={pieChartData} ref={pieChartCardRef} />
                <AreaChartCard
                  dataChart={areaChartData}
                  powerDistributor={selectedDistributor}
                />
              </div>
              <BarChartCard
                dataChart={barChartData} // Dados do gráfico de barras
              />
            </main>
          </div>
        </div>
      );
    } else if (
      selectedConsumerUnit.equipments &&
      selectedConsumerUnit.equipments.length === 0
    ) {
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <main className={styles.noUnitConsumer}>
              <span>
                Não foi encontrado nenhum equipamento cadastrado, deseja
                cadastrar um novo?
              </span>
              <button onClick={handleEquipmentRegister}>
                Cadastrar / Equipamento
              </button>
            </main>
          </div>
        </div>
      );
    } else if (consumerUnitList.length === 0) {
      return (
        <div className={styles.container}>
          <div className={styles.sideContainer} />
          <div className={styles.content}>
            <main className={styles.noUnitConsumer}>
              <span>
                Não foi encontrada nenhuma unidade consumidora cadastrada,
                deseja cadastrar uma nova?
              </span>
              <button onClick={handleConsumerUnit}>
                Cadastrar / Unidade Consumidora
              </button>
            </main>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={styles.loadingScreen}>
      <CircularProgress />
    </div>
  );
}
