import api from "./api";

export function getReportEquipmentForHoursAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/equipment/${id}/hours/${startTime}/${endTime}`);
}

export function getReportEquipmentForDaysAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/equipment/${id}/days/${startTime}/${endTime}`);
}

export function getReportSmartMeterForHoursAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/smartMeter/${id}/hours/${startTime}/${endTime}`);
}

export function getReportSmartMeterForDaysAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/smartMeter/${id}/days/${startTime}/${endTime}`);
}

export function getReportConsumerUnitForHoursAPI(id:number | string, startTime:string, endTime:string) {
  return api.get(`/api/report/consumerUnit/${id}/hours/${startTime}/${endTime}`);
}

export function getReportConsumerUnitDaysBySectorForPizzaAPI(id:number | string, startTime:string, endTime:string, equipmentModelId: number) {
  const sector = equipmentModelId != -1 ? `?equipmentModelId=${equipmentModelId}` : '';

  return api.get(`/api/report/consumerUnit/${id}/days/${startTime}/${endTime}${sector}`);
}

export function getReportConsumerUnitHoursBySectorForPizzaAPI(id:number | string, startTime:string, endTime:string, equipmentModelId?: number) {
  const sector = equipmentModelId != -1 ? `?equipmentModelId=${equipmentModelId}` : '';

  return api.get(`/api/report/consumerUnit/${id}/hours/${startTime}/${endTime}${sector}`);
}

export function getReportModbusDataAPI(macid: string, startTime: string, endTime: string, column: string) {  
  return api.get(`/api/report/moddata/${macid}/${startTime}/${endTime}/${column}`);
}

export function getReportKhompDataAPI(macid: string, startTime: string, endTime: string, type: string) {  
  return api.get(`/api/report/kdata/${macid}/${startTime}/${endTime}/${type}`);
}

export function getReportConsumerUnitForIntervalAPI(id:number | string, interval:string, startTime:string, endTime: string, sectorId?: number) {

  const sector = sectorId != -1 ? `?equipmentModelId=${sectorId}` : '';

  return api.get(`/api/report/consumerUnit/${id}/${interval}/${startTime}/${endTime}${sector}`);
}

export function getReportEquipmentForIntervalAPI(id:number | string, interval:string, startTime:string, endTime:string) {
  return api.get(`/api/report/equipment/${id}/${interval}/${startTime}/${endTime}`);
}