import { DateTime } from "luxon";

export class DateUtils {

    static isValidDate = (dateString: string): boolean => {
        // Expressão regular para verificar o formato YYYY-MM-DD
        const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
        const match = dateString.match(regex);

        return match !== null;
    };

    static formatDateToBrazilian = (dateInput: string | DateTime): string => {
        let date;

        if (!dateInput) {
            return "";
        }

        if (typeof dateInput === "string") {
            if (!this.isValidDate(dateInput)) {
                throw new Error("Data inválida. Use o formato YYYY-MM-DD.");
            }
            date = DateTime.fromISO(dateInput);
        } else {
            date = dateInput;
        }

        return date
            .setLocale("pt-BR")
            .toLocaleString({ day: "numeric", month: "long", year: "numeric" });
    };

    /**
     * Formata uma data para o formato ISO "yyyy-MM-dd".
     *
     * @param {string | Date} dateInput - A data a ser formatada, que pode estar no formato `string` (YYYY-MM-DD) ou `Date`.
     * @returns {string} A data formatada como "yyyy-MM-dd". Retorna uma string vazia se a data fornecida for inválida.
    */
    static formatDateToISO = (dateInput: string | Date | DateTime): string | undefined => {
        let date;

        if (!dateInput) {
            return undefined;
        }

        if (dateInput instanceof DateTime) {
            date = dateInput;
        } else if (typeof dateInput === "string") {
            date = DateTime.fromISO(dateInput);
        } else {
            date = DateTime.fromJSDate(dateInput);
        }

        if (!date.isValid) {
            console.error("Invalid DateTime: ", date.invalidExplanation || "Unknown error");
            return "";
        }

        return date.setZone("GMT").toFormat("yyyy-MM-dd");
    };


}