import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { InputLabel, Typography } from "@mui/material";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";

import * as Yup from "yup";
import { useFormik } from "formik";

import { PlaceData } from "../../../util/types";

import CustomButton from "../../Form/button";
import CustomInput from "../../CustomInput";
import { useStateContext } from "../../../providers/stateContext";
import { useConsumerUnitContext } from "../../../providers/consumerUnitContext";
import InputMask, { Props } from "react-input-mask";
import maskConfig from "../../../util/maks";
import onlyNumber from "../../../util/onlyNumber";
import { CustomSnackBar } from "../../Snackbar";
import CustomButtonLoading from "../../Form/buttonLoading";

import styles from './styles.module.scss';

type props = {
  messageTitle: string;
  massageContent: string;
};

export function ModalConsumerUnitRegistration() {
  const { modalConsumerUnitRegistration, setModalConsumerUnitRegistration } =
    useModalContext();
  const {
    consumerUnitPostApi,
    powerDistributorsList,
    getPowerDistributorsList,
  } = useConsumerUnitContext();
  const { setIsLoading, setStatusErrorOpenSnackBar, setOpenSnackBar } =
    useStateContext();
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);

  useEffect(() => {
    getPowerDistributorsList();
  }, []); // eslint-disable-line

  const validationSchema = Yup.object({
    placeName: Yup
      .string().min(3).required("esqueceu o Nome")
      .max(80, "Máximo de 80 caracteres"),
    placeEmail: Yup
      .string().email().required("esqueceu o seu e-mail")
      .max(80, "Máximo de 80 caracteres"),
    zipCode: Yup
      .string().required("esqueceu o seu CEP")
      .max(20, "Máximo de 20 caracteres"),
    district: Yup
      .string().required("esqueceu o seu Bairro")
      .max(30, "Máximo de 30 caracteres"),
    city: Yup
      .string().required("esqueceu o sua Cidade")
      .max(30, "Máximo de 30 caracteres"),
    state: Yup
      .string().required("esqueceu o seu Estado")
      .max(30, "Máximo de 30 caracteres"),
    street: Yup
      .string().required("esqueceu o seu Bairro")
      .max(150, "Máximo de 150 caracteres"),
    complement: Yup
      .string()
      .max(50, "Máximo de 50 caracteres"),
    number: Yup.number().required("esqueceu o Numero"),
    Phone: Yup.string().required("esqueceu o seu telefone"),
    distribuctors: Yup.string().required("esqueceu a empresa de Energia"),
    tariffType: Yup.string().required("esqueceu o tipo de tarifa"),
  });

  const formik = useFormik({
    initialValues: {
      placeName: "",
      Phone: "",
      placeEmail: "",
      zipCode: "",
      state: "",
      city: "",
      district: "",
      street: "",
      number: "",
      complement: "",
      distribuctors: "",
      tariffType: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      let MountedConsumerUnitPost = {} as PlaceData;
      const userId = await localStorage.getItem("userIdValue");
      if (userId) {
        MountedConsumerUnitPost = {
          placeName: values.placeName,
          placeEmail: values.placeEmail,
          Phone: onlyNumber(values.Phone),
          zipCode: onlyNumber(values.zipCode),
          city: values.city,
          state: values.state,
          district: values.district,
          street: values.street,
          number: values.number,
          complement: values.complement || null,
          userId: userId,
          powerDistributorId: values.distribuctors,
          tariffType: parseInt(values.tariffType),
        };
      }

      setIsLoading(true);

      const hasPostConsumerUnit = await consumerUnitPostApi(
        MountedConsumerUnitPost
      );
      if (hasPostConsumerUnit) {
        setIsLoading(false);
        setStatusErrorOpenSnackBar(false);
        setOpenSnackBar(true);
        setModalConsumerUnitRegistration(false);
        formik.resetForm();
      } else {
        setIsLoading(false);
        setStatusErrorOpenSnackBar(true);
        setOpenSnackBar(true);
      }
    },
  });

  const options = powerDistributorsList.map((option) => {
    const firstLetter = option.distribuidora[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidthExit] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setModalConsumerUnitRegistration(false);
      setStateExitModal(false);
    };

    const handleCloseExit = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidthExit}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "600px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2" sx={{ fontSize: "1.20rem" }}>
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleCloseExit()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalConsumerUnitRegistration}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "90px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
                padding: "0.8rem 0.7rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "-20px",
                  }}
                >
                  Cadastrar <br /> Unidade Consumidora
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1.25rem",
                  }}
                >
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="placeName"
                    name="placeName"
                    label="Nome da Unidade"
                    value={formik.values.placeName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.placeName &&
                      Boolean(formik.errors.placeName)
                    }
                    helperText={
                      formik.touched.placeName && formik.errors.placeName
                    }
                  />


                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) =>
                      `${option.distribuidora} - ${option.uf}`
                    }
                    sx={{
                      width: 100
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => {
                      formik.setFieldValue("distribuctors", value?.id);
                    }}
                    renderInput={(params) => (
                      <CustomInput
                        {...params}
                        size="small"
                        name="distribuctors"
                        label="Empresa de Energia"
                        value={formik.values.distribuctors}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.distribuctors &&
                          Boolean(formik.errors.distribuctors)
                        }
                        helperText={
                          formik.touched.distribuctors &&
                          formik.errors.distribuctors
                        }
                      />
                    )}
                  />

                  <FormControl
                    sx={{
                      display: "flex !important",
                      justifyContent: "flex-end !important",
                      "& .MuiFormControl-root": {
                        display: "flex !important",
                        justifyContent: "flex-end !important",
                      },
                      label: {
                        marginTop: "0.65rem !important",
                      }
                    }}
                  >
                    <InputLabel sx={{ top: '-7px', fontSize: '0.9rem' }} id="equipmentModelBusinessId">
                      Tarifa
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="tariffType"
                      id="tariffType"
                      value={formik.values.tariffType}
                      name="tariffType"
                      label="Tarifa"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.tariffType &&
                        Boolean(formik.errors.tariffType)
                      }
                    /* className={styles.fixMuiInputBaseRootEditModel} */
                    >
                      <MenuItem value="">
                        <em>Selecione um tipo de tarifa</em>
                      </MenuItem>
                      <MenuItem value={1}>Tarifa Convencional</MenuItem>
                      <MenuItem value={2}>Tarifa Branca Ponta</MenuItem>
                      <MenuItem value={3}>Tarifa Branca Intermediária </MenuItem>
                      <MenuItem value={4}>Tarifa Branca Fora Ponta </MenuItem>
                    </Select>
                    <span className={styles.error}>{formik.touched.tariffType && formik.errors.tariffType}</span>
                  </FormControl>

                  {/*      <InputMask
                    mask={maskConfig.phone}
                   
                  >
                    {(inputProps: Props) => {
                      return (
                       
                      )
                    }}
                  </InputMask> */}
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="Phone"
                    name="Phone"
                    label="Telefone"
                    value={formik.values.Phone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.Phone && Boolean(formik.errors.Phone)
                    }
                    helperText={formik.touched.Phone && formik.errors.Phone}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="placeEmail"
                    name="placeEmail"
                    label="E-mail"
                    value={formik.values.placeEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.placeEmail &&
                      Boolean(formik.errors.placeEmail)
                    }
                    helperText={
                      formik.touched.placeEmail && formik.errors.placeEmail
                    }
                  />
                  {/*   <InputMask
                    mask={maskConfig.cep}
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                  >
                    {(inputProps: Props) => (
                    
                    )}
                  </InputMask> */}
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="zipCode"
                    name="zipCode"
                    label="CEP"
                    error={
                      formik.touched.zipCode &&
                      Boolean(formik.errors.zipCode)
                    }
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.zipCode && formik.errors.zipCode
                    }
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="state"
                    name="state"
                    label="Estado"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="city"
                    name="city"
                    label="Cidade"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="district"
                    name="district"
                    label="Bairro"
                    value={formik.values.district}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.district && Boolean(formik.errors.district)
                    }
                    helperText={
                      formik.touched.district && formik.errors.district
                    }
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="street"
                    name="street"
                    label="Rua"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.street && Boolean(formik.errors.street)
                    }
                    helperText={formik.touched.street && formik.errors.street}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="number"
                    name="number"
                    label="N°"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    helperText={formik.touched.number && formik.errors.number}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="complement"
                    name="complement"
                    label="Complemento"
                    value={formik.values.complement}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.complement &&
                      Boolean(formik.errors.complement)
                    }
                    helperText={
                      formik.touched.complement && formik.errors.complement
                    }
                  />

                </Box>
                <Box
                  sx={{
                    padding: "1rem 0rem",
                  }}
                ></Box>
                <Divider />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                padding: "20px 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButtonLoading type="submit" title="Cadastrar" className="middle" />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <CustomSnackBar />
      <ExitModal
        messageTitle="Deseja cancelar o cadastro?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
