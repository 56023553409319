import React, { useState, useEffect } from 'react';
import style from './ChartLegendComp.module.css';

interface ChartLegendPayload {
    value?: string;
    type?: string;
}

interface ChartLegendItem extends ChartLegendPayload {
    color: string;
}

interface ChartLegendProps {
    payload?: ChartLegendPayload[];
    onClick?: (value: string) => void;
    chartColors: string[];
}

const truncateLabel = (label: string, maxLength: number) => {
    if (label.length > maxLength) {
        return `${label.substring(0, maxLength)}...`;
    }
    return label;
};

const ChartLegend: React.FC<ChartLegendProps & { hiddenLegends?: string[] }> = ({
    payload,
    onClick,
    chartColors = [],
    hiddenLegends = [],

}) => {
    const [clickedItems, setClickedItems] = useState<string[]>([]);

    const toggleClickedItem = (value: string) => {
        setClickedItems(prevState =>
            prevState.includes(value)
                ? prevState.filter(item => item !== value)
                : [...prevState, value]
        );
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const getLegendItems = () => {
        if (!payload || payload.length === 0) return [];

        // Mapeia e associa as cores
        const items = payload.map((entry, index) => {
            const color = chartColors[index % chartColors.length];
            return { ...entry, color };
        });

        // Ordena os itens em ordem alfabética com base no valor da legenda
        return items.sort((a, b) => {
            const valueA = a.value?.toLowerCase() || '';
            const valueB = b.value?.toLowerCase() || '';
            return valueA.localeCompare(valueB);
        });
    };

    const legendItems = getLegendItems();

    // Atualiza o tamanho da janela quando a janela for redimensionada
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={style.legendContainer}>
            {/* Div principal para os equipamentos */}
            <div className={`${style.chartLegend} ${windowWidth < 768 ? style.smallScreen : ''}`}>
                {legendItems.map((entry, index) => (
                    <div
                        key={`item-${index}`}
                        className={`${style.chartLegendItem} ${hiddenLegends.includes(entry.value || '') ? style.hiddenLegendItem : ''
                            }`}
                        onClick={() => {
                            toggleClickedItem(entry.value || '');
                            onClick?.(entry.value || '');
                        }}
                    >
                        <div
                            className={style.chartLegendColor}
                            style={{
                                backgroundColor: hiddenLegends.includes(entry.value || '') ? '#999999' : entry.color,
                            }}
                        />
                        <div
                            className={`${style.chartLegendLabel} ${hiddenLegends.includes(entry.value || '') ? style.hiddenLegendLabel : ''
                                }`}
                        >
                            {truncateLabel(entry.value || '', 17)}
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default ChartLegend;
