import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";

import { useFormik } from "formik";

import CustomButton from "../../Form/button";
import { useStateContext } from "../../../providers/stateContext";
import CustomButtonLoading from "../../Form/buttonLoading";
import { useMeterContext } from "../../../providers/meterContext";

type propsSM = {
  equipmentId: any;
};

type props = {
  messageTitle: string;
  massageContent: string;
};

export function ConnectIntrusiveSmartMeter({ equipmentId }: propsSM) {
  const { connectIntrusiveSmartMeter, setConnectIntrusiveSmartMeter } = useModalContext();
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } = useStateContext()
  const { associatedIntrusiveMeter, meterList } = useMeterContext()
  const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");
  const [stateExitModal, setStateExitModal] = useState(false);
  const [selectMeter, setSelectMeter] = useState<any>();
  const [selectValue, setSelectValue] = useState<any>('');

  const [equipmentR, setEquipmentR] = useState<any>(true);
  const [equipmentS, setEquipmentS] = useState<any>(true);
  const [equipmentT, setEquipmentT] = useState<any>(true);



  useEffect(() => {
  }, [associatedIntrusiveMeter]);


  useEffect(() => {
    let select = handleFilterMeterNotAssociated()
    setSelectMeter(select)
  }, [meterList])

  useEffect(() => {
    if (meterList) {
      checkMeterEquipment()
    }
  }, [selectValue])

  const handleFilterMeterNotAssociated = () => {
    let filterListMeterAssociated = [] as any;
    let diff

    if (meterList.intrusive) {
      meterList.intrusive.filter((meter: any) => {
        if (meter.equipmentR !== null && meter.equipmentS !== null && meter.equipmentT !== null) {
          filterListMeterAssociated.push(meter)
        }
      })
      diff = meterList.intrusive.filter(item => !filterListMeterAssociated.includes(item))
    }
    return diff
  }

  const checkMeterEquipment = () => {
    setEquipmentR(true)
    setEquipmentS(true)
    setEquipmentT(true)
    let checkEquipment
    if (meterList.intrusive) {
      checkEquipment = meterList.intrusive.forEach((meter: any) => {
        if (meter.id === selectValue.value) {
          if (meter.equipmentR === null) {
            setEquipmentR(false)
          }
          if (meter.equipmentS === null) {
            setEquipmentS(false)
          }
          if (meter.equipmentT === null) {
            setEquipmentT(false)
          }
        }
      })
    }
    return checkEquipment
  }

  const formik = useFormik({
    initialValues: {
      macId: "",
      channels: [],
    },

    onSubmit: async (values) => {
      setIsLoading(true)

      let hasConnect = await associatedIntrusiveMeter(equipmentId, values.macId, values.channels)

      if (hasConnect) {
        setIsLoading(false)
        setOpenSnackBar(true)
        setStatusErrorOpenSnackBar(false)
        setConnectIntrusiveSmartMeter(false)
      } else {
        setStatusErrorOpenSnackBar(true)
        setOpenSnackBar(true)
        setIsLoading(false)
      }

    },
  });

  const handleClose = () => {
    setStateExitModal(true);
  };

  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setConnectIntrusiveSmartMeter(false);
      setStateExitModal(false);
    };

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "400px",
            minWidth: "350px",
            minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleClose()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={connectIntrusiveSmartMeter}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            padding: "1rem",
            minWidth: "600px",
          }}
        >
          <Box
            sx={{
              padding: "20px 24px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
                mb: "1rem",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={100}
                height={90}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "20px",
                  }}
                >
                  Associar
                  <br />
                  Medidor
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "0px !important",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  component={"span"}
                  variant={"body2"}
                  sx={{
                    mb: "0.8rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  Medidores disponíveis
                  <Tooltip title="Medidores ainda não associados!">
                    <ErrorIcon
                      sx={{ width: "20px", height: "20px", color: "#ee9c0f" }}
                    />
                  </Tooltip>
                </Typography>
                <FormControl>
                  <InputLabel id="Medidores disponíveis">
                    Selecionar medidor
                  </InputLabel>
                  <Select
                    defaultValue={selectValue}
                    value={formik.values.macId}
                    id="macId"
                    name="macId"
                    labelId="macId"
                    label="Selecionar medidor"
                    onChange={(e) => {
                      setSelectValue(e.target)
                      formik.setFieldValue('macId', e.target.value)
                    }}
                    error={
                      formik.touched.macId &&
                      Boolean(formik.errors.macId)
                    }
                  >
                    {
                      selectMeter &&
                      selectMeter.map((meter: any, index: any) => {
                        return (
                          <MenuItem key={index} value={meter.id}>
                            {meter.name}
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
                {
                  formik.values.macId && (
                    <>
                      <Typography
                        component={"span"}
                        variant={"body2"}
                        sx={{
                          mb: "0.5rem",
                          mt: "1rem",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Canais disponíveis
                      </Typography>

                      <FormGroup
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          paddingLeft: "0.6rem"
                        }}
                      >
                        <FormControlLabel
                          disabled={equipmentR}
                          control={<Checkbox name="channels" value="R" onChange={formik.handleChange} />} label="R" />
                        <FormControlLabel
                          disabled={equipmentS}
                          control={<Checkbox name="channels" value="S" onChange={formik.handleChange} />} label="S" />
                        <FormControlLabel
                          disabled={equipmentT}
                          control={<Checkbox name="channels" value="T" onChange={formik.handleChange} />} label="T" />
                      </FormGroup>
                    </>
                  )
                }
              </Box>
            </DialogContent>
            <Box
              sx={{
                padding: "20px 10px",
              }}
            >
              <Divider />
            </Box>
            <DialogActions
              sx={{
                padding: "0rem 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1rem",
                }}
              >
                <CustomButtonLoading
                  type="submit"
                  title="Associar"
                  className="middle"
                />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <ExitModal
        messageTitle="Deseja cancelar o cadastro?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
