import React, { useState } from 'react';
import style from './ChartLegend.module.css';

interface ChartLegendPayload {
    value?: string;
    type?: string;
}

interface ChartLegendItem extends ChartLegendPayload {
    color: string;
}

interface ChartLegendProps {
    payload?: ChartLegendPayload[];
    onClick?: (value: string) => void;
    chartColors: string[];
    intermediarioColor: string;
    pontaColor: string;
}

const truncateLabel = (label: string, maxLength: number) => {
    if (label.length > maxLength) {
        return `${label.substring(0, maxLength)}...`;
    }
    return label;
};

const ChartLegend: React.FC<ChartLegendProps & { hiddenLegends?: string[] }> = ({
    payload,
    onClick,
    chartColors = [],
    intermediarioColor = '#F68D2B99',
    pontaColor = '#E23D2899',
    hiddenLegends = [],
}) => {
    const [clickedItems, setClickedItems] = useState<string[]>([]);

    const toggleClickedItem = (value: string) => {
        setClickedItems(prevState =>
            prevState.includes(value)
                ? prevState.filter(item => item !== value)
                : [...prevState, value]
        );
    };

    const getLegendItems = () => {
        if (!payload || payload.length === 0) return { specialItems: [] as ChartLegendItem[], regularItems: [] as ChartLegendItem[] };

        // Crie um mapeamento de valores para cores
        const colorMap: Record<string, string> = {
            'Horário Intermediário': intermediarioColor,
            'Horários de Ponta': pontaColor,
            // Adicione outros valores se necessário
        };

        // Mapeie cores para cada item antes de ordenar
        const mappedPayload = payload.map((entry) => {
            const color = colorMap[entry.value || ''] || chartColors[payload.indexOf(entry) % chartColors.length];
            return { ...entry, color };
        });

        // Ordena os itens da legenda pelo valor em ordem alfabética
        const sortedPayload = mappedPayload.sort((a, b) => (a.value || '').localeCompare(b.value || ''));

        const specialItems: ChartLegendItem[] = [];
        const regularItems: ChartLegendItem[] = [];

        sortedPayload.forEach((entry) => {
            if (entry.value === 'Horário Intermediário' || entry.value === 'Horários de Ponta') {
                specialItems.push(entry);
            } else {
                regularItems.push(entry);
            }
        });

        return { specialItems, regularItems };
    };


    const { specialItems, regularItems } = getLegendItems();

    return (
        <div className={style.legendContainer}>
            {specialItems.length > 0 && (
                <div className={style.specialLegend}>
                    {specialItems.map((entry, index) => (
                        <div
                            key={`special-item-${index}`}
                            className={`${style.chartLegendItem}`}

                        >
                            <div
                                className={style.chartLegendColor}
                                style={{
                                    backgroundColor: entry.color,
                                }}
                            />
                            <div
                                className={`${style.specialLegendLabel} ${clickedItems.includes(entry.value || '') ? style.clickedLabel : ''
                                    }`}
                            >
                                {truncateLabel(entry.value || '', 25)}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div className={style.chartLegend}>
                {regularItems.map((entry, index) => (
                    <div
                        key={`item-${index}`}
                        className={`${style.chartLegendItem} ${hiddenLegends.includes(entry.value || '') ? style.hiddenLegendItem : ''}`}
                        onClick={() => {
                            toggleClickedItem(entry.value || '');
                            onClick?.(entry.value || '');
                        }}
                    >
                        <div
                            className={style.chartLegendColor}
                            style={{
                                backgroundColor: hiddenLegends.includes(entry.value || '') ? '#999999' : entry.color, // Alteração aqui
                            }}
                        />
                        <div
                            className={`${style.chartLegendLabel} ${hiddenLegends.includes(entry.value || '') ? style.hiddenLegendLabel : ''}`}
                        >
                            {truncateLabel(entry.value || '', 17)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChartLegend;
