import React, { createContext, ReactNode, useContext, useState } from "react"
import { postMeterAPI, deleteMeterAPI, putMeterAPI, associatedSmartMeterAPI, disassociateSmartMeterAPI, associatedIntrusiveMeterAPI, disassociateIntrusiveMeterAPI, getMetersByConsumerUnitAPI } from "../services/restApiMeter"
import { meter } from "../util/types"
import { useConsumerUnitContext } from "./consumerUnitContext"
import { useEquipmentContext } from "./equipmentContext"
import { useStateContext } from "./stateContext"
import { ROLE } from "../util/enums/Role";

export interface IPostPUTModel {
  id: string,
  name: string,
  isSmart: boolean,
  consumerUnitId: number
}

type PropsMeterContext = {
  meterList: meter,
  setMeterList: React.Dispatch<React.SetStateAction<meter>>

  getMeterListByConsumerUnit: () => Promise<void>,
  getMeterById: (id: string | number) => Promise<void>,
  postMeter: (smartMeter: IPostPUTModel) => Promise<boolean>,
  putMeter: (smartMeter: any, id: any) => Promise<boolean>,
  deleteMeter: (id: number | string) => Promise<boolean>,

  associatedSmartMeter: (id: number | string, macId: number | string) => Promise<boolean>,
  desassociateSmartMeter: (id: number | string) => Promise<boolean>,

  associatedIntrusiveMeter: (id: number | string, macId: number | string, channels: any) => Promise<boolean>,
  desassociateIntrusiveMeter: (id: number | string) => Promise<boolean>,
}

type MeterContextTypes = {
  children: ReactNode
}

export const MeterContext = createContext({} as PropsMeterContext)

function MeterContextProvider(props: MeterContextTypes) {
  const [meterList, setMeterList] = useState<meter>({} as meter)

  const { setIsGetFetching, setSnackBarTextResponse, selectConsumerUnitId, setStatusErrorOpenSnackBar, setOpenSnackBar } = useStateContext()
  const { getConsumerUnitList } = useConsumerUnitContext()
  const { getEquipmentByIdConsumerUnit } = useEquipmentContext()

  const getMeterListByConsumerUnit = async () => {
    if (!selectConsumerUnitId) return;
    
    setIsGetFetching(true)
    try {
      const { data, status } = await getMetersByConsumerUnitAPI(selectConsumerUnitId)
      if (status === 200) {
        setMeterList(data)
        setIsGetFetching(false)
        setOpenSnackBar(false)
      }
    } catch (error: any) {
      setMeterList({} as meter)
      setIsGetFetching(false)
    }
  }

  const getMeterById = async (id: string | number) => { }

  const postMeter = async (smartMeter: any) => {
    let hasFetching = false
    try {
      const { status } = await postMeterAPI(smartMeter)
      hasFetching = status === 200

      if (hasFetching) {
        getMeterListByConsumerUnit()
      }
      setSnackBarTextResponse('Medidor cadastrado com sucesso!')
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetching
  }

  const putMeter = async (smartMeter: any, id: any) => {
    let hasFetching = false
    try {
      const { status } = await putMeterAPI(smartMeter, id)
      hasFetching = status === 200

      if (hasFetching) {
        getMeterListByConsumerUnit()
      }
      setSnackBarTextResponse('Medidor atualizado com sucesso!')
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetching
  }

  const deleteMeter = async (id: number | string) => {
    let hasFetching = false
    try {
      const { status } = await deleteMeterAPI(id)
      hasFetching = status === 200

      if (hasFetching) {
        getMeterListByConsumerUnit()
      }
      setSnackBarTextResponse('Medidor deletado com sucesso!')
    }
    catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }
    return hasFetching
  }

  const associatedSmartMeter = async (id: number | string, macId: number | string) => {
    let hasFetching = false
    const role = localStorage.getItem("role");
    const userIdValue = localStorage.getItem("userIdValue");
    let privilege = false
    privilege = role === ROLE.ADMIN || role === ROLE.TECHNICAL || role === ROLE.CLIENT

    try {
      const { status } = await associatedSmartMeterAPI(id, macId)
      hasFetching = status === 200
      if (hasFetching && userIdValue) {
        getConsumerUnitList(userIdValue, privilege)
        setSnackBarTextResponse('Medidor associado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasFetching
  }

  const desassociateSmartMeter = async (id: number | string) => {
    let hasFetching = false
    const role = localStorage.getItem("role");
    const userIdValue = localStorage.getItem("userIdValue");
    let privilege = false
    privilege = role === ROLE.ADMIN || role === ROLE.TECHNICAL || role === ROLE.CLIENT

    try {
      const { status } = await disassociateSmartMeterAPI(id)
      hasFetching = status === 200
      if (hasFetching && userIdValue) {
        getConsumerUnitList(userIdValue, privilege)
        getMeterListByConsumerUnit()
        setSnackBarTextResponse('Medidor desassociado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasFetching
  }

  const associatedIntrusiveMeter = async (id: number | string, macId: number | string, channels: any) => {
    let hasFetching = false

    let bodyRequest = {
      macId: macId,
      channels: channels
    }

    try {
      const { status } = await associatedIntrusiveMeterAPI(id, bodyRequest)
      hasFetching = status === 200
      if (hasFetching) {
        getEquipmentByIdConsumerUnit(selectConsumerUnitId)
        getMeterListByConsumerUnit()
        setSnackBarTextResponse('Medidor associado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasFetching
  }

  const desassociateIntrusiveMeter = async (id: number | string) => {
    let hasFetching = false
    try {
      const { status } = await disassociateIntrusiveMeterAPI(id)
      hasFetching = status === 200
      if (hasFetching) {
        getEquipmentByIdConsumerUnit(selectConsumerUnitId)
        getMeterListByConsumerUnit()
        setSnackBarTextResponse('Medidor desassociado com sucesso!')
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response)?.error
      setSnackBarTextResponse(errorMessage)
    }

    return hasFetching
  }

  const objProvider = {
    meterList,
    setMeterList,
    getMeterListByConsumerUnit,
    getMeterById,
    postMeter,
    putMeter,
    deleteMeter,
    associatedSmartMeter,
    desassociateSmartMeter,
    associatedIntrusiveMeter,
    desassociateIntrusiveMeter,
  }

  return (
    <MeterContext.Provider
      value={objProvider}
    >
      {props.children}
    </MeterContext.Provider>
  )
}

function useMeterContext() {
  const context = useContext(MeterContext)
  return context
}

export {
  MeterContextProvider,
  useMeterContext
}
