// ChartConfig.js
import { useState, useEffect } from "react";

export default function useChartConfig() {
    const [config, setConfig] = useState({
        tickFontSize: 14,
        tspanFontSize: 10,
        x1: -10,
        xOffset: -40,
        strokeWidth: 1.5,
        y2: 8,
        dy: 15,
        containerWidth: "100%",
        containerHeight: "100%",
        chartWidth: 800,
        chartHeight: 400,

        margins: { top: 30, right: 120, left: 85, bottom: 0 },

    });

    useEffect(() => {
        const updateConfig = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;

            if (width === 360 && height === 640) {
                setConfig({
                    tickFontSize: 3,
                    tspanFontSize: 2,
                    dy: 0,
                    x1: -2,
                    y2: 2,
                    strokeWidth: 0.8,
                    xOffset: -10,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 70, right: 10, left: -30, bottom: 0 },

                });

            }
            else if (width === 375 && height === 667) {
                setConfig({
                    tickFontSize: 3,
                    tspanFontSize: 2,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 70, right: 10, left: -30, bottom: 0 },

                });
            }
            else if (width <= 500 && height <= 300) {
                setConfig({
                    tickFontSize: 3,
                    tspanFontSize: 2,
                    dy: 1,
                    x1: -2,
                    y2: 2,
                    strokeWidth: 0.5,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 0, right: 10, left: -30, bottom: 0 },

                });

            }
            else if (width <= 800 && height <= 400) {
                setConfig({
                    tickFontSize: 3,
                    tspanFontSize: 2,
                    dy: 0,
                    x1: -2,
                    y2: 2,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 20, right: 10, left: -30, bottom: -15 },

                });

            }
            else if (width <= 900 && height <= 700) {
                setConfig({
                    tickFontSize: 6,
                    tspanFontSize: 4,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 0, right: 10, left: 0, bottom: 20 },

                });


            }
            else if (width <= 1024 && height <= 450) {
                setConfig({
                    tickFontSize: 8,
                    tspanFontSize: 6,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 0, right: 10, left: 0, bottom: 20 },

                });


                // Outras configurações baseadas em largura e altura
            } else if (width <= 1024 && height <= 700) {
                setConfig({
                    tickFontSize: 8,
                    tspanFontSize: 6,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 0, right: 10, left: 0, bottom: 20 },

                });


            } else if (width <= 1280 && height <= 600) {
                setConfig({
                    tickFontSize: 8,
                    tspanFontSize: 6,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 0, right: 10, left: 0, bottom: 20 },

                });


            } else if (width >= 1500 && height >= 2000) {
                setConfig({
                    tickFontSize: 3,
                    tspanFontSize: 2,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 70, right: 10, left: -30, bottom: 0 },

                });


            } else if (width <= 320) {
                // Configuração para largura <= 360
                setConfig({
                    tickFontSize: 3,
                    tspanFontSize: 2,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 70, right: 10, left: -30, bottom: 0 },

                });

            } else if (width <= 360) {
                // Configuração para largura <= 360
                setConfig({
                    tickFontSize: 3,
                    tspanFontSize: 2,
                    dy: 1,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 70, right: 10, left: -30, bottom: 0 },

                });


            } else if (width <= 375) {
                setConfig({
                    tickFontSize: 4,
                    tspanFontSize: 3,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 10, left: -30, bottom: 40 },

                });
            } else if (width <= 390) {
                setConfig({
                    tickFontSize: 4,
                    tspanFontSize: 3,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 20, left: -30, bottom: 40 },

                });
            } else if (width <= 412) {
                setConfig({
                    tickFontSize: 4,
                    tspanFontSize: 3,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 20, left: -30, bottom: 40 },

                });
            } else if (width <= 414) {
                setConfig({
                    tickFontSize: 4,
                    tspanFontSize: 3,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 20, left: -30, bottom: 40 },

                });
            } else if (width <= 480) {
                setConfig({
                    tickFontSize: 5,
                    tspanFontSize: 4,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -12,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 30, left: -20, bottom: 40 },

                });
            } else if (width <= 600) {
                setConfig({
                    tickFontSize: 7,
                    tspanFontSize: 5,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -15,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 40, right: 30, left: 5, bottom: 50 },

                });

            } else if (width <= 768) {
                setConfig({
                    tickFontSize: 9,
                    tspanFontSize: 7,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    xOffset: -15,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 40, right: 30, left: 15, bottom: 30 },

                });
            } else if (width <= 800) {
                setConfig({
                    tickFontSize: 9,
                    tspanFontSize: 7,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 0.8,
                    xOffset: -15,
                    margins: { top: 40, right: 30, left: 25, bottom: 90 },

                });

            } else if (width <= 1024) {
                setConfig({
                    tickFontSize: 8,
                    tspanFontSize: 6,
                    dy: 8,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 1,
                    xOffset: -15,
                    margins: { top: 30, right: 80, left: 35, bottom: 0 },

                });

            } else if (width <= 1280) {
                setConfig({
                    tickFontSize: 8,
                    tspanFontSize: 6,
                    dy: 6,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 1.5,
                    xOffset: -15,
                    margins: { top: 30, right: 90, left: 50, bottom: 10 },

                });
            } else if (width <= 1366) {
                setConfig({
                    tickFontSize: 10,
                    tspanFontSize: 8,
                    dy: 12,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 1.5,
                    xOffset: -20,
                    margins: { top: 30, right: 90, left: 45, bottom: 20 },

                });
            } else if (width <= 1440) {
                setConfig({
                    tickFontSize: 10,
                    tspanFontSize: 8,
                    xOffset: -20,
                    dy: 12,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 1.5,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 90, left: 45, bottom: 0 },

                });
            } else if (width <= 1680) {
                setConfig({
                    tickFontSize: 10,
                    tspanFontSize: 8,
                    dy: 5,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 1.5,
                    xOffset: -10,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 100, left: 55, bottom: 0 },

                });

            } else if (width <= 1905) {
                setConfig({
                    tickFontSize: 12,
                    tspanFontSize: 10,
                    dy: 9,
                    x1: -5,
                    y2: 5,
                    strokeWidth: 1.5,
                    xOffset: -20,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 100, left: 85, bottom: 0 },

                });
            } else {
                // Configuração para telas maiores, como desktops
                setConfig({
                    tickFontSize: 14,
                    tspanFontSize: 12,
                    xOffset: -27,
                    x1: -10,
                    dy: 15,
                    strokeWidth: 1.5,
                    y2: 8,
                    containerWidth: "100%",
                    containerHeight: "100%",
                    margins: { top: 30, right: 120, left: 85, bottom: 0 },

                });
            }
        };

        updateConfig();  // Chama ao carregar o componente para definir o estado inicial
        window.addEventListener("resize", updateConfig);  // Atualiza o estado ao redimensionar a janela

        return () => window.removeEventListener("resize", updateConfig);  // Remove o listener ao desmontar o componente
    }, []);

    return config;
}
