import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "../../../components/CustomInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel, Typography } from "@mui/material";

import styles from './styles.module.scss';

import { useFormik } from "formik";
import * as yup from "yup";

import { useModalContext } from "../../../providers/modalContext";
import Logo from "../../../assets/image/deep-ambiente-logo.svg";
import CustomButton from "../../Form/button";
import { useAuth } from "../../../providers/authContext";
import { useStateContext } from "../../../providers/stateContext";
import InputMask from 'react-input-mask';
import maskConfig from "../../../util/maks";
import { CustomSnackBar } from "../../Snackbar";
import CustomButtonLoading from "../../Form/buttonLoading";
import onlyNumber from "../../../util/onlyNumber";
import { ROLE } from "../../../util/enums/Role";

type propsModal = {
  userEdit: any;
  list: boolean;
};

type props = {
  messageTitle: string;
  massageContent: string;
};

export function ModalEditClient({ userEdit, list }: propsModal) {
  const { modalEditClient, setModalEditClient } = useModalContext();
  const { setIsLoading, setOpenSnackBar, setStatusErrorOpenSnackBar } = useStateContext()
  const { EditUser } = useAuth();
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [stateExitModal, setStateExitModal] = useState(false);
  const { deleteUser, userList, getUserList, logout, dataUser } = useAuth()

  const { isCnpj, fullName, userName, phone, document, email, userRole } = userEdit;

  useEffect(() => {
    formik.setFieldValue("fullName", fullName, false);
    formik.setFieldValue("userName", userName, false);
    formik.setFieldValue("phone", phone, false);
    formik.setFieldValue("document", document, false);
    formik.setFieldValue("email", email, false);
    formik.setFieldValue("role", userRole.name, false);
  }, [userEdit]); // eslint-disable-line react-hooks/exhaustive-deps


  const validationSchema = yup.object({
    fullName: yup
      .string()
      .required("O nome é obrigatório!")
      .max(130, "No máximo 130 caracteres"),
    userName: yup
      .string()
      .max(20, "No máximo 20 caracteres"),
    phone: yup.string().min(10, "No mínimo 10 caracteres").required("O telefone é obrigatório!"),
    document: yup
      .string()
      .max(95, "No máximo 95 caracteres"),
    email: yup
      .string()
      .email("Entrar com email valido")
      .required("O email é obrigatório!")
      .max(50, "No máximo 50 caracteres"),
    role: yup.string().required("O papel é obrigatório!"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: fullName,
      userName: userName,
      email: email,
      phone: onlyNumber(phone),
      document: onlyNumber(document),
      role: userRole.name,
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const mountedEditUser = {
        fullName: values.fullName,
        userName: values.userName,
        email: values.email,
        phone: onlyNumber(values.phone),
        document: onlyNumber(values.document),
        role: values.role,
      }

      setIsLoading(true)
      const hasEdit = await EditUser(mountedEditUser, userEdit.id, list);

      if (hasEdit) {
        setOpenSnackBar(true)
        setStatusErrorOpenSnackBar(false);
        setIsLoading(false)
        setModalEditClient(false);
      } else {
        setOpenSnackBar(true)
        setIsLoading(false)
        setStatusErrorOpenSnackBar(true);
      }
      setIsLoading(false)
    },
  });

  const handleClose = () => {
    if (
      formik.values.fullName !== fullName ||
      formik.values.userName !== userName ||
      formik.values.phone !== phone ||
      formik.values.document !== document ||
      formik.values.email !== email ||
      formik.values.role !== userRole.name
    ) {
      setStateExitModal(true);
    } else {
      setModalEditClient(false);
    }
  };


  function ExitModal({ messageTitle, massageContent }: props) {
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");

    const CloseAllModal = () => {
      setModalEditClient(false);
      setStateExitModal(false);
    };

    const handleClose = () => {
      setStateExitModal(false);
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={stateExitModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.associarUnid}
          sx={{
            padding: "1.25rem !important",
            display: "grid",
            maxWidth: "400px",
            //minWidth: "350px",
            //minHeight: "170px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {messageTitle}
          </Typography>
          <Typography component={"span"} variant={"body2"}>
            {massageContent}
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "8rem 8rem",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              type="button"
              title="Não"
              className="smallOutline"
              onClick={() => handleClose()}
            />
            <CustomButton
              type="button"
              title="Sim"
              className="small"
              onClick={() => CloseAllModal()}
            />
          </div>
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={modalEditClient}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box className={styles.editarBox}
          sx={{
            padding: "0rem",
            minWidth: "100px",
          }}
        >
          <Box
            sx={{
              padding: "5px 5px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "120px 3fr 1fr",
                justifyContent: "space-between",
              }}
            >
              <img
                src={Logo}
                alt="Logo Deep Ambiente"
                width={80}
                height={60}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    marginRight: "35px",
                  }}
                >
                  Editar Usuário
                </DialogTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  //justifyContent: "flex-end",
                  padding: "8px 5px",
                }}
              >
                <CloseIcon className={styles.closeIC}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>
            </Box>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Divider sx={{ mb: "1rem" }} />
              <Box>
                <Box sx={{ mb: "10px" }}>
                  <Typography
                    component={"span"}
                    variant={"body2"}
                    sx={{ mb: "1rem" }}
                  >
                    Dados Pessoais
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "20px",
                  }}
                >
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="fullName"
                    name="fullName"
                    label="Nome Completo"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                  {/*  <InputMask mask={isCnpj ? maskConfig.cnpj : maskConfig.cpf} value={formik.values.document} disabled={true} onChange={formik.handleChange}>
                    {(inputProps: any) => <CustomInput
                      {...inputProps}
                      size="small"
                      variant="outlined"
                      id="document"
                      name="document"
                      disabled={true}
                      label={isCnpj ? "CNPJ" : "CPF"}
                      error={formik.touched.document && Boolean(formik.errors.document)}
                      helperText={formik.touched.document && formik.errors.document}
                    />
                    }
                  </InputMask> */}
                  <CustomInput
                    value={formik.values.document}
                    onChange={formik.handleChange}
                    size="small"
                    variant="outlined"
                    id="document"
                    name="document"
                    disabled={true}
                    label={isCnpj ? "CNPJ" : "CPF"}
                    error={formik.touched.document && Boolean(formik.errors.document)}
                    helperText={formik.touched.document && formik.errors.document}
                  />
                  {/* <InputMask mask={maskConfig.phone} value={formik.values.phone} onChange={formik.handleChange}>
                    {(inputProps: any) => <CustomInput
                      {...inputProps}
                      size="small"
                      variant="outlined"
                      id="phone"
                      name="phone"
                      label="Telefone"
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    }
                  </InputMask> */}
                  <CustomInput
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    size="small"
                    variant="outlined"
                    id="phone"
                    name="phone"
                    label="Telefone"
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <CustomInput
                    size="small"
                    variant="outlined"
                    id="userName"
                    name="userName"
                    label="Nome de Usuário"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    disabled={true}
                    error={
                      formik.touched.userName && Boolean(formik.errors.userName)
                    }
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                  />

                  {userRole?.name && dataUser.userRole.name === ROLE.ADMIN ? (
                    <FormControl
                      sx={{
                        display: "flex !important",
                        justifyContent: "flex-end !important",
                        "& .MuiFormControl-root": {
                          display: "flex !important",
                          justifyContent: "flex-end !important",
                        },
                        label: {
                          marginTop: "1rem !important",
                        }
                      }}
                    >
                      <InputLabel sx={{ top: '-7px', fontSize: '0.9rem' }} id="role">
                        Papel
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="role"
                        id="role"
                        value={formik.values.role}
                        name="role"
                        label="Papel"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.role &&
                          Boolean(formik.errors.role)
                        }
                      /* className={styles.fixMuiInputBaseRootEditModel} */
                      >
                        <MenuItem value="" selected disabled>
                          <em>Selecione um papel</em>
                        </MenuItem>
                        <MenuItem value={ROLE.CLIENT}>Cliente</MenuItem>
                        <MenuItem value={ROLE.TECHNICAL}>Técnico</MenuItem>
                        <MenuItem value={ROLE.ADMIN}>Admin</MenuItem>
                      </Select>
                      {/*  <span className={styles.error}>{formik.touched.tariffType && formik.errors.tariffType}</span> */}
                    </FormControl>
                  ) : (
                    <></>
                  )
                  }

                </Box>
                <Divider sx={{ mt: "1rem" }} />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                padding: "0rem 1.5rem !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1rem",
                }}
              >
                <CustomButtonLoading type="submit" title="Salvar" className="middle" />
              </Box>
            </DialogActions>
          </form>
        </Box>
      </Dialog>

      <CustomSnackBar />
      <ExitModal
        messageTitle="Deseja cancelar a edição?"
        massageContent="Os dados preenchidos serão perdidos!"
      />
    </>
  );
}
