import { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person';
import Divider from '@mui/material/Divider';
import styles from './styles.module.scss'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CustomButton from "../../components/Form/button";
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from "../../providers/authContext";
import { useModalContext } from "../../providers/modalContext";
import { ModalEditClient } from '../../components/Modal/EditClient'
import { getContract } from '../../services/restApiContract'
import { formatPhoneNumber, formatDocument } from "../../util/formattingUtils";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MyProfile() {
  const { setModalEditClient } = useModalContext()
  const [expanded, setExpanded] = useState(false);
  const [mountedUserClientEdit, setMountedUserClientEdit] = useState()
  const [userContract, setUserContract] = useState({ id: 0, userId: "", contract: "" });
  const { dataUser } = useAuth()

  useEffect(() => {
    handleContract();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenModalEditClient = (user: any) => {
    setMountedUserClientEdit(user)
    setModalEditClient(true)
  }

  const handleContract = async () => {
    let recoveredUserId = localStorage.getItem("userIdValue");
    if (recoveredUserId) {
      const contractdata = await getContract(recoveredUserId);
      setUserContract(contractdata.data);
    }
  };

  const handlePrint = (userContract: any) => {
    const openscreen = window.open("about:blank");
    if (openscreen) {
      openscreen?.document.write(userContract.contract);
      openscreen?.window.print();
      openscreen?.window.close();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <main className={styles.mainContainer}>
          <h2>Meu Perfil</h2>
          <br />
          <Card >
            <CardHeader
              style={{ padding: "1rem" }}
              avatar={
                <Avatar sx={{ color: '#0f85ca', backgroundColor: "rgba(220, 224, 233, 0.845)" }} aria-label="recipe">
                  <PersonIcon />
                </Avatar>
              }
              title={dataUser.fullName}
              subheader={dataUser.userName}
              sx={{ padding: '1rem' }}
            />
            {
              dataUser &&
              <CardContent sx={{ padding: '1rem', }}>

                <Box sx={{ display: 'flex', gap: 1, mt: '1rem', alignItems: 'center' }}>
                  <Typography variant="h6" component="h2">E-mail: </Typography>
                  <Typography variant="subtitle1" component="h2">{dataUser.email}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: '1rem', alignItems: 'center' }}>
                  <Typography variant="h6" component="h2">Telefone: </Typography>
                  <Typography variant="subtitle1" component="h2">{formatPhoneNumber(dataUser.phone)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: '1rem', alignItems: 'center' }}>
                  <Typography variant="h6" component="h2">Documento: </Typography>
                  <Typography variant="subtitle1" component="h2">{formatDocument(dataUser.document)}</Typography>
                </Box>
                <Box onClick={handleExpandClick} sx={{ display: 'flex', gap: 1, mt: '1rem', alignItems: 'center', cursor: 'pointer' }}>
                  <Typography variant="h6" component="h2">Unidades Consumidoras: </Typography>
                  <Typography variant="subtitle1" component="h2">{dataUser.consumerUnits?.length}</Typography>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent sx={{ paddingBottom: '0 !important', paddingTop: '5px !important' }}>
                    {
                      dataUser.consumerUnits?.map((units) => {
                        return (
                          <div key={units.id}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <Typography variant="subtitle1" component="h2">{units.placeName}</Typography>
                            </Box>

                          </div>
                        )
                      })
                    }
                  </CardContent>
                </Collapse>

                <CardActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <CustomButton type="button" title="Editar" className="middleOutline" onClick={() => handleOpenModalEditClient(dataUser)} />
                  <CustomButton type="button" title="Contrato" className="middleOutline" onClick={() => handlePrint(userContract)} />
                </CardActions>
              </CardContent>
            }
          </Card>
        </main>
      </div>
      {
        mountedUserClientEdit ?
          <ModalEditClient userEdit={mountedUserClientEdit} list={false} />
          : <div />
      }
    </div >
  )
}
