import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { DateTime } from "luxon";

import PieChart from "../../../../../components/Charts/PieChart";

import ChartFullScreen from "../../../../../components/Modal/ChartFullScreen";

import { useStateContext } from "../../../../../providers/stateContext";
import { useReportContext } from "../../../../../providers/reportContext";
import { useModalContext } from "../../../../../providers/modalContext";
import { ReactComponent as NewIcon } from "../../../../../util/SVGs/calendar.svg";

import styles from "./styles.module.scss";
import { StringUtils } from "../../../../../util/StringUtils/StringUtils";
import CustomDataPickerModal from "../../CustomDataPickerModal";
import { DateType } from "../../../../../util/enums/dateType";

import { format, differenceInDays } from "date-fns";
import useFormatDate from "../../../../../util/hooks/useFormartDate";
import useDimensions from "../../../../../util/hooks/useDimensions";
import { useZoom } from "../../../../../util/hooks/useZoom";

function PieChartCard({ dataChart }, ref) {
  const { sectorId, setDateToCallApiForChartPie, setDateToCallApiForChartPieRange } = useStateContext();
  const { loadingChartPie } = useReportContext();

  const { totalConsumption } = dataChart;

  const { isTablet } = useDimensions();

  const [hoursFilteredConsumptionPrice, setHoursFilteredConsumptionPrice] = useState(0);
  const [hoursFilteredConsumption, setHoursFilteredConsumption] = useState(0);

  const { zoomClass } = useZoom();

  const { formatTomorrow } = useFormatDate();

  const customDatepickerModalRef = useRef(null);

  const now = DateTime.now();
  const initialStartTime = now.startOf("day").toJSDate();
  const initialEndTime = now.endOf("day").toJSDate();
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);

  const isShowingOthers = sectorId === -1;
  const arrayOfValues = dataChart?.smartMeterConsumption?.values;

  const [activeLegends, setActiveLegends] = useState({});

  const [animationComplete, setAnimationComplete] = useState(false);

  useImperativeHandle(ref, () => ({
    get dateType() {
      return customDatepickerModalRef?.current?.dateType;
    },
  }));

  useEffect(() => {
    if (dataChart?.equipmentConsumptions) {
      const initialLegends = {};
      let totalEquipmentConsumption = 0;

      const calculateFilteredValues = (values) => {
        if (!Array.isArray(values)) return 0;
        return values.filter(({ timestamp }) => {
          const adjustedTimestamp = new Date(timestamp + 3 * 60 * 60 * 1000).getTime();
          return (
            adjustedTimestamp >= new Date(startTime).getTime() &&
            adjustedTimestamp <= new Date(endTime).getTime()
          );
        }).reduce((sum, { consumption = 0 }) => sum + consumption, 0);
      };

      dataChart.equipmentConsumptions.forEach(({ name, values }) => {
        const filteredConsumption = calculateFilteredValues(values);
        if (filteredConsumption > 0) {
          initialLegends[name] = true;
          totalEquipmentConsumption += filteredConsumption;
        }
      });

      const totalSmartMeterConsumption = arrayOfValues?.reduce(
        (sum, item) => sum + (item.consumption || 0),
        0
      ) || 0;

      const othersConsumption = totalSmartMeterConsumption - totalEquipmentConsumption;

      if (isShowingOthers && othersConsumption > 0) {
        initialLegends["Outros"] = true;
      } else {
        initialLegends["Outros"] = false; // Garante que "Outros" sempre tenha um valor inicial.
      }

      setActiveLegends(initialLegends);
    }
  }, [dataChart?.equipmentConsumptions, arrayOfValues, isShowingOthers, startTime, endTime]);

  useEffect(() => {
    if (sectorId && startTime && endTime) {

      const start = new Date(startTime);
      const end = new Date(endTime);

      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      setStartTime(start);
      setEndTime(end);
    }
  }, [sectorId]);


  const onLegendChange = (updatedLegends) => {
    const validatedLegends = { ...updatedLegends };

    Object.keys(validatedLegends).forEach((legend) => {
      if (legend === "Outros" && !isShowingOthers) {
        validatedLegends[legend] = false; // Garante consistência se "Outros" não deve aparecer.
      }
    });

    setActiveLegends(validatedLegends);
  };

  useEffect(() => {
    if (!startTime || !endTime) return;

    const calculateFilteredValues = (values) => {
      if (!Array.isArray(values)) return { totalConsumption: 0, totalPrice: 0 };

      const filteredValues = values.filter(({ timestamp }) => {
        const adjustedTimestamp = new Date(timestamp + 3 * 60 * 60 * 1000).getTime();
        return (
          adjustedTimestamp >= new Date(startTime).getTime() &&
          adjustedTimestamp <= new Date(endTime).getTime()
        );
      });

      return {
        totalConsumption: filteredValues.reduce((sum, { consumption = 0 }) => sum + consumption, 0),
        totalPrice: filteredValues.reduce((sum, { price = 0 }) => sum + price, 0),
      };
    };

    const calculateOthers = (smartMeterConsumption, smartMeterPrice, equipmentConsumption, equipmentPrice) => {
      const othersConsumption = Math.max(0, smartMeterConsumption - equipmentConsumption);
      const othersPrice = Math.max(0, smartMeterPrice - equipmentPrice);
      return { othersConsumption, othersPrice };
    };

    const calculateFinalValues = (smartMeterData, equipmentData, legends, showOthers) => {
      const { totalConsumption: smartMeterConsumption, totalPrice: smartMeterPrice } = calculateFilteredValues(smartMeterData || []);
      let equipmentConsumption = 0;
      let equipmentPrice = 0;

      equipmentData.forEach(({ values }) => {
        const { totalConsumption, totalPrice } = calculateFilteredValues(values || []);
        equipmentConsumption += totalConsumption;
        equipmentPrice += totalPrice;
      });

      const { othersConsumption, othersPrice } = calculateOthers(
        smartMeterConsumption,
        smartMeterPrice,
        equipmentConsumption,
        equipmentPrice
      );

      let finalConsumption = 0;
      let finalPrice = 0;

      equipmentData.forEach(({ values, name }) => {
        if (legends[name]) {
          const { totalConsumption, totalPrice } = calculateFilteredValues(values || []);
          finalConsumption += totalConsumption;
          finalPrice += totalPrice;
        }
      });

      if (showOthers && legends["Outros"]) {
        finalConsumption += othersConsumption;
        finalPrice += othersPrice;
      }

      return { finalConsumption, finalPrice };
    };

    const { finalConsumption, finalPrice } = calculateFinalValues(
      arrayOfValues || [],
      dataChart?.equipmentConsumptions || [],
      activeLegends,
      isShowingOthers
    );

    setHoursFilteredConsumption(finalConsumption);
    setHoursFilteredConsumptionPrice(finalPrice);
    setAnimationComplete(true);

  }, [arrayOfValues, startTime, endTime, dataChart, activeLegends, isShowingOthers]);

  useEffect(() => {
    setDateToCallApiForChartPie(formatTomorrow(startTime.toISOString()));
  }, []);

  function DateForChart() {

    const isFetchingRange = customDatepickerModalRef?.current?.dateType === DateType.PERIOD;

    let dateStart = new Date(startTime);
    let dateEnd = new Date(endTime);

    let dtStart = DateTime.fromMillis(dateStart.getTime());
    let dtEnd = DateTime.fromMillis(dateEnd.getTime());

    let formatPieDate = {
      end: dtEnd.toUTC().toLocaleString({ month: "long", day: "numeric" }),
      date: dtEnd.toLocaleString({
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
    };

    if (isFetchingRange) {
      formatPieDate = {
        start: dtStart.toUTC().toLocaleString({ month: "long", day: "numeric" }),
        end: dtEnd.toUTC().toLocaleString({ month: "long", day: "numeric" }),
        date: `${dtStart.toLocaleString({ month: "long", day: "numeric" })} a ${dtEnd.toLocaleString({ month: "long", day: "numeric" })}`,
      };
    };

    return <p className={styles.date}>{`${formatPieDate.date}`}</p>;
  };

  const compareDates = (start, end) => {
    const startDifference = differenceInDays(startTime, start);
    const endDifference = differenceInDays(endTime, end);

    return [startDifference, endDifference].every((value) => value === 0);
  };

  const onSetDates = (start, end, type) => {
    setStartTime(start);
    setEndTime(end);

    if (compareDates(start, end)) return;

    switch (type) {

      case DateType.PERIOD: {
        const adjustedEnd = new Date(end);
        adjustedEnd.setDate(adjustedEnd.getDate() + 1);

        setDateToCallApiForChartPieRange({
          start: format(start, "yyyy-MM-dd"),
          end: format(adjustedEnd, "yyyy-MM-dd"),
        });
        break;
      };

      case DateType.DATE:
      default:
        setDateToCallApiForChartPie(formatTomorrow(start.toISOString()));
    }
  };
  const onOpenCustomDatepicker = () => {
    customDatepickerModalRef?.current?.openModal(startTime, endTime);
  };

  const getConsumptionToRender = () => {
    return hoursFilteredConsumption.toFixed(2);
  };

  const { integer, decimal } = StringUtils.getBRLParts(hoursFilteredConsumptionPrice);

  return (
    <div className={styles.middleCard}>

      <div className={isTablet ? styles.CardHeader__tablet : styles.CardHeader}>

        <div className={styles.containerTitle}>
          <h3>{"Consumo por período"}</h3>
          <h5>{DateForChart()}</h5>
        </div>

        <div className={styles.containerIcon}>
          <div className={styles.sectionIcon}>
            <NewIcon
              className={styles.circleIcon}
              onClick={onOpenCustomDatepicker}
            />
          </div>
        </div>

      </div>

      <div className={isTablet ? `${styles.info__chart__container__tablet} ${styles[zoomClass]}` : `${styles.info__chart__container} ${styles[zoomClass]}`}>

        {!loadingChartPie && totalConsumption > 0 ? (
          <div className={isTablet ? `${styles.Informative__tablet} ${styles[zoomClass]}` : `${styles.Informative} ${styles[zoomClass]}`}>

            <div className={isTablet ? styles.totals__tablet : `${styles.totals} ${styles[zoomClass]}`}>

              <div className={styles.column}>
                <span className={`${styles.value} ${styles[zoomClass]}`}> {getConsumptionToRender()}
                  <span className={`${styles.unit} ${styles[zoomClass]}`} style={{ fontSize: "1.39rem" }}>{"kW"}</span>
                </span>
                <strong className={`${styles.value__text} ${styles[zoomClass]}`}>Consumo Total</strong>
              </div>

              <div className={styles.column}>
                <span className={`${styles.value} ${styles[zoomClass]}`}>
                  {"R$" + integer}
                  <span className={`${styles.unit} ${styles[zoomClass]}`} style={{ fontSize: "1.39rem" }}>{"," + decimal}</span>
                </span>
                <strong className={`${styles.value__text} ${styles[zoomClass]}`}>Valor Estimado</strong>
              </div>

            </div>
          </div>
        ) : (
          <div />
        )}

        <div className={isTablet ? styles.PieArea__tablet : `${styles.PieArea} ${styles[zoomClass]}`}>
          <PieChart
            data={dataChart}
            startTime={startTime}
            endTime={endTime}
            activeLegends={activeLegends}
            onLegendChange={onLegendChange}
          />
        </div>

      </div>

      <CustomDataPickerModal
        ref={customDatepickerModalRef}
        onChangeDate={onSetDates}
      />
    </div>
  );
}

export default forwardRef(PieChartCard);
