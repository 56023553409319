import React from 'react';
import styles from './styles.module.scss';
import ArrowRight from '../../util/SVGs/arrow-right.svg';

import AreaComparison from './components/AreaComparison';
import BarComparison from './components/BarComparison';
import { useSearchParams } from 'react-router-dom';

export enum ComparisonChartType {
  AREA = "area",
  BAR = "bar",
};

interface ComparisonProps {
  comparisonChartType: ComparisonChartType;
  title: string;
};

export default function Comparison({ comparisonChartType, title }: ComparisonProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handdleClickComparasionClose = () => {
    searchParams.delete('comparison');
    searchParams.delete('chartType');

    setSearchParams(searchParams);
  };

  return (
    <div className={styles.comparisonContainer}>

      <div className={styles.titleContainer}>
        <img
          className={styles.arrowRight} src={ArrowRight} alt="Voltar"
          onClick={handdleClickComparasionClose}
        />
        <h2 className={styles.title}>{title}</h2>
      </div>

      {comparisonChartType === ComparisonChartType.AREA && (
        <AreaComparison />
      )}

      {comparisonChartType === ComparisonChartType.BAR && (
        <BarComparison />
      )}
    </div>
  );
}