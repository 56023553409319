import React from "react";
import styles from "./CustomAlert.module.scss"; // Arquivo CSS para estilizar o alerta

interface CustomAlertProps {
    message: string;
    onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ message, onClose }) => {
    return (
        <div className={styles.alertBackdrop}>
            <div className={styles.alertBox}>
                <h2>⚠️ Atenção</h2>
                <p>{message}</p>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default CustomAlert;
